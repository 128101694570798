.fbf-menu
  position: relative

  &#{&}--fixed
    position: initial

  &#{&}--fixed .fbf-menu-list
    position: fixed
    z-index: $z-dialog

    &.max-height-screen
      max-height: calc(100vh - 16px)

  &.fbf-menu--absolute .fbf-menu-list
    top: 0
    min-width: 100%

  &#{&}--inline
    display: inline-block

  .fbf-text-field
    width: 100%

.fbf-options-field, .fbf-search-field
  &.fbf-menu--absolute .fbf-menu-list
    max-width: 100%
