@mixin fbf-chip--focused
  @include shadow-2dp
  background-color: $color-palette-grey-600
  color: white

.fbf-chip
  @include fbf-layout--flow-right
  background-color: $color-palette-grey-200
  padding-left: 12px
  padding-right: 12px
  border-radius: 16px

  p
    padding-top: 0
    padding-bottom: 0
    margin-top: 0
    margin-bottom: 0
    line-height: 32px
    color: inherit

  .fbf-switch:first-child
    margin-right: 8px

.context--dark .fbf-chip
  background-color: transparentize(black, 0.8)
  color: white

  .fbf-chip__remove
    background-image: url('./images/chips/remove-button-dark.svg')

    &:active
      opacity: 0.5

  &.fbf-image .fbf-chip
    background-color: $color-palette-grey-800

.fbf-chip--actionable
  cursor: pointer
  .context--dark &
    &:hover, &:active, &:focus, &.fbf-chip--selected
      background-color: transparentize(black, 0.5)

  &:hover, &:active, &:focus, &.fbf-chip--selected
    @include fbf-chip--focused

.fbf-chip--positive, .fbf-chip--negative
  margin: 0 8px

  &:hover, &:active, &:focus, &.fbf-chip--selected
    box-shadow: none
    color: transparentize($color-palette-grey-800, 0.15)

.fbf-chip--positive
  &:hover, &:active, &:focus
    background-color: transparentize($color-palette-green-100, 0.5)
  &.fbf-chip--selected
    background-color: $color-palette-green-100

.fbf-chip--negative
  &:hover, &:active, &:focus
    background-color: transparentize($color-palette-red-100, 0.5)
  &.fbf-chip--selected
    background-color: $color-palette-red-100

.fbf-chip--removable
  padding-right: 0

.fbf-chip--contact
  padding-left: 0

  .fbf-profile-picture
    margin-right: 8px

.fbf-chip--deadline
  max-width: 100%
  min-width: 0
  padding: 0 8px

  > p
    color: inherit
  p
    display: inline-block

  .fbf-icon
    color: inherit
    margin-right: 4px

  &.fbf-chip--deadline-expired
    @include text-dark--muted

  &.fbf-chip--deadline-distant
    @include text--red

  &.fbf-chip--deadline-urgent
    background-color: $color-red
    color: white

.fbf-chip--path
  width: auto
  max-width: 100%
  box-sizing: border-box

  > p
    width: 100%
    justify-content: flex-start
    color: inherit

  .fbf-icon
    color: inherit

  .fbf-chip--path__group
    flex-shrink: 10
  .fbf-chip--path__node
    flex-shrink: 20
  .fbf-chip--path__node:last-of-type
    flex-shrink: 1
  .fbf-chip--path__empty
    text-transform: uppercase
    color: $color-blue
    font-weight: 500

// Recommended to use <button class='fbf-chip__remove'>
.fbf-chip__remove
  display: inline-block
  background-color: transparent
  background-image: url('./images/chips/remove-button-light.svg')
  background-position: center
  background-repeat: no-repeat
  border: none
  width: 24px
  height: 24px
  margin-left: 4px
  margin-right: 4px
  vertical-align: -4px

  &:focus, &:hover
    opacity: 0.8

.fbf-chip--inline__container
  display: inline-flex
  flex-wrap: wrap
  padding: 4px

  .fbf-chip
    margin: 4px
