.fbf-profile-picture-list
  @include fbf-layout--flow-right
  .fbf-profile-picture + .fbf-profile-picture, .fbf-profile-picture + .fbf-profile-picture-list__more
    margin-left: 8px

.fbf-profile-picture-list__more
  border-radius: 50%
  background-color: transparentize(black, 0.9)
  @include fbf-layout--flex-center

  .context--dark .fbf-profile-picture-list__more
    background-color: transparentize(white, 0.8)

.fbf-profile-picture-list
  &.size-small .fbf-profile-picture-list__more
      @include profile-picture-small
  &.size-medium .fbf-profile-picture-list__more
      @include profile-picture-medium
  &.size-large .fbf-profile-picture-list__more
      @include profile-picture-large

.fbf-action-bar .fbf-profile-picture-list
  margin: 0 8px
  border-right: 1px solid transparentize(white, 0.8)
  padding-right: 8px
  & > .fbf-profile-picture, & > .fbf-profile-picture-list__more
    width: 32px
    height: 32px
