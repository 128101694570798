@import 'color-definitions'

// Primary colors
$color-primary: $color-palette-green-800
$color-primary-dark: $color-palette-green-900
$color-primary-light: $color-palette-green-300
$color-accent: $color-palette-blue-400

// Shades of grey
$color-grey-dark: $color-palette-grey-900
$color-grey-light: $color-palette-grey-500

// Basic colors
$color-blue-light: $color-palette-blue-300
$color-blue: $color-palette-blue-400
$color-blue-dark: $color-palette-blue-900

$color-red-light: $color-palette-red-300
$color-red: $color-palette-red-800
$color-red-dark: $color-palette-red-900

$color-purple-light: $color-palette-deep-purple-300
$color-purple: $color-palette-deep-purple-400
$color-purple-dark: $color-palette-deep-purple-600

$color-yellow-light: $color-palette-lime-300
$color-yellow: $color-palette-lime-600
$color-yellow-dark: $color-palette-lime-800

$color-cyan-light: $color-palette-cyan-300
$color-cyan: $color-palette-cyan-600
$color-cyan-dark: $color-palette-cyan-800

$color-teal-light: $color-palette-teal-400
$color-teal: $color-palette-teal-700
$color-teal-dark: $color-palette-teal-900

$color-brown-light: $color-palette-brown-300
$color-brown: $color-palette-brown-400
$color-brown-dark: $color-palette-brown-600

$color-blue-grey-light: $color-palette-blue-grey-300
$color-blue-grey: $color-palette-blue-grey-500
$color-blue-grey-dark: $color-palette-blue-grey-700

$color-green-light: $color-palette-green-300
$color-green: $color-palette-green-800
$color-green-dark: $color-palette-green-900

$color-orange-light: $color-palette-orange-300
$color-orange: $color-palette-orange-500
$color-orange-dark: $color-palette-orange-800

$color-light-green: $color-palette-light-green-700

// DEPRECATED: use colors above
$color-green-dark: #33691E
$color-green-bg: #558B2F
$color-green-bg-light: #689F38
$color-primary-bg: $color-palette-green-100
$color-primary-bg-light: $color-palette-green-50

// DEPRECATED: use background classes from `backgrounds` or
// $color-palette-grey-X directly for clearer relative weight
$color-grey-bg-very-light:  $color-palette-grey-50
$color-grey-bg-light:       $color-palette-grey-100
$color-grey-bg:             $color-palette-grey-200
$color-grey-bg-dark:        $color-palette-grey-300
$color-grey-bg-very-dark:   $color-palette-grey-400

// DEPRECATED: use background classes or colors from `backgrounds`
@mixin background-states
  &.background--warning
    background: $color-palette-yellow-900

  &.background--problem
    background: $color-red

  &.background--notice
    background: transparentize($color-palette-blue-500, 0.9)

  &.background--dimmed
    background: $color-grey-bg

  &.background--contrast
    background: $color-palette-grey-700

// DEPRECATED: use $scrim-white-up-80
$fade-white-down: linear-gradient(to top, white, transparentize(white, 0.7))
// DEPRECATED: use $gradient-media-placeholder
$fbf-placeholder-gradient: linear-gradient(to bottom right, black 0%, $color-palette-grey-800 50%, black 51%)
