.fbf-data-table__cell
  padding: 0

  &--input
    .fbf-data-table__cell-wrapper
      overflow: hidden
    .fbf-data-table__cell-filler
      flex-shrink: 2
      flex-basis: 100%
    .fbf-text-field
      flex-basis: 100%
    .fbf-text-field, input
      @include text--p

  &--unpadded
    .fbf-data-table__cell-wrapper
      padding-top: 0
      padding-bottom: 0

  &:first-child .fbf-data-table__cell-wrapper
    padding-left: 24px

    & > .fbf-data-table__cell-antiwrapper
      margin-left: -24px
      padding-left: 24px

  .text--pp
    @include text--small

.fbf-data-table__cell-wrapper
  @include fbf-layout--flow-right
  padding: 12px 16px
  justify-content: flex-end
  box-sizing: border-box
  min-width: 100%
  height: inherit

  & > .fbf-profile-picture
    margin-right: 8px

  & > .fbf-data-table__cell-antiwrapper
    @include fbf-layout--flow-right
    @include fbf-layout--fullwidth
    @include fbf-layout--fullheight
    margin: -12px -16px
    padding: 12px 16px

.fbf-data-table__cell--non-numeric
  .fbf-data-table__cell-wrapper
    justify-content: flex-start

.fbf-data-table__cell--centered
  .fbf-data-table__cell-wrapper
    justify-content: center

.fbf-data-table__cell--colored
  .fbf-data-table__cell-wrapper
    padding: 4px 8px

  .fbf-data-table__cell__colorable
    border-radius: 2px
    padding: 4px 8px

  &.status-completed .fbf-data-table__cell__colorable
    @include text--green
  &.status-incomplete .fbf-data-table__cell__colorable
    @include text--colored($color-palette-deep-orange-700)
    background-color: lighten($color-palette-orange-50, 3%)
    font-weight: 500
  &.status-failed .fbf-data-table__cell__colorable
    @include text--colored(darken($color-palette-red-A700, 3%))
    background-color: $bg-grey--200
    font-weight: 500

.fbf-data-table__cell--checkbox
  width: 16px
  max-width: 56px

  // To overwrite the inline styles
  .fbf-data-table__cell-wrapper
    flex-grow: 0 !important
    flex-basis: auto

    // Damn your styles are weird
    & > .mdl-checkbox
      left: 8px
      &.is-upgraded
        padding-left: 16px

.fbf-data-table__row--nested
  > .fbf-data-table__cell:first-child, > .fbf-data-table__section--fixed:first-child > .fbf-data-table__cell:first-child
    .fbf-data-table__cell-wrapper
      padding-left: 56px

      & > .fbf-data-table__cell-antiwrapper
        margin-left: -56px
        padding-left: 56px

.fbf-data-table__cell--min-width-auto
  width: 1px
  white-space: nowrap

.fbf-data-table__cell--segments
  display: flex
  min-width: 48px

.fbf-data-table__cell__segment
  box-sizing: border-box
  height: 6px
  width: 100%
  min-width: 10px
  max-width: 18px
  margin: 0 1px
  border-radius: 4px

  &.fbf-data-table__cell__segment--progress
    opacity: 0.4

  &.fbf-data-table__cell__segment--empty
    border-width: 1px
    border-style: solid
    opacity: 0.4

.fbf-data-table__cell--blocks
  display: flex
  min-width: 48px

.fbf-data-table__cell__block
  box-sizing: border-box
  height: 10px
  width: 10px
  margin: 0 5px
  border-radius: 2px

  &.fbf-data-table__cell__block--complete
    height: 18px
    width: 18px
    margin: 0 1px

  &.fbf-data-table__cell__block--progress
    opacity: 0.4

  &.fbf-data-table__cell__block--empty
    border-width: 1px
    border-style: solid
    opacity: 0.4

@mixin segment-color-variant($color)
  .fbf-data-table__cell__segment--complete,
  .fbf-data-table__cell__segment--progress
    background-color: $color

  .fbf-data-table__cell__segment--empty
    border-color: $color

@mixin block-color-variant($color)
  .fbf-data-table__cell__block--complete,
  .fbf-data-table__cell__block--progress
    background-color: $color

  .fbf-data-table__cell__block--empty
    border-color: $color

.context--purple
  @include segment-color-variant($color-purple)
  @include block-color-variant($color-purple)

.context--green
  @include segment-color-variant($color-green)
  @include block-color-variant($color-green)
