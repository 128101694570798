.fbf-divider-text
  @include fbf-layout--fullwidth
  @include fbf-layout--flow-right
  @include text--muted
  box-sizing: border-box

  &--has-line-before::before, &--has-line-after::after
    content: ''
    display: inline-block
    height: 1px
    box-sizing: border-box
    background: $color-palette-grey-400
    flex-grow: 1
    align-self: center

  &--has-line-before::before
    margin-right: 16px
  &--has-line-after::after
    margin-left: 16px
