.groupscreen
  @include fbf-layout--flow-down
  @include fbf-layout__fullscreen-page

  &__container
    @include fbf-layout__column-container
    @include fbf-layout__column-container--padding
    @include fbf-layout__column-container--padding-app-bar
    position: static

    &--no-padding-top
      .fbf-app-bar--fixed.fbf-app-bar--static + &
        padding-top: 0
      .fbf-app-bar--fixed.fbf-app-bar--collapsing + &
        padding-top: $fbf-app-bar-height--collapsing

.browser-mobile, .browser-tablet
  .groupscreen[aria-hidden=true]
    // Prevent add-FAB in bottom right becoming visible when collapsing address
    // on certain mobile browsers (e.g. Android+Chrome) due to it having position
    // fixed too.
    .fbf-button--fab.fbf-layout--fixed-bottomright
      display: none
