@mixin fbf-layout--flow-down
  display: flex
  flex-direction: column
  min-width: 1px

@mixin fbf-layout--flow-right
  display: flex
  flex-direction: row
  align-items: center
  min-height: 1px

  & > .fbf-svg, & > .fbf-icon
    margin-right: 8px

@mixin fbf-layout--flow-wrap
  display: flex
  flex-flow: row wrap

@mixin fbf-layout--flow-nowrap
  display: flex
  flex-flow: nowrap

@mixin fbf-layout--flex-center
  display: flex
  align-self: stretch
  align-items: center
  justify-content: center

@mixin fbf-layout--flex-spread
  display: flex
  justify-content: space-between
  align-self: stretch

@mixin fbf-layout--flex-end
  display: flex
  justify-content: flex-end

@mixin fbf-layout--self-center
  display: flex
  align-self: center

@mixin fbf-layout--align-items-start
  display: flex
  align-items: flex-start

@mixin fbf-layout--scroll-overflow
  overflow: auto
  -webkit-overflow-scrolling: touch

  > *
    flex-shrink: 0
