.media-preview
  @include fbf-layout--flex-center
  background-image: $gradient-media-placeholder

  .fbf-image
    background-color: white

.media-preview__error
  @include fbf-layout--flow-down
  @include text--red-light
  @include content-padding-sm
