$fbf-slide-bar-item-max-width: 280px
$fbf-slide-bar-item-width: 256px
$fbf-slide-bar-item-height: 144px

.fbf-slide-bar__scroller .sortable-item
  top: 0px

  &.is-dragging
    top: -8px

    .fbf-slide-bar-item__content
      cursor: grabbing

.fbf-slide-bar-item
  @include fbf-layout--flow-down
  flex-shrink: 0
  align-self: stretch
  max-width: $fbf-slide-bar-item-max-width
  box-sizing: border-box
  padding: 8px 0
  margin-right: 8px

.fbf-slide-bar-item__container
  @include fbf-layout--flex-center
  flex-direction: column
  flex-grow: 1
  position: relative

  > .fbf-button
    position: absolute
    bottom: -8px
    right: -8px

.fbf-slide-bar-item__content
  @include shadow-4dp()
  cursor: pointer
  display: flex
  flex-grow: 1
  margin: 8px

  .fragment-view--document > img
    max-height: $fbf-slide-bar-item-height

  .fragment-view--question
    background-color: white
    min-width: $fbf-slide-bar-item-width
    height: $fbf-slide-bar-item-height
    // Essential lines to enable text truncation of question title
    flex-shrink: 1
    width: 100%
    .text--truncated__input p, .text--truncated__output p
      @include text--h6
    .text-placeholder
      margin-top: 0
      margin-bottom: 0
    .fbf-list
      border-top: 1px solid transparentize(black, 0.9)
      margin-top: 8px
      padding-bottom: 0

.fbf-slide-bar__broadcasting-indicator
  @include fbf-layout--flow-right
  animation-name: scaleIn
  animation-duration: 0.2s
  position: absolute
  bottom: -12px
  left: -12px

  .fbf-chip
    background-color: $color-cyan
    position: relative
    padding-left: 16px
    left: -10px
    .fbf-icon
      margin-right: 0

  .fbf-profile-picture
    z-index: 1

  @keyframes scaleIn
    from
      opacity: 0
      transform: scale(0)
    to
      opacity: 1
      transform: scale(1)

.fbf-slide-bar .fragment-view--preloaded::after
  content: ''
  position: absolute
  width: 8px
  height: 8px
  background-color: transparentize($color-green-light, 0.8)
  border-radius: 8px
  bottom: 16px
  left: 16px

.fbf-slide-bar-item-label > p
  @include text--muted
  text-align: center

.fbf-slide-bar-item--loading .fbf-slide-bar-item__content,
.fbf-slide-bar-item .media-view--loading
  width: $fbf-slide-bar-item-width
  height: $fbf-slide-bar-item-height
  align-items: center
  justify-content: center
  .fbf-spinner
    margin-top: 0

.fbf-slide-bar-item--selected
  .fbf-slide-bar-item__content
    border: 8px solid
    border-radius: 8px
    box-shadow: none
    margin: 0

@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-slide-bar-item--selected .fbf-slide-bar-item__content
    border-color: $color

.fbf-slide-bar-item--placeholder
  .fbf-slide-bar-item__content
    @include fbf-layout--flex-center
    flex-grow: 1
    width: $fbf-slide-bar-item-width
    background: transparentize(black, 0.9)
    box-shadow: none

.fbf-slide-bar-item + .fbf-button--icon
  margin: 0 12px 36px 0
