@mixin h1-responsive
  @include text--h1
  @media(max-width: $tablet-max-width)
    @include text--h2
  @media(max-width: $phone-max-width)
    @include text--h3

h1.text--responsive, .text--h1.text--responsive, .text--responsive--h1
  @include h1-responsive

@mixin h2-responsive
  @include text--h2
  @media(max-width: $tablet-max-width)
    @include text--h3
  @media(max-width: $phone-max-width)
    @include text--h4

h2.text--responsive, .text--h2.text--responsive, .text--responsive--h2
  @include h2-responsive

@mixin h3-responsive
  @include text--h3
  @media(max-width: $tablet-max-width)
    @include text--h4
  @media(max-width: $phone-max-width)
    @include text--h5

h3.text--responsive, .text--h3.text--responsive, .text--responsive--h3
  @include h3-responsive

@mixin h4-responsive
  @include text--h4
  @media(max-width: $tablet-max-width)
    @include text--h5
  @media(max-width: $phone-max-width)
    @include text--h6

h4.text--responsive, .text--h4.text--responsive, .text--responsive--h4
  @include h4-responsive

@mixin h5-responsive
  @include text--h5
  @media(max-width: $tablet-max-width)
    @include text--h6
  @media(max-width: $phone-max-width)
    @include text--p

h5.text--responsive, .text--h5.text--responsive, .text--responsive--h5
  @include h5-responsive
