.fbf-search-field
  .fbf-text-field
    cursor: pointer
    .fbf-chip
      margin-top: 8px
      margin-bottom: 8px
      margin-left: 8px
  .fbf-text-field__input-segment
    flex-wrap: wrap
  &.fbf-menu--absolute .fbf-menu-list
    top: 100%
