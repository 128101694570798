@import 'typography/base'
@import 'typography/sizes'
@import 'typography/weights'
@import 'typography/grey-shading'
@import 'typography/color-shading'
@import 'typography/responsive'
@import 'typography/share-url'
@import 'typography/subheader'
@import 'typography/placeholder'
@import 'typography/rich-text'
@import 'typography/markdown'
@import 'typography/caret'

html, body
  font-family: $roboto-family
  font-size: 14px
  font-weight: 400
  line-height: 20px
  -webkit-text-size-adjust: 100%

h1, h2, h3, h4, h5, h6, p, small
  margin: 0
  padding: 0
  @include text-dark--unmuted

h1, h2, h3, h4, h5, h6
  small
    @include text--h1
    @include text--muted
    font-size: 0.6em

h1
  @include text--h1

h2
  @include text--h2

h3
  @include text--h3

h4,
.fbf-rich-text--headline > p,
.text--broadcast > *
  @include text--h4

h5
  @include text--h5

h6
  @include text--h6

p
  @include text--p

small
  @include text--small

a
  color: inherit
  text-decoration: none

.text--link
  @include text--link

.text--button
  @include text--button

h1, h2, h3, h4, h5, h6, p, small
  a
    @include text--link

blockquote
  @include text--blockquote

mark
  background-color: #f4ff81

dt
  font-weight: 700

address
  @include text--small
  font-style: normal

kbd
  margin: 0 .1em
  padding: .3em .6em
  font-size: 11px
  line-height: 1.4
  color: $color-palette-grey-800
  text-shadow: 0 1px 0 white
  background-color: $color-palette-grey-200
  border: 1px solid $color-palette-grey-500
  border-radius: 3px
  box-shadow: 0 1px 0 transparentize(black, 0.8), 0 0 0 2px white inset
  white-space: nowrap

pre, code
  background-color: $color-palette-grey-100
  border-radius: 3px
  white-space: pre-wrap
  font-family: $monospace-fontstack
  margin: 0
  border: $color-palette-grey-300 solid 1px
  color: $color-palette-grey-600
pre
  display: flex
  padding: 2px 6px
code
  display: inline
  padding: 0px 4px

hr
  margin: 0
  height: 0
  border-top-color: $color-palette-grey-300
  .context--dark &
    border-top-color: $color-palette-grey-600

ul, ol
  @include text--p

.material-icons
  @include text--icon

@mixin text--centered
  text-align: center

.text--centered
  @include text--centered

@mixin text--small-caps
  font-variant: small-caps

.text--small-caps
  @include text--small-caps

@mixin text--truncate
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 100%

.text--truncate
  @include text--truncate

@mixin text--wrap
  word-wrap: break-word
  word-break: break-word
  hyphens: auto

.text--wrap
  @include text--wrap

h5.text--truncate
  // To prevent clipping of bottom part of glyphs by overflow:hidden
  line-height: 24px

@mixin text--dense
  line-height: 20px

.text--dense
  @include text--dense

@mixin text--divider
  padding-left: 6px
  padding-right: 6px
  font-weight: 800

.text--divider
  @include text--divider

.text--pulse
  @include pulse

@mixin text--linkbutton
  @include text--muted
  cursor: pointer
  transition: color 0.2s ease
  &:hover
    opacity: 1
    color: $color-accent
    .fbf-icon
      color: inherit

  .context--dark &
    @include text-light--muted
    &:hover
      color: white
      .fbf-icon
        color: inherit

.text--linkbutton
  @include text--linkbutton

.text--empty-state
  @include text--h4
  margin: 32px
  text-align: center

  // Not sure yet if this selector is appropriate. Rich text inside table
  // cells seems ... unweildy, as well as an unlikely requirement
  td > *
    margin: 0

.text--truncated__input
  display: none

.text--on-divider
  display: flex

  > *
    display: flex
    flex: 1 0 auto
    align-items: center
    padding: 16px 0

    &::after, &::before
      content: ""
      display: block
      border-bottom: 1px solid #ccc
      flex: auto

    &::after
      margin-left: 16px

    &::before
      margin-right: 16px
