.fbf-recipe-inline-app-bar.fbf-app-bar
  @include fbf-layout--flex-center

  .fbf-tool-bar
    width: 100%
    height: auto
    box-sizing: border-box
    justify-content: center
    position: relative

    > .fbf-recipe-app-bar__back-button
      position: absolute
      left: 0
      top: 0
      margin: 12px

  .fbf-recipe-inline-app-bar__header
    @include fbf-layout--flow-right
    height: 72px

    > .fbf-recipe-app-bar__back-button
      margin-right: 16px

.fbf-recipe-compact-app-bar.fbf-app-bar
  animation-name: app-bar
  animation-duration: 0.2s

  .fbf-tool-bar
    height: auto
    padding-right: 0

    .fbf-button
      border-radius: 0

  @keyframes app-bar
    from
      top: -68px
    to
      top: 0

.fbf-recipe-card-container
  @include fbf-layout--flex-center
  width: 100%

.fbf-recipe-card
  box-sizing: border-box
  width: 100%
  max-width: 412px

  > header
    min-height: 56px
    > h5, > .text--h5
      font-weight: 400

.fbf-recipe-card__meta
  > .fbf-list-item__lines
    justify-content: flex-start
    align-items: center

.fbf-recipe-list-item
  & > .fbf-activity-icon + .fbf-list-item__lines
    margin-left: 10px
  img.fbf-list-item__tail
    max-height: 40px

.fbf-recipe__workload
  .fbf-icon-wrapper
    @include fbf-layout--flex-center
    width: 24px
    height: 24px
    margin-left: 4px

  .fbf-icon--dot
    font-size: 10px
    opacity: 0.7

.fbf-recipe__tags
  .fbf-chip
    background: none
    border: 1px solid $color-blue
    color: $color-blue
    margin: 4px 4px 0 0

.fbf-recipe__detail
  display: initial !important

  .fbf-card--padded
    padding: 32px

  hr, .fbf-recipe__detail-meta
    width: 100%
