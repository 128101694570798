.fbf-button--selectable
  @include button--raised-base
  @include button--fab-base
  @include button--fab-size-muted
  color: $button-text-color

  &.fbf-button--active
    @include button--fab-size-unmuted
    color: white

  .fbf-icon, .fbf-svg
    margin-right: 0

.fbf-button--fab-text
  box-shadow: 0 1px 1.5px 0 rgba(0,0,0,0.12), 0 1px 1px 0 rgba(0,0,0,0.24)
  position: relative
  height: $button-fab-size-mini
  width: auto
  border-radius: $button-fab-size-mini / 2
  padding: 0 24px
  .fbf-icon, .fbf-svg
    margin-left: -16px
  .fbf-button__suffix-icon
    margin-right: -16px
    margin-left: 16px

.fbf-button--fab-location
  @include button--fab-base
  @include button--fab-size-regular
  border-bottom-right-radius: 0
  transform: rotate(45deg)
  .fbf-icon, .fbf-svg
    transform: rotate(-45deg)
