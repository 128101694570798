.fbf-fullscreen-controls
  @include fbf-layout--flow-right
  position: absolute
  background: $scrim-up-30
  pointer-events: none
  z-index: 1
  top: 0
  left: 0
  width: 100%
  box-sizing: border-box
  padding: 24px
  transition: top 0.2s $animation-curve-default
  @media(max-width: $desktop-max-width)
    padding: 16px

  .fbf-tab-bar
    @include shadow-4dp
    border-radius: 36px
    padding-right: 36px
    padding-left: 36px

  .fbf-button--size-large
    border-radius: 34px

  & > *
    pointer-events: all
  & > .fbf-layout__spanner
    pointer-events: none

  &.is-hidden
    top: -124px
