$fbf-layout-content-padding--phone: 8px
$fbf-layout-content-padding--tablet: 16px
$fbf-layout-content-padding--desktop: 32px
$fbf-layout-content-padding--desktop-layout: 40px

$fbf-app-bar-height--collapsing: 240px
$fbf-app-bar-shadow-height: 2px
$fbf-icon-size--small: 20px
$fbf-list-item-padding: 16px
$fbf-list-item-head-content-width: 40px
$fbf-list-item-head-content-width--medium: 24px
$fbf-mini-card-height: 100px
$fbf-mini-card-width: 360px
$fbf-option-list-media-preview-min-width: 72px
$fbf-option-list-media-preview-width: 15%
$fbf-tab-bar-height: 48px
$fbf-tool-bar-height: 56px

$progress-controls-thickness-horizontal: 48px
$progress-controls-thickness-horizontal--compact: 32px
$progress-controls-thickness-vertical: 32px
$progress-controls-text-container-size: 56px

$roboto-family: 'Roboto', 'Helvetica', 'Arial', sans-serif

$text-opacity-default: 0.87
$text-opacity-muted: 0.54
$text-opacity-muted-more: 0.38

$card-icon-gutter-width: 68px

$shadow-key-umbra-opacity: 0.2
$shadow-key-penumbra-opacity: 0.14
$shadow-ambient-shadow-opacity: 0.12

$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1)
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1)
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1)

$animation-curve-default: cubic-bezier(0.4, 0, 0.2, 1)

// DEPRECATED
$fbf-layout-header-height: 238px
