/* Override of addon styles */

// Fix for ember-ic-you so it triggers correctly on the splash page
.ember-ic-you
  position: relative
  margin-top: 1px

// Fix mdl-navigation__link transparency
.mdl-navigation__link
  opacity: 1
  button
    opacity: 0.87
