$pd-width-duration: .2s
$pd-width-delay: .4s

.fbf-dialog.fbf-page-dialog
  min-height: 80vh
  max-height: 80vh
  transition: width $pd-width-duration $pd-width-delay, min-width $pd-width-duration $pd-width-delay

  .fbf-dialog__footer
    display: flex !important

  .fbf-data-table
    margin: 0 !important

  .fbf-data-table__row
    padding: 0 !important
