// The colored underline for inputs that animates in under focus
@mixin underline
  @include material-animation-default()
  background-color: black
  bottom: -1px
  content: ''
  height: 2px
  left: 45%
  position: absolute
  visibility: hidden
  width: 10px

  #ember-testing &
    transition: none

@mixin underline--visible
  left: 0
  visibility: visible
  width: 100%
