@mixin footer-color-variant($color)
  background-color: darken($color, 20)

.fbf-landing__footer-panel
  @include footer-color-variant($color-primary)
  .fbf-landing__panel__column
    @include fbf-layout__center-column--medium-width

.fbf-landing__footer__links
  padding-left: 0
  li
    list-style: none
  li a, li p
    @include text--linkbutton
    @include fbf-layout--flow-right
    @media(min-width: $desktop-min-width)
      padding: 4px 0
.fbf-landing__footer__address
  .fbf-icon.fbf-icon
    margin-right: 4px
.fbf-landing__footer__flag
  margin-right: 8px
.fbf-landing__footer__media
  border-top: 1px solid transparentize(white, 0.5)
  padding: 16px 0
  margin-top: 16px
  @include fbf-layout--flow-right
  justify-content: center
  a + a
    margin-left: 16px

@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-landing__footer-panel
    @include footer-color-variant($color)
