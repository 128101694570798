ul.fbf-metrics
  @include fbf-grid(3, 40)
  padding: 0
  margin-top: 24px
  margin-bottom: 24px
  list-style: none
  justify-content: center

  &.fbf-metrics--2-col
    @include fbf-grid(2, 40)

li.fbf-metric
  text-align: center
  padding: 32px 32px
  @include fbf-layout--flow-down

  h5
    @include text--muted
  strong
    @include text--h4
    font-weight: 500
    line-height: 2
  small
    line-height: 14px
    display: block
    margin-top: 8px

  &.fbf-metric--graphic-left
    .fbf-metric__head
      @include fbf-layout--flow-right
      justify-content: center

      h5
        text-align: left

  &.fbf-metric--graphic-top
    .fbf-metric__head
      @include fbf-layout--flow-down

      .fbf-metric__graphic
        margin-bottom: 8px
        @include fbf-layout--flex-center

        .fbf-mini-progress-bar
          max-width: 160px
