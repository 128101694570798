.fbf-landing__faq-panel
  .fbf-landing__panel__column
    @include fbf-layout__center-column--normal-width

.fbf-landing__faq-grid
  @media(min-width: $desktop-min-width)
    @include fbf-grid(2, 16)
    ul.fbf-list:first-child .fbf-list-item__lines
      align-items: flex-end
      text-align: right
