$fixed-size: 22px

.fbf-badge
  display: flex
  flex-direction: row
  justify-content: center
  align-content: center
  align-items: center
  flex-shrink: 0
  font-weight: 600
  font-size: 12px
  padding: 0 8px
  height: $fixed-size
  border-radius: $fixed-size / 2
  color: white
  background-color: $color-blue

  &:last-child
    margin-left: 8px

.fbf-badge--fixed-width
  padding: 0
  width: $fixed-size

@mixin badge-color-variant($color)
  background-color: $color

@mixin badge-color-variant-context-dark($color)
  color: $color
  background-color: white

.context--cyan
  .fbf-badge
    @include badge-color-variant($color-cyan)
  .context--dark .fbf-badge
    @include badge-color-variant-context-dark($color-cyan)

.context--blue
  .fbf-badge
    @include badge-color-variant($color-blue)
  .context--dark .fbf-badge
    @include badge-color-variant-context-dark($color-blue)
