.fbf-data-table__title
  width: 100%
  text-align: left

  .fbf-data-table__title-wrapper
    @include fbf-layout--flow-right
    width: 100%
    padding: 8px 16px 8px 24px
    min-height: 64px
    box-sizing: border-box

    h5, .text--h5
      & + .fbf-chip
        margin-left: 16px
        flex-shrink: 0

  &.fbf-data-table__title--selection
    .fbf-data-table__title-wrapper
      background-color: $color-palette-green-50
      h6
        color: $color-green

  &.fbf-data-table__section--auto
    position: initial
    height: initial
  &.fbf-data-table__section--fixed
    top: 0
