.fbf-media-stepper
  display: flex

  @media(min-width: $desktop-min-width)
    flex-direction: row
    .fbf-media-stepper__media
      width: 75%
    aside
      width: 25%

  @media(max-width: $tablet-max-width)
    flex-direction: column !important
    .fbf-media-stepper__media
      width: auto
    aside
      width: auto

  .fbf-media-stepper__media
    position: relative
    align-self: flex-start
    img, video
      width: 100%

    .fbf-media-action-button
      position: absolute
      top: 50%
      left: 50%
      &.fbf-media-action-button--large
        margin-top: -63px
        margin-left: -63px
      &.fbf-media-action-button--medium
        margin-top: -42px
        margin-left: -42px

  aside
    @include fbf-layout--flow-down
    min-width: 304px

    padding-left: 16px
    @media(min-width: $desktop-min-width)
      padding-left: 32px

    h5
      margin-left: 8px

    .fbf-stepper--vertical
      margin-left: -16px
