.text__subheader
  @include text--small
  @include text--strong

  @each $color-name, $color in $colors
    .context--#{$color-name} &
      color: $color

  @each $color-name, $color in $colors-light
    .context--#{$color-name} .context--dark &
      color: $color
