.fbf-activity-icon--extra-small
  width: 19px
  height: 19px
.fbf-activity-icon--small
  width: 32px
  height: 32px
.fbf-activity-icon--medium
  width: 52px
  height: 52px
.fbf-activity-icon--large
  width: 88px
  height: 88px
.fbf-activity-icon--extra-large
  width: 128px
  height: 128px
.fbf-activity-icon--huge
  width: 256px
  height: 256px

.fbf-activity-icon--container.fbf-activity-icon .primary
  color: #546E7A

.fbf-activity-icon--primitive.fbf-activity-icon .primary
  fill: transparent
  color: #fff

.fbf-activity-icon--primitive, .fbf-activity-icon--container
  &.fbf-activity-icon
    .backdrop-item
      fill: #9B9B9B
    .backdrop-container
      fill: transparentize(#546E7A, 0.8)

  &.fbf-activity-icon--major .shadow
    fill: transparentize(black, 0.7)

  &.fbf-activity-icon--minor,
  &.fbf-activity-icon--new
    .backdrop-item, .backdrop-container
      fill: #F5F5F5

  &.fbf-activity-icon--transparent
    .backdrop-item, .backdrop-container
      fill: none
      stroke: #979797

  &.fbf-activity-icon--minor .primary,
  &.fbf-activity-icon--new .primary
    fill: #fff

  &.fbf-activity-icon--new .primary
    color: #039BE5


.fbf-activity-icon--integration
  &.fbf-activity-icon--completed
    .disabled
      display: none

// Color definitions
.fbf-activity-icon--minor.fbf-activity-icon--Aid .primary
  color: #E53935

.fbf-activity-icon--major.fbf-activity-icon--Aid .fill,
.fbf-activity-icon--small.fbf-activity-icon--Aid .fill
  fill: #E53935

.fbf-activity-icon--minor.fbf-activity-icon--Assignment-Groupfeedback .primary,
.fbf-activity-icon--minor.fbf-activity-icon--Assignment-Handin .primary,
.fbf-activity-icon--minor.fbf-activity-icon--Assignment-Peerfeedback .primary,
.fbf-activity-icon--minor.fbf-activity-icon--Assignment-Assignmentfeedback .primary,
.fbf-activity-icon--minor.fbf-activity-icon--Assignment-Personalfeedback .primary,
.fbf-activity-icon--minor.fbf-activity-icon--Assignment .primary
  color: $color-purple

.fbf-activity-icon--major.fbf-activity-icon--Assignment-Groupfeedback .fill,
.fbf-activity-icon--major.fbf-activity-icon--Assignment-Handin .fill,
.fbf-activity-icon--major.fbf-activity-icon--Assignment-Peerfeedback .fill,
.fbf-activity-icon--major.fbf-activity-icon--Assignment-Assignmentfeedback .fill,
.fbf-activity-icon--major.fbf-activity-icon--Assignment-Personalfeedback .fill,
.fbf-activity-icon--major.fbf-activity-icon--Assignment .fill,
.fbf-activity-icon--small.fbf-activity-icon--Assignment-Groupfeedback .fill,
.fbf-activity-icon--small.fbf-activity-icon--Assignment-Handin .fill,
.fbf-activity-icon--small.fbf-activity-icon--Assignment-Peerfeedback .fill,
.fbf-activity-icon--small.fbf-activity-icon--Assignment-Assignmentfeedback .fill,
.fbf-activity-icon--small.fbf-activity-icon--Assignment-Personalfeedback .fill,
.fbf-activity-icon--small.fbf-activity-icon--Assignment .fill
  fill: $color-purple

.fbf-activity-icon--minor.fbf-activity-icon--Assistent .primary
  color: #407A28

.fbf-activity-icon--major.fbf-activity-icon--Assistent .fill,
.fbf-activity-icon--small.fbf-activity-icon--Assistent .fill
  fill: #407A28

.fbf-activity-icon--minor.fbf-activity-icon--Audio .primary
  color: #8D6E63

.fbf-activity-icon--major.fbf-activity-icon--Audio .fill,
.fbf-activity-icon--small.fbf-activity-icon--Audio .fill
  fill: #8D6E63

.fbf-activity-icon--minor.fbf-activity-icon--Course .primary
  color: #5C6BC0

.fbf-activity-icon--major.fbf-activity-icon--Course .fill,
.fbf-activity-icon--small.fbf-activity-icon--Course .fill
  fill: #5C6BC0

.fbf-activity-icon--minor.fbf-activity-icon--CollaborativeLearning .primary
  color: #4DB6AC

.fbf-activity-icon--major.fbf-activity-icon--CollaborativeLearning .fill,
.fbf-activity-icon--small.fbf-activity-icon--CollaborativeLearning .fill
  fill: #4DB6AC

.fbf-activity-icon--minor.fbf-activity-icon--Discussion .primary,
.fbf-activity-icon--minor.fbf-activity-icon--Annotation .primary
  color: #689F38

.fbf-activity-icon--major.fbf-activity-icon--Discussion .fill,
.fbf-activity-icon--small.fbf-activity-icon--Discussion .fill,
.fbf-activity-icon--major.fbf-activity-icon--Annotation .fill,
.fbf-activity-icon--small.fbf-activity-icon--Annotation .fill
  fill: #689F38

.fbf-activity-icon--minor.fbf-activity-icon--Doc .primary
  color: #039BE5

.fbf-activity-icon--major.fbf-activity-icon--Doc .fill,
.fbf-activity-icon--small.fbf-activity-icon--Doc .fill
  fill: #039BE5

.fbf-activity-icon--minor.fbf-activity-icon--Comprehension .primary,
.fbf-activity-icon--minor.fbf-activity-icon--Exam .primary
  color: #AB40BA

.fbf-activity-icon--major.fbf-activity-icon--Comprehension .fill,
.fbf-activity-icon--small.fbf-activity-icon--Comprehension .fill,
.fbf-activity-icon--major.fbf-activity-icon--Exam .fill,
.fbf-activity-icon--small.fbf-activity-icon--Exam .fill
  fill: #AB40BA

.fbf-activity-icon--minor.fbf-activity-icon--Image .primary
  color: #8D6E63

.fbf-activity-icon--major.fbf-activity-icon--Image .fill,
.fbf-activity-icon--small.fbf-activity-icon--Image .fill
  fill: #8D6E63

.fbf-activity-icon--minor.fbf-activity-icon--Knowledge .primary
  color: #303F9F

.fbf-activity-icon--major.fbf-activity-icon--Knowledge .fill,
.fbf-activity-icon--small.fbf-activity-icon--Knowledge .fill
  fill: #303F9F

.fbf-activity-icon--minor.fbf-activity-icon--Link .primary,
.fbf-activity-icon--minor.fbf-activity-icon--LinkExt .primary,
.fbf-activity-icon--minor.fbf-activity-icon--LinkInt .primary
  color: #607D8B

.fbf-activity-icon--major.fbf-activity-icon--Link .fill,
.fbf-activity-icon--major.fbf-activity-icon--LinkExt .fill,
.fbf-activity-icon--major.fbf-activity-icon--LinkInt .fill,
.fbf-activity-icon--small.fbf-activity-icon--Link .fill,
.fbf-activity-icon--small.fbf-activity-icon--LinkExt .fill,
.fbf-activity-icon--small.fbf-activity-icon--LinkInt .fill
  fill: #607D8B

.fbf-activity-icon--minor.fbf-activity-icon--Notification .primary
  color: #E53935

.fbf-activity-icon--major.fbf-activity-icon--Notification .fill,
.fbf-activity-icon--small.fbf-activity-icon--Notification .fill
  fill: #E53935

.fbf-activity-icon--minor.fbf-activity-icon--Question .primary
  color: $color-teal

.fbf-activity-icon--major.fbf-activity-icon--Question .fill,
.fbf-activity-icon--small.fbf-activity-icon--Question .fill
  fill: $color-teal

.fbf-activity-icon--minor.fbf-activity-icon--QuestionSet .primary
  color: $color-teal

.fbf-activity-icon--major.fbf-activity-icon--QuestionSet .fill,
.fbf-activity-icon--small.fbf-activity-icon--QuestionSet .fill
  fill: $color-teal

.fbf-activity-icon--minor.fbf-activity-icon--Slides .primary
  color: #00ACC1

.fbf-activity-icon--major.fbf-activity-icon--Slides .fill,
.fbf-activity-icon--small.fbf-activity-icon--Slides .fill
  fill: #00ACC1

.fbf-activity-icon--minor.fbf-activity-icon--Teacher .primary
  color: #7986CB

.fbf-activity-icon--major.fbf-activity-icon--Teacher .fill,
.fbf-activity-icon--small.fbf-activity-icon--Teacher .fill
  fill: #7986CB

.fbf-activity-icon--minor.fbf-activity-icon--Text .primary
  color: #979797

.fbf-activity-icon--major.fbf-activity-icon--Text .fill,
.fbf-activity-icon--small.fbf-activity-icon--Text .fill
  fill: #979797

.fbf-activity-icon--minor.fbf-activity-icon--Progress .primary
  color: #607D8B
.fbf-activity-icon--major.fbf-activity-icon--Progress .fill
  fill: #607D8B

.fbf-activity-icon--minor.fbf-activity-icon--Upvote .primary
  color: #607D8B
.fbf-activity-icon--major.fbf-activity-icon--Upvote .fill
  fill: #607D8B

.fbf-activity-icon--minor.fbf-activity-icon--Junction .primary
  color: #607D8B
.fbf-activity-icon--major.fbf-activity-icon--Junction .fill
  fill: #607D8B

.fbf-activity-icon--minor.fbf-activity-icon--Video .primary
  color: #B61A1C

.fbf-activity-icon--major.fbf-activity-icon--Video .fill,
.fbf-activity-icon--small.fbf-activity-icon--Video .fill
  fill: #B61A1C
