@mixin progress-color-variant($color)
  $progress-main-color: $color
  $progress-secondary-color: transparentize(white, 0.2)
  $progress-fallback-buffer-color: transparentize(white, 0.1)

  .mdl-progress > .progressbar
    background-color: $progress-main-color

  .mdl-progress > .bufferbar
    background-image: linear-gradient(to right, $progress-secondary-color, $progress-secondary-color), linear-gradient(to right, $progress-main-color, $progress-main-color)

  // Webkit only
  @supports (-webkit-appearance:none)
    .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate) > .auxbar,
    .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate) > .auxbar
      background-image: linear-gradient(to right, $progress-secondary-color, $progress-secondary-color), linear-gradient(to right, $progress-main-color, $progress-main-color)

  .mdl-progress:not(.mdl-progress--indeterminate) > .auxbar,
  .mdl-progress:not(.mdl-progress__indeterminate) > .auxbar
    background-image: linear-gradient(to right, $progress-fallback-buffer-color, progress-fallback-buffer-color), linear-gradient(to right, $progress-main-color, $progress-main-color)

  .mdl-progress.mdl-progress--indeterminate > .bar1,
  .mdl-progress.mdl-progress__indeterminate > .bar1
    background-color: $progress-main-color

  .mdl-progress.mdl-progress--indeterminate > .bar3,
  .mdl-progress.mdl-progress__indeterminate > .bar3
    background-color: $progress-main-color

.context--dark
  @include progress-color-variant(white)

.context--cyan
  @include progress-color-variant($color-cyan)
