.fbf-landing__integrations-panel__section
  @include fbf-layout--flow-right
  opacity: 0.5
  margin: 0 16vw

  img
    width: calc(50% - 32px)
  img + img
    margin-left: 64px

  @media (max-width: $notepad-width-in)
    margin: 0 32px
  @media (max-width: $phone-max-width)
    img
      width: calc(50% - 12px)
    img + img
      margin-left: 24px
