.fbf-slider
  width: 100%
  margin: 16px 0 16px

  .fbf-slider--tooltips
    margin-top: 48px

.fbf-slider__hints
  align-items: flex-start
  justify-content: space-between

  small
    max-width: 45%

/*! Styles loosely based upon Propeller framework */
.fbf-slider__target.noUi-target
  box-shadow: none
  border: none
  height: 2px
  background: $bg-grey--400

  &:hover *:focus, .noUi-active
    outline: none

  .noUi-base
    z-index: 100

  .noUi-value
    display: none

  .noUi-handle
    border: none
    box-shadow: none
    border-radius: 50%
    width: 14px
    height: 14px
    right: -7px !important
    top: -6px
    background: transparent

    &:after
      display: none

    &:before
      display: block
      width: 100%
      height: 100%
      border-radius: 50%
      position: absolute
      left: 0
      top: 0
      transition: all ease-in-out 0.2s

    .fbf-slider--tooltips &.noUi-active:before
      opacity: 0
      transform: scale(0,0)

  .noUi-marker
    background: $color-palette-grey-700

  .noUi-pips-horizontal
    height: 2px
    padding: 0
    top: 0
    z-index: 10

    .noUi-marker-horizontal
      height: 2px

    .noUi-marker-large
      &:first-of-type
        margin-left: 0

      &:nth-last-child(2)
        margin-left: -2px

  .noUi-tooltip
    left: 50%
    padding: 0
    width: 28px
    height: 28px
    margin-left: -14px
    border: none
    border-radius: 50%
    color: white
    line-height: 28px
    transform: scale(1,1)
    transition: all ease-in-out 0.2s
    font-size: 12px

  &.noUi-horizontal
    .noUi-tooltip
      top: -32px
      bottom: auto
      transform-origin: 50% 150%

      &:before
        content: ''
        width: 20px
        height: 20px
        position: absolute
        left: 50%
        top: 9px
        border-radius: 10px 10px 0 10px
        margin-left: -10px
        z-index: -1
        transform: rotate(45deg)

@mixin slider-color-variant($color)
  &.noUi-target
    .noUi-connect,
    .noUi-handle:before,
    .noUi-tooltip,
    .noUi-tooltip:before
      background: $color

.fbf-slider.fbf-slider--indeterminate .fbf-slider__target.noUi-target
  .noUi-connect,
  .noUi-handle:before,
  .noUi-tooltip,
  .noUi-tooltip:before
    background: black

  .noUi-tooltip
    transform: scale(0,0)

[disabled].fbf-slider .fbf-slider__target
  .noUi-handle
    border: 2px solid white

  .noUi-connect
    opacity: 0.6

.context--platform
  .fbf-slider__target
    @include slider-color-variant($color-primary)

.context--purple
  .fbf-slider__target
    @include slider-color-variant($color-purple)


.fbf-list-item__lines
  .fbf-slider
    margin-top: 36px
    margin-bottom: 12px
