.fbf-table
  width: 100%
  max-width: 100%
  white-space: unset
  word-wrap: break-word

.fbf-table--no-border
  border: 0
  border-collapse: collapse

.fbf-table-cell--has-maxwidth
  overflow: hidden
  text-overflow: ellipsis
  // Pretty strange, but setting width won't work without this
  max-width: 0

.fbf-table-cell--aligned-top
  vertical-align: top

.fbf-table
  .fbf-table-cell,
  .fbf-table-head
    overflow: hidden

  .fbf-table-head
    text-overflow: unset

  .fbf-table-cell
    text-overflow: ellipsis
    white-space: nowrap

  th.fbf-table-head--unpadded,
  td.fbf-table-cell--unpadded
    padding: 0

  td.fbf-table-cell--limited
    max-width: 160px

  .fbf-table-cell--center
    text-align: center
