@import 'fbf-linear-bar'

.fbf-distribution-bar
  @include linear-bar

.fbf-distribution-bar__part
  @include linear-bar__interval
  position: relative

.fbf-distribution-bar__part + .fbf-distribution-bar__part
  box-sizing: border-box
  border-left: 1px solid white

.fbf-distribution-bar__part--green
  @include linear-bar__element-color-variant($color-green)
.fbf-distribution-bar__part--orange
  @include linear-bar__element-color-variant($color-orange)
.fbf-distribution-bar__part--red
  @include linear-bar__element-color-variant($color-red)
.fbf-distribution-bar__part--blue
  @include linear-bar__element-color-variant($color-blue)
.fbf-distribution-bar__part--purple
  @include linear-bar__element-color-variant($color-purple)
.fbf-distribution-bar__part--blue-grey
  @include linear-bar__element-color-variant($color-blue-grey)
