$app-bar-height: 56px
$list-item-height: 48px
$file-picker-height: 2 * $app-bar-height + 4.5 * $list-item-height
$file-picker-width--flat: 328px
$selected-color: $color-palette-light-blue-50

.fbf-dialog.fbf-file-picker
  border-radius: 0

  .fbf-app-bar
    display: flex !important
    box-shadow: none

    .fbf-button
      transition: none

    @media (max-width: $notepad-width-in)
      .fbf-tool-bar
        padding: 0 16px

  > .fbf-app-bar.fbf-app-bar--top
    background-color: $bg-grey--100
    border-bottom: 1px solid $bg-grey--300

  > .fbf-app-bar.fbf-app-bar--bottom
    border-top: 1px solid $bg-grey--300

  .fbf-list--tree__root
    @include fbf-layout--scroll-overflow
    padding: 0
    flex-grow: 1

.fbf-file-picker--flat
  &:not(.fbf-file-picker--forced-flat)
    min-height: $file-picker-height
    max-height: $file-picker-height
    width: $file-picker-width--flat

    @media(max-height: $file-picker-height / 0.8)
      min-height: initial

  .fbf-app-bar .fbf-tool-bar > .fbf-button:first-child
    margin-left: 0
    margin-right: 16px

  .fbf-list-item--tree-node__header > .fbf-icon + .fbf-list-item__lines
    margin-left: 16px

.fbf-file-picker--expandable
  height: $file-picker-height

  @media(min-height: $file-picker-height / 0.8)
    min-height: $file-picker-height
    height: auto

.fbf-file-picker--has-selection
  &.fbf-file-picker--expandable .fbf-app-bar--top, &.fbf-file-picker--flat .fbf-app-bar--bottom
    @include text--blue
    background-color: $selected-color

    .fbf-button--icon
      color: inherit

.fbf-dialog .fbf-file-picker
  z-index: $z-dialog

  &.fbf-file-picker--flat:not(.fbf-file-picker--forced-flat)
    position: absolute

.fbf-dialog.fbf-dialog--size-medium .fbf-file-picker.fbf-file-picker--flat
  @media(min-width: 344px / 0.8)
    width: 344px
  @media(max-width: 344px / 0.8)
    width: 100%

.fbf-dialog.fbf-dialog--force-fullscreen.fbf-file-picker.fbf-file-picker--flat
  height: 100%
  width: 100vw
  max-height: initial
  max-width: initial

.fbf-file-picker.fbf-file-picker--forced-flat
  @include fbf-dialog-fullscreen
