@import 'variables'

@mixin toggle--base
  position: relative
  display: flex

  &.is-enabled
    cursor: pointer
  &:focus
    outline: none

@mixin toggle__focus-helper
  display: flex
  position: absolute
  box-sizing: border-box
  width: $toggle-button-size
  height: $toggle-button-size
  border-radius: 50%
  background-color: transparent

@mixin toggle__label
  cursor: pointer
  margin-left: 8px

  fieldset[disabled] .fbf-toggle &,
  .fbf-toggle.is-disabled &
    @include text--muted

@mixin toggle__icon
  .fbf-toggle.is-unchecked &
    color: transparentize($toggle-dark-color, $toggle-unchecked-fade)
    outline: none

  fieldset[disabled] .fbf-toggle &,
  .fbf-toggle.is-disabled &
    color: transparentize($toggle-dark-color, $toggle-disabled-fade)

  .fbf-toggle.is-enabled.is-checked &
    color: $toggle-dark-color

@mixin toggle--is-focused
  .fbf-toggle__focus-helper
    transform: scale(1)
  &.is-enabled.is-checked
    .fbf-toggle__focus-helper
      box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($toggle-dark-color, $toggle-focus-fade)
      background-color: transparentize($toggle-dark-color, $toggle-focus-fade)
  &.is-enabled.is-unchecked, &.is-disabled
    .fbf-toggle__focus-helper
      box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($toggle-dark-color, $toggle-disabled-focus-fade)
      background-color: transparentize($toggle-dark-color, $toggle-disabled-focus-fade)

@mixin toggle--has-hover
  &.is-enabled
    .fbf-toggle__focus-helper
      transform: scale(1)
      box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($toggle-dark-color, $toggle-hover-fade)
      background-color: transparentize($toggle-dark-color, $toggle-hover-fade)

@mixin toggle--is-clicked
  &.is-enabled
    .fbf-toggle__focus-helper
      transform: scale(1)
      box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($toggle-dark-color, $toggle-clicked-fade)
      background-color: transparentize($toggle-dark-color, $toggle-clicked-fade)

@mixin toggle__icon--color($color)
  &.is-enabled.is-checked
    .fbf-toggle__icon
      color: $color

@mixin toggle__focus-helper--color($color)
  &.is-enabled.is-checked
    &.is-focused
      .fbf-toggle__focus-helper
        box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($color, $toggle-focus-fade)
        background-color: transparentize($color, $toggle-focus-fade)

    &:hover
      .fbf-toggle__focus-helper
        box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($color, $toggle-hover-fade)
        background-color: transparentize($color, $toggle-hover-fade)

    &:active
      .fbf-toggle__focus-helper
        box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($color, $toggle-clicked-fade)
        background-color: transparentize($color, $toggle-clicked-fade)

@mixin toggle__icon--white
  &.is-unchecked .fbf-toggle__icon
    color: transparentize($toggle-white-color, $toggle-unchecked-fade)
  fieldset[disabled] & .fbf-toggle__icon,
  &.is-disabled .fbf-toggle__icon
    color: transparentize($toggle-white-color, $toggle-disabled-fade)

  &.is-enabled.is-checked .fbf-toggle__icon
    color: $toggle-white-color

@mixin toggle__focus-helper--white
  &.is-focused.is-enabled.is-enabled
    .fbf-toggle__focus-helper
      box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($toggle-white-color, $toggle-focus-fade)
      background-color: transparentize($toggle-white-color, $toggle-focus-fade)

  &.is-focused.is-disabled
    .fbf-toggle__focus-helper
      box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($toggle-white-color, $toggle-disabled-focus-fade)
      background-color: transparentize($toggle-white-color, $toggle-disabled-focus-fade)

  &.is-enabled:hover
    .fbf-toggle__focus-helper
      box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($toggle-white-color, $toggle-hover-fade)
      background-color: transparentize($toggle-white-color, $toggle-hover-fade)

  &.is-enabled:active
    .fbf-toggle__focus-helper
      box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($toggle-white-color, $toggle-clicked-fade)
      background-color: transparentize($toggle-white-color, $toggle-clicked-fade)
