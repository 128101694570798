.fbf-data-table__header
  text-align: left

  .fbf-data-table__header-wrapper
    padding: 16px
    box-sizing: border-box

    small
      @include text--small
      @include text--muted-more
      font-weight: 500

      &.text--strong, &:hover
        @include text--muted

        strong
          @include text--unmuted

      strong
        @include text--muted

    .fbf-icon
      min-width: 12px

  .fbf-data-table__header-wrapper--narrow
    padding: 16px 8px 16px 0

.fbf-data-table__header--weight
  vertical-align: bottom
