$toggle-dark-color: $color-palette-grey-800
$toggle-white-color: white

$toggle-unchecked-fade: 0.46
$toggle-disabled-fade: 0.74
$toggle-focus-fade: 0.74
$toggle-hover-fade: 0.96
$toggle-checked-hover-fade: 0.93
$toggle-clicked-fade: 0.84
$toggle-disabled-focus-fade: 0.9

$toggle-button-size: 24px
$toggle-focus-helper-size: 40px
$toggle-focus-helper-spread: (($toggle-focus-helper-size - $toggle-button-size) / 2)
