.fbf-lti-popover
  width: 360px
  .fbf-popover__content
    padding: 16px 32px

.fbf-lti-popover__lms-link
  @include fbf-layout--actionable
  display: flex
  height: 50px
  align-items: center
  padding: 0 8px

  img
    width: 100%
