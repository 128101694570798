.fbf-tab-bar
  display: flex

.fbf-tab-bar--centered
  justify-content: center

.fbf-tab-bar--fixed
  .fbf-tab-bar-item
    flex-grow: 1

.fbf-tab-bar--transparent
  background-color: transparent !important

.fbf-tab-bar.color-white.color-white
  background-color: white

@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-tab-bar, .fbf-tab-bar.color-#{$color-name}.color-#{$color-name}
    background-color: $color
