@mixin fbf-layout--focused
  background-color: transparentize(black, 0.95)
  .context--dark &
    background-color: transparentize(white, 0.95)

@mixin fbf-layout--active
  background-color: transparentize(black, 0.90)
  &:hover, &:focus, .fbf-layout--focused
    background-color: transparentize(black, 0.85)
  .context--dark &
    background-color: transparentize(black, 0.90)
    &:hover, &:focus, .fbf-layout--focused
      background-color: transparentize(black, 0.85)

@mixin fbf-layout--actionable
  cursor: pointer
  &:focus, &.is-focused
    @include fbf-layout--focused
  &.is-active
    @include fbf-layout--active
  &:hover, .context--dark .context--light &:hover
    background-color: transparentize(black, 0.97)

  .context--dark &:hover
    background-color: transparentize(white, 0.97)

@mixin fbf-layout--selected
  background-color: transparentize(black, 0.95)
