.fbf-tool-bar
  display: flex
  flex-direction: row
  align-items: center
  padding: 0 16px
  height: $fbf-tool-bar-height
  position: relative

  @media(max-width: $notepad-width-in)
    padding: 0 8px

    > h5, > .text--h5
      &:first-child
        padding-left: 8px

  & + &
    padding: 0 16px

  &__item + &__item
    margin-left: 8px

  h1, h2, h3, h4, h5, h6, .mdl-textfield
    flex: auto

  .context--dark &
    .fbf-tool-bar__title
      color: white

  .fbf-text-field
    @include fbf-layout--fullwidth
    margin-right: 8px

    input, label
      font-size: 20px

  // DEPRECATED: use new fbf-text-field instead
  .mdl-textfield
    margin-right: 8px
    width: auto
    &.fbf-tool-bar__title
      flex-grow: 6
    &.fbf-tool-bar__secondary
      flex-grow: 1

    input, label
      font-size: 20px

  // DEPRECATED: use `fbf-tool-bar__item` for adjacent items in the tool bar
  .fbf-button--icon + .fbf-button,
  .fbf-button--icon + .fbf-tooltip + .fbf-button,
  .fbf-button + .fbf-button--icon,
  .fbf-button + .fbf-tooltip + .fbf-button--icon,
  .fbf-button--raised + .fbf-button,
  .fbf-button--raised + .fbf-tooltip + .fbf-button,
  .fbf-button--raised + .fbf-menu-button,
  .fbf-button--raised + .fbf-tooltip + .fbf-menu-button,
  .fbf-button + .fbf-button--raised,
  .fbf-button + .fbf-tooltip + .fbf-button--raised,
  .fbf-button + .fbf-activity-options-menu,
  .fbf-button + .fbf-tooltip + .fbf-activity-options-menu,
  .fbf-button + .fbf-tool-bar__item,
  .fbf-tool-bar__item + .fbf-button,
  .fbf-tool-bar__item + .fbf-activity-options-menu,
  .fbf-tool-bar__item + .fbf-menu-button
    margin-left: 8px

.fbf-tool-bar__icon
  position: absolute
  left: 0
  bottom: 0
  z-index: -1

  @media(min-width: $desktop-layout-min-width)
    left: 192px

  .fbf-icon, .fbf-svg
    color: transparentize(black, 0.9)
  .fbf-icon
    font-size: 144px
  .fbf-svg
    height: 80px
    width: auto
    max-width: 104px

.fbf-tool-bar__primary-action, .fbf-tool-bar > .fbf-button:first-child
  margin-left: 4px
  margin-right: 20px

  .fbf-sidebar &
    margin-left: 0
    margin-right: 16px

// As used to enter the title during e.g. creating a study activity
.fbf-tool-bar__primary-field
  input, label, .mdl-textfield, .fbf-text-field
    font-size: inherit !important
    line-height: inherit

// Needed to trump specificity of `.context--dark` color rules
.fbf-app-bar--collapsing .fbf-tool-bar
  .fbf-tool-bar__title
    @include text--truncate
    @include material-animation-default()
    transition-property: left
    opacity: 1
    position: absolute
    left: 72px
    @media(min-width: $desktop-layout-min-width)
      // Required inset to align at end of current logo
      left: 264px

    width: calc(#{$fbf-layout-center-column-width} + (80vw - #{$fbf-layout-center-column-width}) / 2)
    font-weight: 500
    margin-top: -72px
    right: auto // Disabling of simple `title` length cap that enables truncation
    color: transparentize(black, 0.8)

  .fbf-tool-bar__center-column
    h5, .text--h5
      position: absolute
      font-size: inherit
      font-weight: 400
      line-height: inherit
      margin-top: -26px

    .fbf-tool-bar__primary-field
      display: flex
      position: absolute
      width: 100%

      .fbf-text-field
        margin-top: -32px

      // DEPRECATED: use new fbf-text-field instead
      .mdl-textfield
        margin-top: -52px

    .fbf-tool-bar__title
      left: 0

.fbf-tool-bar--no-primary-action
  padding-left: 56px

// For aligning some content in the toolbar with the center column
.fbf-tool-bar__center-column
  position: relative
  width: $fbf-layout-center-column-width - 60px
  display: flex

  h1, h2, h3, h4, h5, h6, .mdl-textfield
    flex: auto

  .fbf-tool-bar__title
    left: 0

  .fbf-tool-bar__icon
    left: 0

    @media(max-width: $tablet-max-width)
      left: 64px

    .fbf-icon
      margin-top: -102px
      margin-left: -144px

    .fbf-svg
      margin-top: -84px
      margin-left: -128px

@media(max-width: $tablet-max-width)
  .fbf-tool-bar__center-column
    width: initial
    margin-left: 0
    flex-grow: 1

.fbf-detail-view, .fbf-page
  .fbf-tool-bar__center-column
    &.fbf-tool-bar__has-icon
      position: absolute
      left: calc((100% - #{$fbf-layout-center-column-width}) / 2 + 60px)
