.fbf-groupset-picker-dialog .fbf-dialog__header
  @include fbf-layout--flow-down
  align-items: flex-start

.fbf-groupset-picker-list
  @include fbf-layout--scroll-overflow
  .fbf-list-item
    position: relative

.fbf-groupset-picker-list-item__groups
  @include content-padding-lg
  @include fbf-grid(2, 24)
  align-items: flex-start
  background-color: $bg-grey
  padding-top: 0

  & > *
    margin-top: 24px

.fbf-groupset-picker-list-item__empty
  @include content-padding-lg
  align-items: flex-start
  background-color: $bg-grey

.fbf-group-picker__group
  .fbf-card--base
    @include fbf-card--base
    margin: 6px
    &.fbf-card--selected
      margin: 0
    & > .fbf-list-item
      @include fbf-layout--actionable
