.fbf-sidebar-button
  position: absolute
  bottom: 0
  left: 0
  right: 0
  height: 56px
  background-color: $color-palette-grey-300

  .fbf-sidebar-button__container
    @include fbf-layout--actionable
    @include fbf-layout--flow-right
    height: 100%
    width: 100%
    background-color: $color-palette-grey-100
    border: 0 solid $color-palette-grey-300
    border-top-width: 1px
    box-sizing: border-box
    padding-left: 16px
    padding-right: 96px

    .fbf-sidebar-button__text
      @include text--button
      padding-left: 8px
      padding-right: 12px

  &.fbf-sidebar-button--wrapped
    position: relative
