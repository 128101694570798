.context--dark
  .fbf-text-field__container
    background-color: transparentize(white, 0.95)
    border-bottom: 1px solid transparentize(white, 0.9)
  .fbf-text-field.fbf-text-field .fbf-text-field__container:after
      background-color: white
  .fbf-text-field__label
    @include text-light--muted-more
  .fbf-text-field__input
    @include text-light--unmuted
    &::placeholder
      @include text-light--muted-more
    &.fbf-text-field__input.fbf-text-field__input
      caret-color: white
  .fbf-text-field.has-label
    &.is-dirty, &.is-focused
      .fbf-text-field__label.fbf-text-field__label
        @include text-light--muted
    &.is-invalid .fbf-text-field__label
      @include text--red-light
  .fbf-text-field__leading-icon, .fbf-text-field__trailing-icon
    @include text-light--muted
  .fbf-text-field__clear
    &:focus, &:hover
      @include text-light--unmuted
  .fbf-text-field.is-invalid
    .fbf-text-field__error, .fbf-text-field__trailing-icon
      @include text--red-light
    .fbf-text-field__error
      border-radius: 0 0 4px 4px
      background-color: $color-palette-grey-900
