$button-min-width: 64px !default
$button-height: 36px !default
$button-padding: 16px !default
$button-margin: 4px !default
$button-border-radius: 2px !default

$button-fab-size: 56px !default
$button-fab-size-mini: 40px !default
$button-fab-font-size: 24px !default

$button-icon-size: 32px !default
$button-icon-size-mini: 24px !default

$button-text-color: transparentize(black, 0.5) !default
