$slide-bar-popover-width: 224px

.fbf-slide-bar
  display: flex
  background: $color-palette-grey-800
  height: 200px
  width: 100%
  position: relative
  align-items: flex-start

.fbf-slide-bar--compact
  height: auto

.fbf-slide-bar__scroller
  display: flex
  align-items: center
  overflow-x: auto
  // Needed to suppress addition of a vertical non-overlay scrollbar on some OS's
  overflow-y: hidden
  -webkit-overflow-scrolling: touch
  position: relative
  min-height: 200px
  flex-grow: 1

  // Workaround because `padding: 0 32px` is somehow ignored
  &:after, &:before
    content: ""
    display: block
    width: 32px
    height: 1px
    flex-shrink: 0

  .fbf-slide-bar--fab &
    top: -64px
    padding-top: 64px
    z-index: 10

  .fbf-slide-bar--bottom-sheet &
    top: -144px
    padding-top: 144px
    z-index: 10

  .fbf-button--fab-location
    @include shadow-8dp()
    position: absolute
    top: 8px
    transition-property: left
    transition-duration: 0.2s

.fbf-slide-bar__scroller
  .fbf-bottom-sheet
    width: $slide-bar-popover-width
    position: absolute
    bottom: 200px

    &.fbf-popover--align-start
      margin-left: -24px
    &.fbf-popover--align-center
      margin-left: - $slide-bar-popover-width / 2
    &.fbf-popover--align-end
      margin-left: -$slide-bar-popover-width + 24px

    .fbf-popover__content
      flex-wrap: nowrap

.fbf-slide-bar__prev,
.fbf-slide-bar__next
  position: absolute
  height: 100%
  width: 80px
  top: 0
  display: flex
  align-items: center
  justify-content: center
  z-index: 20
  pointer-events: none
  & > .fbf-button
    pointer-events: all

.fbf-slide-bar__prev
  left: 0
  background: linear-gradient(to right, $color-palette-grey-800, transparent)

.fbf-slide-bar__next
  right: 0
  background: linear-gradient(to left, $color-palette-grey-800, transparent)
