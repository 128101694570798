[data-ember-action]:not(:disabled),
[tabindex="0"],
[draggable],
.cursor--pointer
  cursor: pointer

[tabindex="-1"]:focus
  outline: none

.inlined-svg
  position: absolute
  top: 0
  left: 0
  & > *
    // These SVG sprites will normally span up a content block, so
    // to prevent them from causing overflow and unexpected
    // scrolling on very small devices we squash their size here
    width: 0
    height: 0
    position: absolute
    top: 0
    left: 0

.sortable-group
  // Nulling out base `ol` styles
  margin: 0
  padding-left: 0
  padding-right: 0
  list-style: none

.sortable-item
  // Always leave animations enabled or explicitly set
  // `transition-property: none` to avoid ember-sortable dragging hangup bug
  transition-property: all
  transition-duration: 0.2s

.is-dragging
  position: relative
  z-index: $z-base-index

.sr-only
  top: 0
  left: -2px
  width: 1px
  height: 1px
  position: absolute
  overflow: hidden
