.fbf-fruit-icon
  width: 40px
  height: 40px
  border-radius: 50%

.fbf-fruit-icon > svg
  width: 100%
  height: 100%
  color: #fff

.fbf-fruit-icon--crimson
  background-color: $color-palette-red-900

.fbf-fruit-icon--coral
  background-color: $color-palette-deep-orange-300

.fbf-fruit-icon--magenta
  background-color: $color-palette-red-A400

.fbf-fruit-icon--fucsia
  background-color: $color-palette-pink-700

.fbf-fruit-icon--burgundy
  background-color: $color-palette-pink-900

.fbf-fruit-icon--purple
  background-color: $color-palette-purple-600

.fbf-fruit-icon--violet
  background-color: $color-palette-deep-purple-300

.fbf-fruit-icon--pink
  background-color: $color-palette-pink-300

.fbf-fruit-icon--green
  background-color: $color-palette-green-800

.fbf-fruit-icon--chartreuse
  background-color: $color-palette-light-green-A700

.fbf-fruit-icon--yellow
  background-color: $color-palette-yellow-A400

.fbf-fruit-icon--gold
  background-color: $color-palette-amber-400

.fbf-fruit-icon--amber
  background-color: $color-palette-orange-400

.fbf-fruit-icon--ochre
  background-color: $color-palette-orange-700

.fbf-fruit-icon--orange
  background-color: $color-palette-deep-orange-600

.fbf-fruit-icon--red
  background-color: $color-palette-red-500

.fbf-fruit-icon--emerald
  background-color: $color-palette-green-400

.fbf-fruit-icon--turquois
  background-color: $color-palette-teal-A700

.fbf-fruit-icon--teal
  background-color: $color-palette-teal-700

.fbf-fruit-icon--mint
  background-color: $color-palette-teal-A400

.fbf-fruit-icon--cyan
  background-color: $color-palette-cyan-A400

.fbf-fruit-icon--azure
  background-color: $color-palette-blue-200

.fbf-fruit-icon--blue
  background-color: $color-palette-blue-500

.fbf-fruit-icon--navy
  background-color: $color-palette-indigo-800

.fbf-fruit-icon--bronze
  background-color: $color-palette-lime-900

.fbf-fruit-icon--umber
  background-color: $color-palette-brown-900

.fbf-fruit-icon--brown
  background-color: $color-palette-brown-600

.fbf-fruit-icon--kaki
  background-color: $color-palette-brown-200

.fbf-fruit-icon--silver
  background-color: $color-palette-grey-400

.fbf-fruit-icon--grey
  background-color: $color-palette-blue-grey-500

.fbf-fruit-icon--black
  background-color: $color-palette-black

.fbf-fruit-icon--indigo
  background-color: $color-palette-deep-purple-900
