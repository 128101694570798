@import '../fonts'
@import 'fbf-app-bar/color-support'

.fbf-app-bar
  @include shadow-2dp()
  display: flex
  flex-direction: column
  flex-shrink: 0
  z-index: $z-app-bar
  transition: box-shadow 0.2s, height 0.25s, opacity 0.25s

  &__elements
    @include fbf-layout--flow-down
    background-color: inherit

  &__extension
    &.fbf-rich-text
      @include fbf-layout--scroll-overflow
      padding-right: 16px
      padding-left: 16px

  &__extension--heading
    padding: 16px

    .fbf-sidebar &
      padding: 16px 24px

  & &__scrim-top
    position: absolute
    top: 0
    left: 0
    right: 0
    height: 72px

  & &__scrim-bottom
    position: absolute
    bottom: 0
    left: 0
    right: 0
    height: 72px

  & + &__extension
    @include shadow-2dp()

  &#{&}--fixed
    position: absolute
    top: 0
    right: 0
    left: 0

    // All these general and app-bar sibling selectors are a sign that we should
    // avoid having these styles at all. Once the app-bar is static positioned,
    // these can be removed.
    & + *
      transition: margin-top 0.25s

    &.fbf-app-bar--static + *
      margin-top: $fbf-tool-bar-height
    &.fbf-app-bar--static.fbf-app-bar--double-height + *
      margin-top: 2 * $fbf-tool-bar-height
    &.fbf-app-bar--static.fbf-app-bar--tabbed + *
      margin-top: $fbf-tool-bar-height + $fbf-tab-bar-height
    &.fbf-app-bar--transparent.fbf-app-bar--transparent.fbf-app-bar--overlapping + *
      margin-top: 0

  &#{&}--collapsing
    .fbf-tab-bar
      @include material-animation-default()
      position: relative
      transition-property: padding-left

      @media(min-width: $desktop-min-width)
        // Required inset for alignment with group name (based on current logo)
        padding-left: 264px

    .fbf-app-bar__background
      position: absolute
      height: 100%
      width: 100%
      // Hide cover image overflow
      overflow: hidden
      .fbf-image
        position: absolute

  &#{&}--no-shadow
    box-shadow: none

  &#{&}--tabs-collapsing
    .fbf-tool-bar
      z-index: 2
    .fbf-tab-bar
      z-index: 1

  &#{&}--cover-image:not(&--tabs-collapsing)
    background: none

    &::after
      content: ''
      position: absolute
      bottom: 0
      left: 0
      top: 0
      right: 0
      z-index: -1

  .fbf-tool-bar
    background-color: inherit

  .fbf-tab-bar
    padding-left: 52px

    @media(max-width: $tablet-max-width)
      padding-left: 64px

  .fbf-scrollable.fbf-scrollable--overflow-top &
    @include shadow-2dp

  // This is the fix that makes sure that a fullscreen dialog inside a page
  // is fully visible in iOS. This should be removed once there are no dialogs
  // inside pages anymore.
  .browser-ios &__sibling--has-fullscreen-dialog
    margin-top: 0 !important
    z-index: $z-app-bar

    .fbf-card
      z-index: auto

.fbf-app-bar--transparent
  background-color: transparent !important
  box-shadow: none

.fbf-app-bar--semi-transparent
  background-color: transparentize(black, 0.5)
  box-shadow: none

.fbf-app-bar--neutral.fbf-app-bar,
.fbf-app-bar--neutral.fbf-app-bar.fbf-app-bar--tabs-collapsing .fbf-tool-bar
  background-color: $bg-grey
