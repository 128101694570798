// When changed, don't forget to update highlightable mixin too
$highlight-duration: 0.3s
$error-highlight-duration: 1s
$guide-highlight-duration: 2s

@mixin material-animation-fast-out-slow-in($duration: 0.2s)
  transition-duration: $duration
  transition-timing-function: $animation-curve-fast-out-slow-in

@mixin material-animation-linear-out-slow-in($duration: 0.2s)
  transition-duration: $duration
  transition-timing-function: $animation-curve-linear-out-slow-in

@mixin material-animation-fast-out-linear-in($duration: 0.2s)
  transition-duration: $duration
  transition-timing-function: $animation-curve-fast-out-linear-in

@mixin material-animation-default($duration: 0.2s)
  transition-duration: $duration
  transition-timing-function: $animation-curve-default

@mixin pulse
  animation-name: pulse--opacity
  animation-duration: 0.8s
  animation-direction: alternate
  animation-iteration-count: infinite
  animation-timing-function: linear

@mixin pulse--fast
  animation-duration: 0.4s

@keyframes pulse--opacity
  from
    opacity: 1
  to
    opacity: 0.5


@mixin fade--in
  animation-name: fade--in
  animation-duration: 0.2s

@keyframes fade--in
  from
    opacity: 0
  to
    opacity: 1

@mixin fade--in-delayed
  animation-name: fade--in-delayed
  animation-duration: 1s

@keyframes fade--in-delayed
  0%
    opacity: 0
  80%
    opacity: 0
  100%
    opacity: 1

@mixin fade
  transition-property: visibility, opacity
  transition-duration: 0.2s
  transition-timing-function: ease-in

@mixin fade--invisible
  opacity: 0
  visibility: hidden

@mixin fade--visible
  opacity: 1
  visibility: visible

@mixin appear
  transition-property: visibility, opacity, transform
  transition-duration: 0.2s
  transition-timing-function: ease-in
  transform-origin: center center

@mixin appear--invisible
  opacity: 0
  visibility: hidden
  transform: scale(0.95)

@mixin appear--visible
  opacity: 1
  transform: scale(1) translateX(0) translateY(0) !important
  visibility: visible

@mixin appear--from-top
  transform-origin: top
  transform: scale(0.95) translateX(0) translateY(-16px)
@mixin appear--from-top-right
  transform-origin: top right
  transform: scale(0.95) translateX(16px) translateY(-16px)

@mixin highlight
  animation-name: highlight
  animation-duration: $highlight-duration

@keyframes highlight
  from
    background-color: transparent
  50%
    background-color: transparentize($color-blue, 0.7)
  to
    background-color: transparent

@mixin guide-highlight
  animation-name: guide-highlight
  animation-duration: $guide-highlight-duration

@keyframes guide-highlight
  15%
    background-color: transparent
  25%
    background-color: transparentize($color-primary, 0.85)
  65%
    background-color: transparent
  75%
    background-color: transparentize($color-primary, 0.85)
  to
    background-color: transparent

@keyframes error-highlight
  from
    background-color: transparent
  25%
    background-color: transparentize($color-red, 0.7)
  50%
    background-color: transparent
  75%
    background-color: transparentize($color-red, 0.7)
  to
    background-color: transparent

@mixin error-highlight
  animation-name: error-highlight
  animation-duration: $error-highlight-duration
