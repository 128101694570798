.fbf-mini-card
  width: $fbf-mini-card-width
  display: flex
  flex-flow: column nowrap
  justify-content: space-between

  &__content
    @include fbf-layout--flow-down
    border-radius: 4px
    overflow: hidden
    flex-grow: 1

  &.fbf-popover--arrow-bottom
    .fbf-popover__arrow
      fill: $bg-grey
