.fbf-popover
  display: flex
  @include shadow-6dp
  z-index: $z-dialog
  background-color: white
  border-radius: 4px
  position: relative

.fbf-popover--animated
  @include appear
  @include appear--invisible
  &.fbf-popover--visible
    @include appear--visible

.fbf-popover--absolute
  position: absolute

.fbf-popover--dark
  background-color: $color-palette-grey-800

  .fbf-popover__arrow
    fill: $color-palette-grey-800

.fbf-popover__content
  @include fbf-layout--flow-down
  flex-grow: 1

.fbf-popover--padded .fbf-popover__content
  padding: 16px

.fbf-popover__header
  margin-top: 16px

.fbf-popover__arrow
  position: absolute
  fill: white

.fbf-popover--arrow-top, .fbf-popover--arrow-bottom
  .fbf-popover__arrow
    width: 16px
    height: 8px

  &.fbf-popover--align-start .fbf-popover__arrow
    left: 16px

  &.fbf-popover--align-center .fbf-popover__arrow
    left: 50%
    margin-left: -8px

  &.fbf-popover--align-end .fbf-popover__arrow
    right: 16px

.fbf-popover--arrow-left, .fbf-popover--arrow-right
  .fbf-popover__arrow
    height: 16px
    width: 8px

  &.fbf-popover--align-start .fbf-popover__arrow
    top: 16px

  &.fbf-popover--align-center .fbf-popover__arrow
    top: 50%
    margin-top: -8px

  &.fbf-popover--align-end .fbf-popover__arrow
    bottom: 16px

.fbf-popover--arrow-top
  margin-top: 8px
  transform-origin: top center

  .fbf-popover__arrow
    top: -8px
    filter: drop-shadow(1px -2px 2px rgba(black, .1))

.fbf-popover--arrow-right
  margin-right: 8px
  transform-origin: center right

  .fbf-popover__arrow
    right: -8px
    filter: drop-shadow(4px 4px 2px rgba(black, .1))

.fbf-popover--arrow-bottom
  margin-bottom: 8px
  transform-origin: bottom center

  .fbf-popover__arrow
    bottom: -8px
    filter: drop-shadow(2px 4px 2px rgba(black, .2))

.fbf-popover--arrow-left
  margin-left: 8px
  transform-origin: center left

  .fbf-popover__arrow
    left: -8px
    filter: drop-shadow(-3px 2px 2px rgba(black, .1))

.fbf-popover__return-button
  vertical-align: middle
  margin-top: -8px
  margin-left: 16px

.fbf-popover
  &--inline
    @include shadow-2dp

    &.fbf-popover--arrow-bottom .fbf-popover__arrow
      filter: drop-shadow(1px 1px 1px rgba(black, .4))

  &__container
    position: relative

  &__container &
    position: absolute

    &.fbf-popover--arrow-top
      top: calc(100% + 16px)
    &.fbf-popover--arrow-bottom
      bottom: calc(100% + 16px)

    &.fbf-popover--arrow-top.fbf-popover--align-start
      left: calc(50% - 24px)
    &.fbf-popover--arrow-left.fbf-popover--align-start,
    &.fbf-popover--arrow-right.fbf-popover--align-start
      top: -8px
    &.fbf-popover--arrow-bottom.fbf-popover--align-start
      left: calc(50% - 24px)

    &.fbf-popover--arrow-left
      left: calc(100% + 16px)
    &.fbf-popover--arrow-right
      right: calc(100% + 16px)

    &.fbf-popover--arrow-top.fbf-popover--align-center,
    &.fbf-popover--arrow-bottom.fbf-popover--align-center
      left: 50%
      transform: translateX(-50%)
    &.fbf-popover--arrow-left.fbf-popover--align-center,
    &.fbf-popover--arrow-right.fbf-popover--align-center
      top: 50%
      transform: translateY(-50%)

    &.fbf-popover--arrow-top.fbf-popover--align-end
      right: calc(50% - 24px)
    &.fbf-popover--arrow-bottom.fbf-popover--align-end
      right: calc(50% - 24px)
    &.fbf-popover--arrow-left.fbf-popover--align-end,
    &.fbf-popover--arrow-right.fbf-popover--align-end
      bottom: calc(50% - 24px)
