.fbf-sidebar--empty__container
  position: absolute
  height: 100%
  pointer-events: none
  top: 0
  @include fbf-layout--flow-down

.fbf-sidebar--empty__arrow-container
  flex-grow: 1
  margin-right: auto
  position: relative

.fbf-sidebar--empty__arrow
  position: absolute
  height: 100%

.fbf-sidebar--empty__margin
  height: 50px
