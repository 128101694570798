.fbf-layout__activity-icon
  @include fbf-layout__activity-icon

.fbf-layout__card-list-item
  @include fbf-layout__card-list-item

.fbf-layout__margin--app-bar
  @include fbf-layout--margin-app-bar
.fbf-layout__pad--app-bar
  @include fbf-layout--pad-app-bar
.fbf-layout__pad--app-bar-collapsing
  @include fbf-layout--pad-app-bar-collapsing
