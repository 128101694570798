.fbf-landing__extensions-panel
  .fbf-landing__panel__column
    @include fbf-layout__center-column--normal-width

.fbf-landing--platform .fbf-landing__extensions-panel
  background-color: transparent
  box-shadow: none
  .fbf-landing__panel__column
    @include fbf-card--base
    @media (min-width: $desktop-min-width)
      border-radius: 4px
    margin-bottom: 40px
