$spacer: 16px;
$spacers: (
  0: 0,
  'xs': ($spacer * .25),
  'sm': ($spacer * .5),
  'md': $spacer,
  'lg': ($spacer * 1.5),
  'xl': ($spacer * 2)
);

@each $property in 'margin', 'padding' {
  @each $size, $length in $spacers {
    .#{$property}-#{$size} {
      #{$property}: $length !important;
    }

    .#{$property}-top-#{$size},
    .#{$property}-y-#{$size} {
      #{$property}-top: $length !important;
    }

    .#{$property}-right-#{$size},
    .#{$property}-x-#{$size} {
      #{$property}-right: $length !important;
    }

    .#{$property}-bottom-#{$size},
    .#{$property}-y-#{$size} {
      #{$property}-bottom: $length !important;
    }

    .#{$property}-left-#{$size},
    .#{$property}-x-#{$size} {
      #{$property}-left: $length !important;
    }
  }

  .#{$property}-bottom-toolbar {
    #{$property}-bottom: $fbf-tool-bar-height;
  }

  .#{$property}-top-toolbar {
    #{$property}-top: $fbf-tool-bar-height;
  }
}

@each $property in 'margin' {
  @each $size, $length in $spacers {
    .anti#{$property}-#{$size} {
      #{$property}: -$length !important;
    }

    .anti#{$property}-top-#{$size},
    .anti#{$property}-y-#{$size} {
      #{$property}-top: -$length !important;
    }

    .anti#{$property}-right-#{$size},
    .anti#{$property}-x-#{$size} {
      #{$property}-right: -$length !important;
    }

    .anti#{$property}-bottom-#{$size},
    .anti#{$property}-y-#{$size} {
      #{$property}-bottom: -$length !important;
    }

    .anti#{$property}-left-#{$size},
    .anti#{$property}-x-#{$size} {
      #{$property}-left: -$length !important;
    }
  }
}

$border-widths: (1, 2, 4, 8);

@each $width in $border-widths {
  @each $color-name, $color in $colors-grey {
    @if $color-name != '' {
      $color-name: -#{$color-name};
    }

    .border-#{$width}#{$color-name} {
      border: #{$width}px solid $color;
    }

    .border-top-#{$width}#{$color-name},
    .border-y-#{$width}#{$color-name} {
      border-top: #{$width}px solid $color;
    }

    .border-right-#{$width}#{$color-name},
    .border-x-#{$width}#{$color-name} {
      border-right: #{$width}px solid $color;
    }

    .border-bottom-#{$width}#{$color-name},
    .border-y-#{$width}#{$color-name} {
      border-bottom: #{$width}px solid $color;
    }

    .border-left-#{$width}#{$color-name},
    .border-x-#{$width}#{$color-name} {
      border-left: #{$width}px solid $color;
    }
  }
}

$corner-radius: 8px;
$corners: (
  0: 0,
  'xs': ($corner-radius * .25),
  'sm': ($corner-radius * .5),
  'md': $corner-radius,
  'lg': ($corner-radius * 1.5),
  'xl': ($corner-radius * 2)
);

@each $size, $radius in $corners {
  .border-radius-#{$size} {
    border-radius: $radius !important;
  }
}

.content-padding-sm, .context--beamer .content-padding-xs {
  @include content-padding-sm
}
.content-padding-md, .context--beamer .content-padding-sm {
  @include content-padding-md
}
.content-padding-lg, .context--beamer .content-padding-md {
  @include content-padding-lg
}


$block-sizes: (0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 25);

@each $size in $block-sizes {
  .block-width-#{$size} {
    @include block-width($size);
  }
  .block-height-#{$size} {
    @include block-height($size);
  }
  .max-block-width-#{$size} {
    @include max-block-width($size);
  }
  .max-block-height-#{$size} {
    @include max-block-height($size);
  }
  .min-block-width-#{$size} {
    @include min-block-width($size);
  }
  .min-block-height-#{$size} {
    @include min-block-height($size);
  }
}

$positions: (absolute, fixed, relative, static);

@each $position in $positions {
  .position-#{$position} {
    position: $position !important
  }
}

$displays: (block, inline-block, flex, inline-flex);

@each $display in $displays {
  .display-#{$display} {
    display: $display !important
  }
}

$flex-values: (0, 1);

@each $value in $flex-values {
  @each $property in (flex-grow, flex-shrink) {
    .#{$property}-#{$value} {
      #{$property}: $value;
    }
  }
}
