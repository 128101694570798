@mixin caret-color-variant($color)
  border-top-color: $color

@mixin caret-disabled
  &::after
    opacity: 0.5
    border-top-color: transparentize(black, 0.5)

  .context--dark &::after
    @include caret-color-variant(white)

@mixin caret
  &::after
    content: ''
    width: 0
    height: 0
    border: 6px solid transparent
    border-top-color: transparentize(black, 0.5)
    margin-left: 8px
    margin-top: 6px

  @each $color-name, $color in $colors
    .context--#{$color-name} &::after
      @include caret-color-variant($color)

  .context--dark &::after
    @include caret-color-variant(white)

.caret
  @include caret

.caret--disabled
  @include caret-disabled
