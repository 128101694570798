.fbf-docked-dialog
  transition: width 0.2s
  background-color: white
  border-radius: 8px 8px 0 0
  @include shadow-6dp()
  z-index: $z-dialog
  display: flex
  flex-direction: column
  max-height: 50vh

  &.fbf-docked-dialog--collapsed
    width: 250px
    .fbf-docked-dialog__header
      cursor: pointer
      > .fbf-icon
        margin-right: 16px
    .fbf-docked-dialog__content
      height: 0px
      width: 0px
      overflow: hidden

  &.fbf-docked-dialog--expanded
    align-self: stretch
    .fbf-docked-dialog__header
      > .fbf-icon
        margin-left: 8px
        margin-right: 24px

  &.fbf-docked-dialog--fullscreen
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    width: auto !important
    max-height: none
    margin: 0
    border-radius: 0

    .fbf-docked-dialog__header
      border-radius: 0

  // TODO: Remove these tab style overrides once header refactoring is completed:
  // @link: https://github.com/feedbackfruits/feedbackfruits-ui-styles/issues/91
  .fbf-tab-bar
    background-color: $color-palette-grey-800
    flex-shrink: 0

.fbf-docked-dialog__header
  height: $fbf-tool-bar-height
  align-items: center
  padding-left: 16px
  padding-right: 16px
  border-radius: 8px 8px 0 0
  background-color: $color-palette-grey-800
  display: flex
  flex-shrink: 0

  .fbf-icon
    color: white

  .fbf-badge
    margin-left: 8px

  h5
    flex-grow: 1

  .fbf-actionbar
    display: flex
    margin-left: auto
    color: white


.fbf-docked-dialog__content
  transition: height 0.2s
  display: flex
  flex-direction: column
  flex-shrink: 1
  overflow-y: auto
