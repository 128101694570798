.fbf-landing__extension-intro-panel
  .fbf-image
    height: 100vh
  .fbf-layout__hero-unit .context--dark
    text-align: center
  .fbf-card
    margin-top: 40px
    margin-bottom: 24px
    align-self: center
    border-radius: 4px
    .fbf-app-logo
      margin-left: 8px
    .fbf-layout--flow-right
      justify-content: center
