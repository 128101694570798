.fbf-list-item, .fbf-list-item__wrapper
  display: flex
  flex-shrink: 0
  // Heads
  & > .fbf-activity-icon
    flex: 0 0 auto
    align-self: center
    margin: 10px 0 10px 10px
  & > .fbf-icon, & > .fbf-svg, & > .fbf-checkbox
    flex: 0 0 auto
    align-self: center
    justify-content: center
  & > .fbf-icon.fbf-icon--h2:first-child
    margin-left: 13px
  & > .fbf-profile-picture, & > .fbf-list-item__avatar
    flex: 0 0 auto
    align-self: center
    justify-content: center
    margin: 8px 0 8px 16px
  & > .fbf-list-item__head
    flex: 0 0 auto
    align-self: center
    justify-content: center
    margin: 16px 0 16px 16px
  & > .fbf-list-item__icon
    flex: 0 0 auto
    width: 24px
    height: 24px
    align-self: center
    &.fbf-list-item__icon--large
      width: 80px
      height: 80px
  & > .fbf-list-item__icon, & > .fbf-icon, & > .fbf-svg, & > .fbf-checkbox
    &.fbf-list-item__head, &:first-child
      margin: 12px 0 12px 16px
      &:empty
        margin: 0 0 0 16px
    &.fbf-list-item__tail, &:last-child
      margin: 12px 16px 12px 0
      &:empty
        margin: 0

  & > .fbf-button--icon
    width: initial
    height: initial
    &.fbf-list-item__head, &:first-child
      margin: 8px -4px 8px 12px
    &.fbf-list-item__tail, &:last-child
      margin: 8px 12px 8px -4px
  &.fbf-activity-list-item__header
    & > .fbf-activity-icon
      margin: 6px
      &.fbf-profile-picture
        margin: 12px
    & > .fbf-activity-icon + .fbf-list-item__lines
      margin-left: 0

  &.fbf-list-item--step
    position: relative

    &::after
      background-color: transparentize(black, 0.9)
      width: 1px
      height: calc(100% - 46px)
      content: " "
      margin-top: 60px
      margin-left: 32px
      position: absolute

    &:not(.fbf-activity-list-item__header)::after
      height: calc(100% - 36px)
      margin-top: 50px

    &:last-of-type:not(.fbf-activity-list-item__header)::after
      visibility: hidden

    .fbf-activity-icon-plain + .fbf-list-item__lines
      margin-left: 0

  &.fbf-list-item--no-border
    border: none !important

  &.fbf-list-item--spinner
    @include min-block-height(1)

  &.fbf-list-item--selected
    @include fbf-layout--selected

.fbf-list-item__wrapper
  flex-grow: 1
  flex-shrink: 1
  max-width: 100%
  overflow: hidden

// Body
.fbf-list-item__lines
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  // Workaround for flex bug; remove once fixed in browsers
  // (https://github.com/philipwalton/flexbugs)
  min-width: 1px

  flex: 1 1 auto
  margin-left: 16px
  margin-right: 16px
  margin-top: 12px
  margin-bottom: 12px

  word-break: break-word

  & > a
    width: 100%

.fbf-list-item
  & > .fbf-activity-icon + .fbf-list-item__lines
    margin-left: 10px
  & > .fbf-icon.fbf-icon--h2 + .fbf-list-item__lines
    margin-left: 14px


.fbf-list-item__primary, .fbf-list-item__secondary
  display: flex
  align-items: center
  align-self: stretch
  // This might have unintended layout consequences. If so, needs to be
  // scoped in a `fbf-list-item--convo` selector.

  &:nth-child(2)
    flex-shrink: 0

  &.text--truncate
    display: block

.fbf-list-item__suffix
  flex-shrink: 0

.fbf-chip--deadline
  margin-top: 8px
  box-sizing: border-box

  .fbf-list-item__lines--full-width
    > *
      width: 100%

// Tails
.fbf-list-item__tail
  @include fbf-layout--flow-right
  flex: 0 0 auto
  align-self: center
  justify-content: center
  margin: 12px 16px 12px 0

  @media (max-width: $tablet-max-width)
    max-width: 50%

  &:empty
    margin: 0

  // DEPRECATED: use '.text--green' instead
  &.status-completed, .status-completed
    .fbf-icon, small
      color: $color-primary

  .fbf-mini-progress-bar
    width: 160px

.fbf-list-item__media
  display: flex
  flex-shrink: 0
  width: 136px
  height: 88px
  align-self: center

@mixin fbf-list-item__media--large
  width: 256px
  height: 178px
.fbf-list-item__media--large
  @include fbf-list-item__media--large

.fbf-list-item__lines + .fbf-list-item__icon
  margin-left: 0

// Deprecated: use layout.sass classes
.fbf-list-item__tail--as-column
  flex-direction: column

.fbf-list-item__tail-icons
  display: flex

.fbf-list-item--multiline >
  .fbf-icon, .fbf-svg, .fbf-checkbox, .fbf-list-item__icon, .fbf-list-item__avatar,
  .fbf-profile-picture, .fbf-activity-icon, .fbf-list-item__head,
  .fbf-list-item__tail
    align-self: flex-start
  .fbf-list-item__lines
    justify-content: flex-start
  .fbf-profile-picture
    margin-top: 16px

.fbf-list-item--padding, .fbf-list-item--padding-16dp
  padding: 16px

.fbf-list-item--muted
  & > *
    opacity: 0.3

.fbf-list-item--single
  max-height: 48px

  & > .fbf-activity-icon
    width: 30px // compensate for visual weight
    margin: 10px 12px 10px 14px

.fbf-list-item--tree-node__header
  > .fbf-button
    margin: 0 0 0 12px
  > .fbf-activity-icon
    margin-top: 0
    margin-bottom: 0
  > .fbf-button + .fbf-activity-icon
    margin-left: 10px
  > .fbf-list-item__tail
    margin-top: 0
    margin-bottom: 0

  &.fbf-list-item--single
    max-height: initial

    > .fbf-list-item__lines
      margin-left: 0
    > .fbf-button--icon + .fbf-icon
      margin: 12px 16px 12px 12px
    > .fbf-checkbox
      margin-left: 16px
      .fbf-checkbox__icon
        margin: 0

  &.fbf-list-item--empty > .fbf-list-item__lines
    margin-left: 56px

  &.fbf-list-item--muted > .fbf-button
    opacity: 1

  &.fbf-list-item--selected
    background-color: $selected-color

  &.fbf-list-item--new
    > .fbf-list-item__lines
      margin-left: 16px
      margin-right: 0
    > .fbf-list-item__tail
      margin-right: 0


// For select use. For adding border to every list item, use parent (.fbf-list) variant:
// `.fbf-list--bordered`
.fbf-list-item--bordered
  border-top: 1px solid $color-palette-grey-100

.context--dark .fbf-list-item--bordered
  border-top: 1px solid transparentize(white, 0.8)

.context--beamer .fbf-list-item
  .fbf-list-item__lines
    margin: 24px
  .fbf-list-item__icon, .fbf-list-item__avatar, .fbf-list-item__head
    margin: 24px 0 24px 24px
  & > .fbf-activity-icon
    align-self: center
    margin: 20px 0 20px 20px
  .fbf-list-item__tail
    margin: 24px 24px 24px 0

.fbf-list-item--active
  background-color: transparentize(black, 0.90) !important
  &:hover
    background-color: transparentize(black, 0.85) !important

.context--dark .fbf-list-item--active
  background-color: transparentize(white, 0.90) !important
  &:hover
    background-color: transparentize(white, 0.85) !important

.fbf-list-item--actionable
  @include fbf-layout--actionable

.fbf-list-item--actionable-darker
  cursor: pointer
  &:hover
    background-color: $bg-grey--300

.fbf-list-item--grey
  background-color: $bg-grey--100

  &.fbf-list-item--bordered
    border-top: 1px solid $color-palette-grey-300

.fbf-switch.fbf-list-item__head, .fbf-switch.fbf-list-item__tail
  margin-top: 10px
  margin-bottom: 10px

.fbf-list-item iframe.fbf-list-item__video
  display: flex

  @media(max-width: $tablet-max-width)
    width: 100%
    height: 41vw

  @media(min-width: $desktop-min-width)
    align-self: center
    height: 175px
    width: 298px

.fbf-list-item__tail.fbf-selectfield
  margin-top: 0
  margin-bottom: 0
  &.fbf-selectfield--no-label
    padding-top: 20px
  @media(max-width: $tablet-max-width)
    max-width: 50%
    padding-right: 16px
