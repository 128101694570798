@import 'colorscheme';
@import 'gradients';

$colors: (
  'blue': $color-blue,
  'blue-grey': $color-blue-grey,
  'brown': $color-brown,
  'cyan': $color-cyan,
  'dark-purple': $color-purple-dark,
  'green': $color-green,
  'orange': $color-orange,
  'platform': $color-primary,
  'primary': $color-primary,
  'purple': $color-purple,
  'red': $color-red,
  'teal': $color-teal,
  'yellow': $color-yellow
);

$colors-dark: (
  'blue': $color-blue-dark,
  'blue-grey': $color-blue-grey-dark,
  'brown': $color-brown-dark,
  'cyan': $color-cyan-dark,
  'dark-purple': $color-purple-dark,
  'green': $color-green-dark,
  'orange': $color-orange-dark,
  'platform': $color-primary-dark,
  'primary': $color-primary-dark,
  'purple': $color-purple-dark,
  'red': $color-red-dark,
  'teal': $color-teal-dark,
  'yellow': $color-yellow-dark
);

$colors-light: (
  'blue': $color-blue-light,
  'blue-grey': $color-blue-grey-light,
  'brown': $color-brown-light,
  'cyan': $color-cyan-light,
  'dark-purple': $color-purple-dark,
  'green': $color-green-light,
  'orange': $color-orange-light,
  'platform': $color-primary-light,
  'primary': $color-primary-light,
  'purple': $color-purple-light,
  'red': $color-red-light,
  'teal': $color-teal-light,
  'yellow': $color-yellow-light
);

$colored-gradients: (
  'blue': $gradient-blue,
  'blue-grey': $gradient-blue-grey,
  'brown': $gradient-brown,
  'cyan': $gradient-cyan,
  'dark-purple': $gradient-purple,
  'green': $gradient-green,
  'orange': $gradient-orange,
  'platform': $gradient-primary,
  'primary': $gradient-primary,
  'purple': $gradient-purple,
  'red': $gradient-red,
  'teal': $gradient-teal,
  'yellow': $gradient-yellow
);

$colors-grey: (
  '': transparentize(black, 0.9), // default value
  50: $color-palette-grey-50,
  100: $color-palette-grey-100,
  200: $color-palette-grey-200,
  300: $color-palette-grey-300,
  400: $color-palette-grey-400,
  500: $color-palette-grey-500,
  600: $color-palette-grey-600,
  700: $color-palette-grey-700,
  800: $color-palette-grey-800,
  900: $color-palette-grey-900
)
