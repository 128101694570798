.fbf-layout--flow-down
  @include fbf-layout--flow-down
.fbf-layout--flow-right
  @include fbf-layout--flow-right
.fbf-layout--flow-wrap
  @include fbf-layout--flow-wrap
.fbf-layout--flow-nowrap
  @include fbf-layout--flow-nowrap

.fbf-layout--flex-spread
  @include fbf-layout--flex-spread
.fbf-layout--flex-center
  @include fbf-layout--flex-center
.fbf-layout--flex-end
  @include fbf-layout--flex-end

.fbf-layout--self-center
  @include fbf-layout--self-center

.fbf-layout--align-items-start
  @include fbf-layout--align-items-start

.fbf-layout--scroll-overflow
  @include fbf-layout--scroll-overflow
