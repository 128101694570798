@import '../platform/layout'

$profile-picture-small-size: 24px
$profile-picture-medium-size: 40px
$profile-picture-large-size: 64px
$profile-picture-huge-size: 128px

.fbf-profile-picture
  flex-shrink: 0
  justify-content: center
  display: flex
  position: relative

.fbf-profile-picture--online
  &::after
    content: ""
    width: 8px
    height: 8px
    border: 2px white solid
    background-color: $color-palette-green-400
    display: block
    border-radius: 50%
    position: absolute
    bottom: -2px
    right: -2px
    .context--dark &
      background-color: $color-palette-green-300
      border-width: 0
      bottom: 0
      right: 0

.fbf-profile-picture .fbf-profile-picture__admin
  font-size: 12px
  color: $color-palette-blue-grey-500
  background-color: white
  border-radius: 50%
  position: absolute
  bottom: -2px
  left: -2px
  border: 1px solid white
  .context--dark &
    color: white
    background-color: $color-palette-blue-grey-800
    border-width: 0

.fbf-profile-picture .fbf-fruit-icon
  width: 100%
  height: 100%

@mixin profile-picture-small
  width: $profile-picture-small-size
  height: $profile-picture-small-size

@mixin profile-picture-medium
  width: $profile-picture-medium-size
  height: $profile-picture-medium-size

@mixin profile-picture-large
  width: $profile-picture-large-size
  height: $profile-picture-large-size

  &.fbf-profile-picture--online
    &::after
      width: 12px
      height: 12px
      bottom: 0px
      right: 0px

  .fbf-profile-picture__admin
    font-size: 18px
    border-width: 2px
    bottom: -2px
    left: -2px

@mixin profile-picture-huge
  width: $profile-picture-huge-size
  height: $profile-picture-huge-size

  &.fbf-profile-picture--online
    &::after
      width: 16px
      height: 16px
      bottom: 10px
      right: 10px
      .context--dark &
        bottom: 12px
        right: 12px

  .fbf-profile-picture__admin
    font-size: 24px
    border-width: 2px
    bottom: 4px
    left: 4px

.fbf-profile-picture
  flex-shrink: 0
  position: relative
  @include profile-picture-medium

.fbf-profile-picture--small
  @include profile-picture-small

.fbf-profile-picture--large
  @include profile-picture-large

.fbf-profile-picture--huge
  @include profile-picture-huge

.context--beamer
  .fbf-profile-picture
    @include profile-picture-large

.fbf-profile-picture--action-icon
  .icon-backdrop
    @include fbf-layout--flex-center
    border-radius: 50%
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    background-color: transparentize(black, 0.5)
    opacity: 0
    transition-property: opacity
    transition-duration: 0.2s
    .fbf-icon
      color: white

  &:hover
    .icon-backdrop
      opacity: 1

.fbf-profile-picture:not(.fbf-profile-picture--action-icon).fbf-profile-picture--actionable:hover
  cursor: pointer

  > *:first-child
    filter: contrast(110%) brightness(120%)

.fbf-profile-picture--highlighted
  .fbf-text-icon, .fbf-image--circle
    animation-name: pulseHighlightBorder
    animation-duration: 0.8s
    animation-direction: alternate
    animation-iteration-count: infinite
    animation-timing-function: linear

@keyframes pulseHighlightBorder
  from
    box-shadow: $color-accent 0 0 0 4px

  to
    box-shadow: transparent 0 0 0 4px

.fbf-profile-picture__anonymous
  font-size: 48px !important
