$linear-bar-height: 4px
$linear-bar-height--small: 2px

$linear-bar__average-height: 32px
$linear-bar__average-width: 2px
$linear-bar__average-padding--horizontal: 16px
$linear-bar__average-padding--vertical: 8px

@mixin linear-bar($height: $linear-bar-height)
  display: flex
  flex-direction: row
  position: relative
  margin-top: $height
  margin-bottom: $height
  height: $height
  width: 100%
  background-color: transparentize(black, 0.9)

@mixin linear-bar--white
  background-color: transparentize(white, 0.9)

@mixin linear-bar__element-color-variant($color)
  background-color: $color

@mixin linear-bar__interval
  display: flex
  position: absolute
  top: 0
  bottom: 0
  width: 0%
  transition: width 0.2s $animation-curve-default

@mixin linear-bar__interval--actionable
  cursor: pointer
  transition-property: top, bottom
  transition-duration: 0.2s
  transition-timing-function: $animation-curve-default
  .fbf-linear-bar__label
    transition-property: bottom, top, visibility, opacity, transform
    transition-duration: 0.2s
    transition-timing-function: $animation-curve-default
  &:hover
    @include shadow-4dp
    top: -2px
    bottom: -2px
    .fbf-linear-bar__label--top
      bottom: 12px
    .fbf-linear-bar__label--bottom
      top: 12px

@mixin linear-bar__point--size($size)
  top: calc(50% - (#{$size} / 2))
  margin-left: -($size / 2)
  height: $size
  width: $size

@mixin linear-bar__point
  @include fbf-layout--flex-center
  display: flex
  position: absolute
  border-radius: 50%

  &.size-tiny
    @include linear-bar__point--size(4px)
  &.size-small
    @include linear-bar__point--size(8px)
  &.size-medium
    @include linear-bar__point--size(16px)
  &.size-large
    @include linear-bar__point--size(24px)
  &.size-huge
    @include linear-bar__point--size(32px)

@mixin linear-bar__point--actionable
  margin-left: 0
  cursor: pointer
  transition-property: height, width, top, margin-left
  transition-duration: 0.2s
  transition-timing-function: $animation-curve-default
  .fbf-linear-bar__label
    transition-property: left, bottom, top, visibility, opacity, transform
    transition-duration: 0.2s
    transition-timing-function: $animation-curve-default
  &:hover, &.linear-bar__point--highlighted
    @include shadow-4dp
    &.size-small
      margin-left: -8px
      top: -6px
      height: 16px
      width: 16px
      .fbf-linear-bar__label
        &--top, &--top-right, &--top-left
          bottom: 20px
        &--bottom, &--bottom-right, &--bottom-left
          top: 20px

@mixin linear-bar__average
  position: absolute
  top: 50%
  height: $linear-bar__average-height
  transform: translate(-50%, -50%)
  transform-origin: center
  padding: $linear-bar__average-padding--vertical $linear-bar__average-padding--horizontal

  &:focus, &:hover
    &::after
      transform: scaleY(1.2)

  &::after
    content: ''
    position: absolute
    border-left: $linear-bar__average-width dashed $color-palette-grey-400
    top: $linear-bar__average-padding--vertical
    bottom: $linear-bar__average-padding--vertical
    left: calc(50% - #{$linear-bar__average-width} / 2)
    transition: transform .2s ease-in
    transform-origin: center
    animation: appear .4s

  @keyframes appear
    from
      transform: scaleY(0)
    to
      transform: scaleY(1)

@mixin linear-bar__label
  @include appear
  display: flex
  position: absolute
  padding: 4px 10px
  color: white
  white-space: nowrap

.fbf-linear-bar
  @include linear-bar

  &__interval
    @include linear-bar__interval
  &__interval--actionable
    @include linear-bar__interval--actionable

  &__point
    @include linear-bar__point
  &__point--actionable
    @include linear-bar__point--actionable

  &__average
    @include linear-bar__average

  &__label
    @include linear-bar__label
    &.is-visible
      @include appear--visible
    &.is-hidden
      @include appear--invisible

    &--top, &--top-left
      border-radius: 16px 16px 16px 0
      transform-origin: bottom left
      .fbf-linear-bar__interval &
        left: 0
        bottom: 8px
      .fbf-linear-bar__point &
        left: 4px
        bottom: 10px
    &--top-right
      transform-origin: bottom right
      border-radius: 16px 16px 0 16px
      .fbf-linear-bar__interval &
        right: 0
        bottom: 8px
      .fbf-linear-bar__point &
        right: 4px
        bottom: 10px
    &--bottom, &--bottom-left
      transform-origin: top left
      border-radius: 0 16px 16px 16px
      .fbf-linear-bar__interval &
        left: 0
        top: 8px
      .fbf-linear-bar__point &
        left: 4px
        top: 10px
    &--bottom-right
      transform-origin: top right
      border-radius: 16px 0 16px 16px
      .fbf-linear-bar__interval &
        right: 0
        top: 8px
      .fbf-linear-bar__point &
        right: 4px
        top: 10px

  &__interval, &__point
    .fbf-linear-bar__label.is-muted
      @include appear--invisible
    &:hover .fbf-linear-bar__label.is-muted
      @include appear--visible

  &--small
    @include linear-bar($linear-bar-height--small)

.context--dark .fbf-linear-bar
  @include linear-bar--white

.fbf-linear-bar
  &__interval,
  &__point,
  &__interval.color-black.color-black,
  &__point.color-black.color-black,
  &__interval.color-grey.color-grey,
  &__point.color-grey.color-grey
    @include linear-bar__element-color-variant($color-palette-grey-400)
    .fbf-linear-bar__label
      @include linear-bar__element-color-variant($color-palette-grey-400)

  @each $color-name, $color in $colors
    &__interval.color-#{$color-name}.color-#{$color-name},
    &__point.color-#{$color-name}.color-#{$color-name},
    .context--#{$color-name} &__interval,
    .context--#{$color-name} &__point
      @include linear-bar__element-color-variant($color)
      .fbf-linear-bar__label
        @include linear-bar__element-color-variant($color)
