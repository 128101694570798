.fbf-detail-view
  @include fbf-layout--flow-down
  @include fbf-layout__fullscreen-page
  z-index: $z-detail-view
  background-color: $bg-grey

  &__content
    @include fbf-layout--flow-down
    @include fbf-layout--scroll-overflow
    @include fbf-layout__column-container--padding-app-bar
    align-items: center
    z-index: initial
    flex-grow: 1

  > .fbf-app-bar
    flex-shrink: 0
    z-index: 3
    transition-duration: .35s
    transition-property: margin-top, visibility, height, opacity
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)

  > .fbf-app-bar__extension
    z-index: 2

  // Mobile first: flow main + sidebar under eachother
  &__container
    @include fbf-layout--flow-down
    @include fbf-layout--scroll-overflow
    height: 100%
    transition: margin-top .3s

    main
      @include fbf-layout--flow-down
      flex: 0 0 100%
      overflow: hidden
      position: relative
      box-sizing: border-box

  // Full layout view: main + optionally folded out sidebar next to eachother
  @media(min-width: $desktop-layout-min-width)
    .fbf-detail-view__container
      flex-direction: row
      overflow: hidden

      main
        flex: auto
        min-width: 1

.browser-ios .fbf-detail-view .fbf-detail-view__container
  z-index: 1

  &.fbf-app-bar__sibling--has-fullscreen-dialog
   z-index: 3
   margin-top: 0 !important

.fbf-detail-view__fadeable--horizontal
  transition: height .3s, opacity .3s

  *
    transition: height .3s

  .fbf-detail-view__faded-out &
    height: 0
    opacity: 0

    *
      height: 0

.browser-ios .fbf-app-bar__sibling--has-fullscreen-dialog.fbf-detail-view__content
  margin-top: 0 !important
  z-index: 3

  .fbf-card
    z-index: auto

// In a broadcast, the app-bar is not fixed, so the iOS fix for fullscreen dialogs
// has no effect there. To fix that we need to make sure this app-bar is fixed,
// so until that is realised, this fixes the overlapping app-bar issue in iOS.
.browser-ios .fbf-app-bar.fbf-app-bar--with-tab-bar:not(.fbf-app-bar--fixed) ~ .fbf-app-bar__sibling--has-fullscreen-dialog.fbf-detail-view__container,
.browser-ios .fbf-app-bar.fbf-app-bar--with-tab-bar:not(.fbf-app-bar--fixed) ~ .fbf-app-bar__sibling--has-fullscreen-dialog.fbf-page__main
  margin-top: -128px !important

.fbf-detail-view__fadeable--vertical
  transition: width .3s,  opacity .3s

  *
    transition: width .3s

  .fbf-detail-view__faded-out &
    width: 0
    opacity: 0

    *
      width: 0

.fbf-detail-view
  main
    transition: padding 0.25s

.fbf-detail-view--sidebar-button.fbf-button--fab
  transition: right 0.25s ease-in-out
  z-index: $z-sidebar-button

.fbf-detail-view__faded-out
  .fbf-app-bar.fbf-app-bar--transparent, &.fbf-detail-view__faded-out--full main > .fbf-app-bar, &.fbf-detail-view__faded-out--full > .fbf-app-bar
    height: 0
    opacity: 0

    & + *
      margin-top: 0

  .fbf-detail-view--sidebar-button
    right: -70px
