.fbf-landing__support-panel
  .fbf-button--size-large
    margin: 24px
    align-self: center

  .fbf-list-item
    align-self: center

.fbf-landing__support-panel__meet
  @include fbf-layout--flow-down
  @include text--muted
  align-self: center
  margin-bottom: 16px
  &::before
    content: ''
    align-self: center
    width: 1px
    height: 6vh
    margin: 16px 0 16px 0
    background-color: transparentize(black, 0.8)
