@mixin text--preferred-font($usePreferred: true)
  @if $usePreferred
    font-family: $roboto-family

@mixin text--blockquote($colorContrast: false, $usePreferred: true)
  @include text--preferred-font($usePreferred)
  position: relative
  font-size: 24px
  font-weight: 300
  font-style: italic
  line-height: 1.35
  letter-spacing: 0.08em

  &:before
    position: absolute
    left: -0.5em
    content: '“'

  &:after
    content: '”'
    margin-left: -0.05em

@mixin text--menu($colorContrast: false, $usePreferred: true)
  @include text--preferred-font($usePreferred)
  font-size: 14px
  font-weight: 500
  line-height: 1
  letter-spacing: 0

@mixin text--button($colorContrast: false, $usePreferred: true)
  @include text--preferred-font($usePreferred)
  font-size: 14px
  font-weight: 500
  text-transform: uppercase
  line-height: 1
  letter-spacing: 0

@mixin text--icon()
  font-family: 'Material Icons'
  font-weight: normal
  font-style: normal
  font-size: 24px
  line-height: 1
  letter-spacing: normal
  text-transform: none
  display: inline-block
  word-wrap: normal
  font-feature-settings: 'liga'
  -webkit-font-feature-settings: 'liga'
  -webkit-font-smoothing: antialiased

@mixin text--link__base
  display: inline-flex
  cursor: pointer
  &:hover
    text-decoration: underline
  .fbf-icon, .fbf-svg
    color: inherit

@mixin text-dark--link
  @include text--link__base
  color: $color-accent !important

@mixin text-light--link
  @include text--link__base
  font-weight: 600
  color: white !important

// Shorthand for easy of use and backwards compatibility
@mixin text--link
  @include text-dark--link

@mixin text--colored($color)
  color: $color
  h1, h2, h3, h4, h5, h6, p, a, small, .fbf-icon, .fbf-svg
    color: inherit
