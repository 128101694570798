.fbf-story
  overflow: hidden

  &.is-editing
    display: flex
    flex-grow: 1

  .fbf-card--story &
    margin-bottom: 16px

.fbf-story:not(.is-editing)
  .fbf-profile-picture
    display: inline-block

  .fbf-profile-picture, .fbf-speech-indicator, .fbf-svg
    float: left
    margin-top: 4px
    margin-bottom: 16px

.fbf-story h5 + .fbf-rich-text
  margin-top: 8px

.fbf-story__error
  @include text--small
  @include text--red
  margin-top: 12px
  display: block
