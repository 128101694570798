@mixin start-creating-color-variant($color)
  background-color: $color

.fbf-landing__start-creating-panel
  @include start-creating-color-variant($color-primary)
  .fbf-landing__panel__column
    @include fbf-layout__center-column--medium-width

@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-landing__start-creating-panel
    @include start-creating-color-variant($color)
