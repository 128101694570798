.fbf-fragment-tag
  &__container
    @include fbf-layout--flow-right

  &__hint
    height: 24px

  [tabindex="0"]:hover &:hover,
  [tabindex="0"]:focus &
    @include fbf-chip--focused
