$fbf-layout-center-column-width: 840px

@mixin fbf-layout__center-column
  @include fbf-layout--flow-down
  @include fbf-layout__center-column--normal-width
  align-self: center
  width: 100%
  box-sizing: border-box
  transition: max-width 0.2s

@mixin fbf-layout__center-column--margin-vertical
  margin-top: 0vh
  margin-bottom: 8vh
  transition-property: max-width, margin-top
  transition-duration: 0.2s
  @media(min-width: $desktop-layout-min-width)
    margin-top: 8vh

@mixin fbf-layout__center-column--padding-vertical
  padding-top: 0vh
  padding-bottom: 8vh
  transition-property: max-width, padding-top, padding-bottom
  transition-duration: 0.2s
  @media(min-width: $desktop-layout-min-width)
    padding-top: 8vh

@mixin fbf-layout__center-column--small-width
  max-width: $tablet-min-width
@mixin fbf-layout__center-column--medium-width
  max-width: $notepad-width-in
@mixin fbf-layout__center-column--normal-width
  max-width: $desktop-min-width
@mixin fbf-layout__center-column--large-width
  max-width: $desktop-layout-min-width
@mixin fbf-layout__center-column--huge-width
  max-width: $desktop-large-min-width
@mixin fbf-layout__center-column--full-width
  max-width: 100%
