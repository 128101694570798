@mixin button--flat-grey
  color: $button-text-color
  background: transparent

  &:hover, &:focus, &.fbf-button--active
    background-color: transparentize(black, 0.94)

  &:focus:not(:active).fbf-button--support-focus
    background-color: transparentize(black, 0.88)

  &:active
    background-color: transparentize(black, 0.8)

@mixin button--flat-white
  color: white

  &:hover, &:focus, &.fbf-button--active
    background-color: transparentize(white, 0.8)

  &:focus:not(:active).fbf-button--support-focus
    background-color: transparentize(white, 0.88)

  &:active
    background-color: transparentize(white, 0.6)

  &.fbf-button[disabled], &.fbf-button.fbf-button--disabled
    color: transparentize(white, 0.74)
    background-color: transparent

@mixin button--outlined
  @include button--flat-grey
  border: solid currentcolor 1px

@mixin button--raised-base
  @include shadow-2dp()
  color: $button-text-color
  background-color: white

  // Specificity boosting to overcome `.context--dark .fbf-icon color: white`
  &.fbf-button
    .fbf-icon, .fbf-svg
      color: inherit

  &.fbf-button:hover, &.fbf-button--active, &.fbf-button:focus:not(:hover)
    background-color: $color-palette-grey-200

  &:focus:not(:active).fbf-button--support-focus
    @include shadow-4dp()
    background-color: $color-palette-grey-300

  &:active
    @include focus-shadow()
    background-color: $color-palette-grey-400

  &.fbf-button.fbf-button.fbf-button
    @include button--raised-disabled

  .browser-safari &, .browser-ios &
    .fbf-badge
      margin: 7px 0

// Button mixins
@mixin button--fab-base
  @include shadow-6dp
  justify-content: center
  border-radius: 50%
  font-size: $button-fab-font-size
  padding: 0
  overflow: hidden
  position: relative
  line-height: normal

  &.fbf-layout--absolute-centerright, &.fbf-layout--absolute-bottomright, &.fbf-layout--fixed-bottomright
    z-index: $z-floating-button

  &:focus, &:hover
    @include shadow-8dp
  &:active
    @include shadow-16dp

@mixin button--fab-size-regular
  height: $button-fab-size
  min-width: $button-fab-size
  width: $button-fab-size
  .browser-safari &
    // Safari alignment fix to enable layout centering (without display: flex, which Safari doesn't allow on buttons)
    padding: ($button-fab-size - $button-fab-font-size) / 2

@mixin button--fab-size-mini
  height: $button-fab-size-mini
  min-width: $button-fab-size-mini
  width: $button-fab-size-mini
  .browser-safari &
    // See Safari note above
    padding: ($button-fab-size-mini - $button-fab-font-size) / 2

@mixin button--fab-size-muted
  transition: min-width 0.2s $animation-curve-default, width 0.2s $animation-curve-default, height 0.2s $animation-curve-default, margin 0.2s $animation-curve-default, box-shadow 0.2s $animation-curve-fast-out-linear-in, padding 0.2s $animation-curve-default, background-color 0.2s $animation-curve-default, color 0.2s $animation-curve-default
  @include button--fab-size-mini
  margin: ($button-fab-size - $button-fab-size-mini) / 2

@mixin button--fab-size-unmuted
  @include button--fab-size-regular
  margin: 0

@mixin button--disabled
  &[disabled], &.fbf-button--disabled
    background-color: transparent
    color: transparentize(black, 0.74)
    box-shadow: none
    cursor: default

@mixin button--white-disabled
  &.fbf-button[disabled], &.fbf-button--disabled
    background-color: transparentize(white, 0.88) !important
    color: transparentize(white, 0.74) !important
    box-shadow: none !important

@mixin button--raised-disabled
  &[disabled], &.fbf-button--disabled
    background-color: transparentize(black, 0.88)

// Colors
@mixin button--color-variant($color)
  .fbf-icon, .fbf-svg
    color: inherit

  &.fbf-button--flat, &.fbf-button--outlined
    color: $color

  &.fbf-button--raised, &.fbf-button--fab, &.fbf-button--fab-mini, &.fbf-button--fab-text, &.fbf-button--fab-location
    color: white
    background-color: $color
    &:hover, &:focus
      background-color: lighten($color, 8%)

    &:active
      background-color: darken($color, 10%)

    &:focus:not(:active).fbf-button--support-focus
      background-color: lighten($color, 6%)

@mixin button--color-variant-context-dark($color)
  &.fbf-button--raised, &.fbf-button--fab, &.fbf-button--fab-mini, &.fbf-button--fab-text, &.fbf-button--fab-location
    color: $color
    background-color: white

    .fbf-icon, .fbf-svg, .fbf-button__text
      color: inherit

    &:hover, &:focus
      background-color: darken(white, 10%)

    &:active
      color: darken($color, 10%)
      background-color: darken(white, 20%)

    &:focus:not(:active).fbf-button--support-focus
      background-color: darken(white, 8%)

@mixin button--icon-color-variant($color)
  color: $color
  .fbf-icon, .fbf-svg, .fbf-button__text
    color: inherit

  &:hover, &.fbf-button--active
    background-color: transparentize($color, 0.90)

  &:focus:not(:active).fbf-button--support-focus
    background-color: transparentize($color, 0.84)

  &:active
    background-color: transparentize($color, 0.76)

@mixin button--selectable-color-variant($color)
  &.fbf-button--selectable.fbf-button--active
    background-color: $color

    &[disabled], &.fbf-button--disabled
      background-color: transparentize(darken($color, 10), 0.7)
