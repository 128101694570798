.fbf-comments-inline-typing-users
  @include text--muted-more
  margin-left: 72px
  transition: height 200ms, padding-bottom 200ms
  padding-bottom: 16px
  flex-shrink: 0

  &.fbf-comments-inline-typing-users--none-typing
    height: 0px
    padding-bottom: 0px
