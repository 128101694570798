@mixin list-item-margin-between
  &:last-child
    margin-bottom: 0

  &:not(:last-child)
    margin-bottom: 16px

    @media(max-width: $phone-max-width)
      margin-bottom: $fbf-layout-content-padding--phone
    @media(min-width: $desktop-layout-min-width)
      margin-bottom: 24px
    .fbf-sidebar &
      margin-bottom: $fbf-layout-content-padding--phone

.fbf-activity-list-item
  @include fbf-layout__card-list-item
  @include list-item-margin-between

  > .fbf-activity-icon
    @include fbf-layout__activity-icon
    margin-top: 12px

  > .fbf-profile-picture
    flex: none
    width: 40px
    height: 40px
    margin: 14px

  // Note to future itches: this hack currently can't be easily smoothed out due to the nature of
  // engines in the platform.
  > div
    flex: auto
    min-width: 1px

.fbf-activity-list-item__header
  @media(min-width: $desktop-min-width)
    padding-left: 8px

    .fbf-sidebar &
      padding-left: 0

.fbf-activity-list-item__header-broadcast
  @include fbf-layout--flow-right
  @include fbf-layout--flex-spread
  margin-left: -4px
  width: 100%

  .text--pulse
    @include fbf-layout--flow-right

.context--cyan .fbf-activity-list-item__header-broadcast
  .text--pulse, .fbf-icon
    color: $color-cyan

.fbf-activity-list-item__tasks-toggle.fbf-list-item
  & > .fbf-icon:first-child
    margin-left: 24px

.fbf-activity-list-item__list
  flex-grow: 1
  min-width: 1px

  .fbf-menu
    position: relative

.fbf-activity-list-item__preview
  display: flex
  position: relative
  min-width: 200px
  .media-preview
    flex-grow: 1

.fbf-activity-list-item__broadcast
  @include fbf-layout--flow-down
  padding: 8px 16px
  min-width: 200px
  box-sizing: border-box
  align-items: center
  justify-content: center
  &.context--cyan.context--dark
    background-color: $color-cyan
  &.context--red.context--dark
    background-color: $color-red
  .text--pulse, .text--muted
    @include fbf-layout--flow-right
  .text--pulse
    padding: 8px

.fbf-activity-list-item--updated:not(.fbf-activity-list-item--update-only)
  > .fbf-activity-icon
    margin-top: 84px

.fbf-activity-list-item__update
  display: flex
  flex-direction: row
  align-items: center
  border-bottom: 1px solid $color-palette-grey-200
  padding: 16px
  transition: padding 200ms
  @media(min-width: $desktop-layout-min-width + 1)
    padding-left: 24px
    padding-right: 24px

  .fbf-profile-picture
    margin-right: 8px

  .fbf-text
    flex-grow: 1

.fbf-activity-list-item--tiny
  > .fbf-activity-icon
    margin-top: 2px

.fbf-activity-list-item--tiny.fbf-activity-list-item--updated
  > .fbf-activity-icon
    margin-top: 74px

.fbf-activity-list-item--large
  > .fbf-activity-icon
    margin-top: 174px

  .fbf-card
    border-radius: 4px

.fbf-activity-list-item--large.fbf-activity-list-item--updated
  > .fbf-activity-icon
    margin-top: 246px

.fbf-activity-list-item--readonly
  pointer-events: none
