.fbf-landing__innovations-panel .fbf-landing__panel__column
  padding-left: 0
  padding-right: 0

  .fbf-media-stepper
    flex-direction: row-reverse
  @media(min-width: $desktop-min-width)
    .fbf-stepper__step__content
      min-height: 100px

  .fbf-button--size-large.fbf-button--raised
    margin-top: 5vh
    margin-bottom: 5vh
