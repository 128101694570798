.fbf-comments-new-inline
  display: flex
  align-items: center
  border: 0 solid $color-palette-grey-300
  border-top-width: 1px
  background-color: white
  overflow: hidden

  .fbf-list-item
    flex-grow: 1
    align-self: stretch

    .fbf-list-item__avatar
      align-self: flex-start
      margin: 16px 0 16px 16px
      &.fbf-text-icon
        font-size: 48px

    .fbf-list-item__lines
      transition-property: height
      transition-duration: 0.2s
      min-height: 48px
      .fbf-textarea-inline
        padding: 8px 0
        flex-grow: 0

  .fbf-comments-new-inline__toolbar
    margin: 16px 16px 16px 0
    align-self: center

  &.fbf-comments-new-inline--multiline
    flex-direction: column
    .fbf-textarea-inline
      padding: 0

    .fbf-comments-new-inline__toolbar
      margin: 0 16px 16px 16px
      align-self: flex-end

  &.fbf-comments-new-inline--rounded
    border: 1px solid $color-palette-grey-300
    border-radius: 32px
    margin: 8px 16px 8px 8px

    .fbf-list-item__avatar
      margin: 8px 0 8px 8px
    .fbf-list-item__lines
      margin-bottom: 4px
      margin-top: 4px
    .fbf-comments-new-inline__toolbar
      margin: 8px 16px 8px 0
    &.fbf-comments-new-inline--multiline .fbf-comments-new-inline__toolbar
      margin: 0 16px 8px 8px

.fbf-comments-new-inline--border-bottom
  border-top-width: 0
  border-bottom-width: 1px

.fbf-activity-card .fbf-comments-new-inline
  > .fbf-list-item > .fbf-list-item__avatar
    @media(min-width: $desktop-layout-min-width + 1)
      margin-left: 24px

// For dialog hosted flat discussion
.fbf-dialog .fbf-dialog__footer.fbf-comments-new-inline
  padding: 0
