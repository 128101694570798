@import 'fbf-toggle/variables'
@import 'fbf-toggle/mixins'

$switch-color-fade: 0.74
$switch-off-thumb-color: $color-palette-grey-50
$switch-off-track-color: transparentize(black, $switch-color-fade)
$switch-disabled-thumb-color: $color-palette-grey-400
$switch-disabled-track-color: transparentize(black, 0.9)
$switch-track-color: transparentize(black, 0.55)
$switch-thumb-color: $color-palette-grey-800

$switch-height: 24px !default
$switch-track-height: 14px !default
$switch-track-length: 36px !default
$switch-thumb-size: 20px !default
$switch-track-top: ($switch-height - $switch-track-height) / 2 !default
$switch-thumb-top: ($switch-height - $switch-thumb-size) / 2 !default
$switch-ripple-size: $switch-height * 2 !default
$switch-helper-size: 8px !default

.fbf-switch
  @include toggle--base

.fbf-switch__label
  @include toggle__label

.fbf-switch__focus-helper
  @include toggle__focus-helper
  width: $switch-thumb-size
  height: $switch-thumb-size
  top: 50%
  left: 50%
  transform: translate(-$switch-thumb-size/2, -$switch-thumb-size/2)

  .fbf-switch.is-focused &
    box-shadow: 0 0 0px (($toggle-focus-helper-size - $switch-thumb-size) / 2) transparentize(black, 0.9)

.fbf-switch__track
  background: $switch-off-track-color
  position: relative
  left: 0
  margin-top: ($switch-height - $switch-track-height) / 2
  margin-bottom: ($switch-height - $switch-track-height) / 2
  height: $switch-track-height
  width: $switch-track-length
  border-radius: $switch-track-height

  .fbf-switch.is-checked &
    background: $switch-track-color

  fieldset[disabled] .fbf-switch &,
  .fbf-switch.is-disabled &
    background: $switch-disabled-track-color
    cursor: auto

.fbf-switch__thumb
  background: $switch-off-thumb-color
  position: absolute
  left: 0
  top: $switch-thumb-top
  height: $switch-thumb-size
  width: $switch-thumb-size
  border-radius: 50%

  @include shadow-2dp()

  @include material-animation-default(0.28s)
  transition-property: left

  .fbf-switch.is-checked &
    background: $switch-thumb-color
    left: $switch-track-length - $switch-thumb-size

    @include shadow-3dp()

  fieldset[disabled] .fbf-switch &,
  .fbf-switch.is-disabled &
    background: $switch-disabled-thumb-color
    cursor: auto

@mixin switch-color-variant($color)
  &.is-enabled.is-checked
    .fbf-switch__track
      background-color: transparentize($color, $switch-color-fade)
    .fbf-switch__thumb
      background-color: $color

    &.is-focused .fbf-switch__focus-helper
      box-shadow: 0 0 0px (($switch-ripple-size - $switch-helper-size) / 2) transparentize($color, $switch-color-fade)

@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-switch, .fbf-switch.color-#{$color-name}
    @include switch-color-variant($color)

.context--dark .fbf-switch
  &.is-enabled .fbf-switch__track
    background: transparentize(white, $switch-color-fade)
  &.is-enabled .fbf-switch__thumb
    background: $color-palette-grey-500

  &.is-disabled.is-disabled.is-disabled .fbf-switch__thumb
    background: $color-palette-grey-600

  &.is-focused .fbf-switch__focus-helper
    box-shadow: 0 0 0px (($switch-ripple-size - $switch-helper-size) / 2) transparentize(white, 0.9)

  &.is-checked
    .fbf-switch__track.fbf-switch__track
      background-color: transparentize(white, 0.55)
    .fbf-switch__thumb
      background-color: white

    &.is-focused .fbf-switch__focus-helper
      box-shadow: 0 0 0px (($switch-ripple-size - $switch-helper-size) / 2) transparentize(white, $switch-color-fade)


@mixin switch-dark-color-variant($color)
  .fbf-switch__thumb
    background-color: lighten($color, 30)
  &.is-checked .fbf-switch__thumb
    background-color: white

@each $color-name, $color in $colors
  .context--#{$color-name} .context--dark .fbf-switch, .context--dark .fbf-switch.color-#{$color-name}.color-#{$color-name}
    @include switch-dark-color-variant($color)
