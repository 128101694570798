.fbf-landing__customers-panel
  .fbf-landing__customers-panel__section
    opacity: 0.5
    @media(max-width: $notepad-width-in)
      @include fbf-layout--flow-down
      align-items: center
      height: 78vw
      img
        padding: 16px 10vw
        width: 100%
        box-sizing: border-box
    @media(min-width: $notepad-width-out)
      @include fbf-layout--flow-right
      margin-bottom: 16px
      img + img
        margin-left: 8%
