@mixin fbf-card--base
  @include shadow-2dp
  background-color: white

@mixin fbf-card
  @include fbf-card--base
  position: relative
  margin-bottom: 8px
  transition-property: margin-bottom, padding, box-shadow
  transition-duration: 0.2s
  @media(min-width: $desktop-layout-min-width + 1)
    margin-bottom: 16px

.fbf-card--base
  @include fbf-card--base

.fbf-card
  @include fbf-card

.fbf-card--no-margin-bottom
  margin-bottom: 0

.fbf-card--padding-top
  padding-top: 8px

.fbf-card--padded
  padding: 16px

.fbf-card--grey-bg
  background-color: $bg-grey

.fbf-card--grey-bg-light
  background-color: $bg-grey--100

// recommended to use in conjunction with .fbf-rich-text
.fbf-card--story
  padding: 8px 24px
  margin-bottom: 24px

  @media(min-width: $tablet-min-width)
    border-radius: 4px
    padding: 24px 40px
    margin-bottom: 40px

  @media(min-width: $desktop-layout-min-width)
    padding: 40px 64px

// Remove any horizontal padding (left and right)
.fbf-card--no-padding-h
  padding-left: 0
  padding-right: 0

// For temporarily disabling of shadows for e.g. nested cards (to prevent shadow stacking)
.fbf-card--no-shadow
  box-shadow: none

.fbf-card.fbf-card--form
  margin-bottom: 0

  & + &
    margin-top: 16px

  .fbf-card__section
    @include fbf-layout--flow-down
    padding-left: 32px
    padding-right: 32px
    @media(max-width: $notepad-width-out)
      padding-left: 16px
      padding-right: 16px

    &:first-child
      padding-top: 16px
      @media(max-width: $notepad-width-out)
        padding-top: 8px

  .fbf-checkbox
    margin-left: 8px
    margin-right: 8px
  .fbf-options-field
    margin-right: 8px

  @media(max-width: $notepad-width-out)
    .fbf-checkbox
      margin-left: 0

.fbf-card-container__glance
  flex: 0 0 33.333%
  position: relative

.fbf-rating-card
  padding: 42px

  .fbf-rating-card__grid
    @include fbf-grid(2, 24)
    margin-top: 24px

    > *
      margin-top: 12px

@mixin card-actionable
  cursor: pointer
  transition: box-shadow 0.2s

  &:hover
    background-color: $bg-grey--50
    @include shadow-6dp()

  &:active
    background-color: $bg-grey--300

.fbf-card--actionable
  @include card-actionable

.fbf-card--selected
  border: 6px solid
  border-radius: 6px
  @include shadow-16dp()

  @media(max-width: $desktop-max-width)
    @include shadow-8dp()
  @include mq-in-phone-or-sidebar
    @include shadow-4dp()
  &.fbf-card--compact
    @include shadow-4dp()

    .fbf-card--selected__header
      padding: 6px
      padding-top: 0

  .fbf-card--selected__header
    display: flex
    flex-direction: row
    justify-content: center
    color: white
    padding: 12px
    @media(max-width: $desktop-max-width)
      padding: 8px
    @include mq-in-phone-or-sidebar
      padding: 6px
      padding-top: 0

.fbf-card--placeholder.fbf-card--selected
  background-color: transparent
  box-shadow: none !important

  .fbf-card--selected__header
    @include text--muted

@mixin card-selected-color-variant($color)
  border-color: $color
  .fbf-card--selected__header
    background-color: $color

  &.fbf-card--placeholder
    border-color: $bg-grey--300
    .fbf-card--selected__header
      background-color: $bg-grey--300

@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-card--selected
    @include card-selected-color-variant($color)
  .fbf-card--selected.fbf-card--color-#{$color-name}.fbf-card--color-#{$color-name}
    @include card-selected-color-variant($color)

// DEPRECATED: use .content-padding-md from utilities.scss instead
.fbf-card__content
  padding: 16px 24px

.context--beamer
  .fbf-card__content
    padding: 32px 40px

    > *:not(:last-child)
      margin-bottom: 24px

  .fbf-card--collapsed
    .fbf-card__content
      padding: 16px 24px

      > *:not(:last-child)
        margin-bottom: 16px

// DEPRECATED
.fbf-card--standalone
  margin: 16px
  @extend %fbf-grid__max-8

.fbf-card-container
  display: flex

.fbf-card--dimmed
  background-color: $bg-grey

// DEPRECATED: Use `fbf-card__content` instead
.fbf-card--padded.fbf-rich-text
  padding: 16px 32px
