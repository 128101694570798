.fbf-media-action-button
  border: 0
  display: flex
  color: white
  background-color: transparentize(black, 0.5)
  text-align: center
  @include fbf-layout--flow-right
  transition-property: transform
  transition-duration: 0.2s
  @include material-animation-default()
  cursor: pointer
  // One size fits all
  border-radius: 6em

  .fbf-icon
    margin-right: 0
    color: inherit

  h1, h2, h3, h4, h5, h6, p, small
    margin-left: 8px
    margin-right: 8px

  &:hover
    transform: scale(1.1)
    animation: none
    outline: none

.fbf-media-action-button--muted
  transition-property: transform, opacity
  opacity: 0
  transform: scale(0)

.fbf-media-action-button--pulsing
  animation-iteration-count: infinite
  animation-direction: alternate
  animation-duration: 0.6s
  animation-name: pulse--size

  @keyframes pulse--size
    from
      transform: scale(1)
    to
      transform: scale(1.05)

.fbf-media-action-button__container
  &:hover .fbf-media-action-button--muted
    opacity: 1
    transform: scale(1)

.fbf-media-action-button--small
  padding: 1em

.fbf-media-action-button--medium
  font-size: 35px
  padding: 0.4em
  .fbf-icon
    font-size: 56px

.fbf-media-action-button--large
  font-size: 35px
  padding: 1em
  .fbf-icon
    font-size: 56px

.fbf-media-action-button + .fbf-media-action-button
  margin-left: 8px
