$fbf-task-padding-vertical: 24px
$fbf-task-padding-horizontal: 32px
$fbf-task-mobile-padding-vertical: 16px
$fbf-task-mobile-padding-horizontal: 16px
$fbf-task--step__before-margin-top: 62px

@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-task
    > .fbf-activity-icon, .fbf-task__header > .fbf-activity-icon
      .fill
        fill: $color

.fbf-task
  display: flex
  flex-shrink: 0
  position: relative

  & ~ &
    @include fbf-layout--content-margin-top
    .fbf-sidebar &
      @include fbf-layout--content-margin-top(false)

  &.fbf-task--step
    @media(min-width: $desktop-min-width)
      &::before
        background-color: transparentize(black, 0.9)
        width: 2px
        height: calc(100% + #{$fbf-layout-content-padding--desktop} + 6px - #{$fbf-task--step__before-margin-top})
        content: " "
        margin-top: $fbf-task--step__before-margin-top
        margin-left: 33px
        position: absolute

        @media(min-width: $desktop-layout-min-width)
          height: calc(100% + #{$fbf-layout-content-padding--desktop-layout} + 6px - #{$fbf-task--step__before-margin-top})

      &:last-of-type::before
        visibility: hidden

  > .fbf-activity-icon
    margin: 8px
    flex-shrink: 0
    width: 52px
    height: 52px

  // When your task contains multiple cards
  .fbf-task__content-list
    flex: auto
    width: 100%

    > .fbf-card, .fbf-task__content
      margin-bottom: 16px
      width: 100%

  // Content in your tasks, wrap them in this container for automagic styling
  > .fbf-card, .fbf-task__content
    box-sizing: border-box
    flex: auto
    width: calc(100% - 68px)
    margin-bottom: 0

    & > hr.margin
      margin-top: $fbf-task-padding-vertical
      margin-bottom: $fbf-task-padding-vertical
      @media(max-width: $tablet-max-width)
        margin-top: $fbf-task-mobile-padding-vertical
        margin-bottom: $fbf-task-mobile-padding-vertical

    & > .fbf-task__header + hr.margin
      margin-top: 8px
      @media(max-width: $tablet-max-width)
        margin-top: 0

    & > .fbf-list > .fbf-list-item:not(.fbf-list-item--padding)
      padding-left: 0
      padding-right: 0
      & > .fbf-activity-icon + .fbf-list-item__lines
        margin-left: 10px

    & > .fbf-metrics--2-col
      padding: 0 56px

  & > .fbf-card.fbf-card--padded, .fbf-task__content.fbf-card--padded
    padding: $fbf-task-padding-vertical $fbf-task-padding-horizontal
    @media(max-width: $tablet-max-width)
      padding: $fbf-task-mobile-padding-vertical $fbf-task-mobile-padding-horizontal

    & > hr.margin, & > .fbf-list, & > .fbf-data-table, & > .fbf-task__cover, & > .media-preview
      margin-left: -$fbf-task-padding-horizontal
      margin-right: -$fbf-task-padding-horizontal
      @media(max-width: $tablet-max-width)
        margin-left: -$fbf-task-mobile-padding-horizontal
        margin-right: -$fbf-task-mobile-padding-horizontal

    & > .fbf-list.fbf-option-list--no-margin, & > .fbf-list.fbf-list--no-margin
      margin: 0

    & > .fbf-task__cover, & > .media-preview
      &.fbf-task__cover--full
        margin-top: -$fbf-task-padding-vertical
        margin-bottom: -$fbf-task-padding-vertical

      &.fbf-task__cover--header
        margin-top: -$fbf-task-padding-vertical

    .fbf-task__header
      padding: 0

  &.fbf-task--standalone
    > .fbf-card, .fbf-task__content
      margin-left: 68px
      width: calc(100% - 68px)
    @media(max-width: $tablet-max-width)
      > .fbf-card, .fbf-task__content
        margin-left: 0
        > .fbf-task__header
          padding-left: 48px

  .fbf-task__cover, & > .media-preview, & > .fbf-card > .media-preview, .fbf-task__content > .media-preview
    flex-grow: 1
    background-color: $color-palette-grey-800

    // Disabling default styles (which might become deprecated in the future)
    width: auto
    height: 400px
    @media(max-width: $tablet-max-width)
      max-height: 30vh

    &.fbf-task__cover--small
      height: 240px

    &.fbf-task__cover--header
      align-items: flex-end

      h4, .text--h4
        background-image: $scrim-down-50
        padding: 30px $fbf-task-padding-horizontal 16px $fbf-task-padding-horizontal
        flex-grow: 1

  .fbf-task__header
    @include fbf-layout--flow-right
    align-items: flex-start
    padding-left: $fbf-task-padding-horizontal
    padding-right: $fbf-task-padding-horizontal
    padding-top: $fbf-task-padding-vertical
    @media(max-width: $tablet-max-width)
      padding-left: $fbf-task-mobile-padding-horizontal
      padding-right: $fbf-task-mobile-padding-horizontal
      padding-top: $fbf-task-mobile-padding-vertical

    > .fbf-activity-icon
      margin: 0 12px 0 -4px
      height: 32px
      width: 32px

    @media(max-width: $tablet-max-width)
      + .fbf-list
        padding-top: 0

    .text__subheader
      @include fbf-layout--flow-right
      justify-content: flex-start

      // On the left no margin should be needed, but the smaller text needs a little
      // margin to align better with the header
      margin: 12px 16px 12px 1px

  .fbf-task__header-content
    @include fbf-layout--flow-down
    width: 100%

  header
    display: flex
    margin-bottom: 16px

    h4, .text--h4
      margin: 0

    & > h4, & > .text--h4, & > div
      margin-right: 16px
      flex-grow: 1

    span
      display: flex
      align-items: center

      h4, .text--h4
        margin-left: 8px

    .deadline--details
      @include fbf-layout--flow-down
      align-items: flex-end

    .deadline * // specificity
      color: $color-red

    .deadline--passed * // specificity
      color: $color-grey-light
      flex-shrink: 0

    .deadline--passed
      flex-shrink: 0

    .fbf-mini-progress-bar
      width: 240px

    &.fbf-task__header--responsive
      @include mq-in-notepad
        @include fbf-layout--flow-down

  > .fbf-list-item
    min-height: 72px // Purely cosmetic: gives a lot more vertical harmonics

  &.fbf-task--media
    & > div
      @include fbf-layout--flow-right
      @include fbf-layout--flex-spread

      @media(max-width: $tablet-max-width)
        flex-direction: column
        margin-bottom: 8px

      & > iframe
        background-image: $gradient-media-placeholder

.fbf-task--indented-content
  margin-left: 68px

.fbf-task--primary
  > .fbf-card, .fbf-task__content
    padding: 0

    > div:not(.fbf-task__header) > header
      padding: 24px 32px 0 32px
      margin-bottom: 24px

  > .fbf-task__header
    padding: 16px 16px 0 16px

  .fbf-task__padded-content
    padding: 0 32px 24px 32px

.fbf-task--secondary
  margin-bottom: 0

  header
    padding: 16px 16px
    margin-bottom: 0
    background: $bg-grey

    h5
      margin: 8px 0

  .fbf-list-item .fbf-list-item__lines
    .fbf-list-item__primary, .fbf-list-item__secondary
      justify-content: space-between
      align-items: center
      width: 100%

    small strong
      color: $color-purple

  .fbf-list-item__primary, .fbf-list-item__secondary
    > *:first-child
      margin-right: 8px

  .fbf-list-item__primary
    margin-bottom: 8px

  .mdl-slider__container
    width: 100%

.fbf-task--new
  position: relative
  display: flex
  flex-direction: row
  margin-top: 24px
  margin-bottom: 16vh

  @media(max-width: $tablet-max-width)
    flex-direction: column

  & > .fbf-button--fab
    margin: 0 6px
    position: absolute
    top: 0
    left: 0
    transition-property: transform, opacity
    transition-duration: 0.2s

.fbf-task--new-active
  flex-shrink: 0

  & > .fbf-button--fab
    transform: scale(0)
    opacity: 0

  .fbf-task-store
    transform: scale(1)
    opacity: 1
    transition-delay: 0s, 0.1s
    .fbf-app-bar
      opacity: 1

  .fbf-task-store__contents
    opacity: 1

.fbf-task__subhead
  display: flex
  align-items: center
  margin-bottom: 16px
  .fbf-icon
    margin-right: 8px

.fbf-task__zero-data
  display: flex
  justify-content: center
  align-items: center
  min-height: 80px
  @media(min-width: $desktop-layout-min-width)
    min-height: 120px
  margin-bottom: 24px
  @media(max-width: $tablet-max-width)
    margin-bottom: 8px
  flex-direction: column
  text-align: center

.fbf-task__users-row
  display: flex
  align-items: center
  margin-bottom: 24px
  margin-top: 4px

  .fbf-profile-picture:nth-child(1)
    opacity: 1
  .fbf-profile-picture:nth-child(2)
    opacity: 0.9
  .fbf-profile-picture:nth-child(3)
    opacity: 0.8
  .fbf-profile-picture:nth-child(4)
    opacity: 0.7
  .fbf-profile-picture:nth-child(5)
    opacity: 0.6
  .fbf-profile-picture:nth-child(6)
    opacity: 0.5
  .fbf-profile-picture:nth-child(7)
    opacity: 0.4
  .fbf-profile-picture:nth-child(8)
    opacity: 0.3
  .fbf-profile-picture:nth-child(9)
    opacity: 0.2
  .fbf-profile-picture:nth-child(10)
    opacity: 0.1

  .fbf-profile-picture
    margin-right: 10px
    transition: opacity 0.2s
    &:hover
      opacity: 1

  .fbf-profile-picture + a
    margin-left: 8px

.fbf-task__details-btn
  margin-top: 8px
  margin-bottom: 24px

.fbf-task__settings-toggle
  display: flex
  align-self: stretch
  margin-left: -32px
  margin-right: -32px
  margin-bottom: -24px
  background-color: $color-palette-grey-100
  border-top: 1px solid $color-palette-grey-300
  justify-content: center
  padding: 8px 0

.fbf-task-path__container
  @include fbf-layout__center-column
  position: relative

  .fbf-tap-target
    top: -232px
    left: -214px

.fbf-task-path__error
  margin-top: 30px
  margin-bottom: 30px
  margin-left: 68px

  @media(min-width: $desktop-layout-min-width)
    margin-left: 84px

.fbf-sidebar, .context--embedded
  .fbf-task-path__container
    margin-top: 0

.fbf-task-path__step
  width: 52px
  height: 52px
  margin: 2px 8px
  flex-shrink: 0
  .outer
    fill: $bg-grey
  .inner
    fill: $color-grey-light

.fbf-task-store
  background-color: $bg-grey--100
  flex-grow: 1
  transform: scale(0)
  opacity: 0
  transform-origin: 50% 0
  transition-property: transform, opacity
  transition-duration: 0.3s, 0.1s
  transition-delay: 0s, 0s
  transition-timing-function: ease-in, ease-in

  .fbf-app-bar
    opacity: 0
    transition-property: opacity
    transition-delay: 0.2s
    transition-duration: 0.2s

  .fbf-app-bar
    border-bottom: 1px solid $bg-grey--300

  #ember-testing &
    transition: none

.fbf-task-store__contents
  padding-top: 16px
  padding-right: 16px
  display: flex
  flex-wrap: wrap
  overflow: hidden
  transition-property: height, opacity
  transition-delay: 0.2s
  transition-duration: 0.3s
  // start values
  min-height: 220px
  opacity: 0

  .fbf-card, .fbf-task-store--placeholder
    border-radius: 2px
    width: calc(50% - 16px)
    margin-left: 16px
    margin-bottom: 16px
    display: flex
    flex-direction: column
    box-sizing: border-box

  .fbf-task-store--placeholder
    height: 224px
    border: 1px solid $bg-grey--300
    justify-content: center
    align-items: center

.fbf-task-store__media
  display: flex
  justify-content: center
  align-items: center
  min-height: 150px

  .fbf-icon
    color: #fff
    font-size: 72px

.fbf-task
  @media(max-width: $tablet-max-width)
    ul.fbf-metrics > li.fbf-metric
      padding: 16px
      width: auto


@each $color-name, $gradient in $colored-gradients
  .context--#{$color-name} .fbf-task-store__media
    background: $gradient
