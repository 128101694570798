.fbf-stepper
  display: flex
  flex-shrink: 0

.fbf-stepper.fbf-stepper--horizontal
  flex-direction: row
  overflow-x: auto

.fbf-stepper.fbf-stepper--vertical
  flex-direction: column
  overflow-y: auto

.fbf-stepper.fbf-stepper--mobile-dots
  flex-direction: row
  justify-content: space-between
  align-items: center
  .fbf-stepper__dots
    @include fbf-layout--flow-right
  .fbf-button:first-child
    margin-left: 8px
    margin-right: 16px
  .fbf-button:last-child
    margin-right: 8px
    margin-left: 16px

// DEPRECATED: for backwards compatbility
.fbf-stepper
  & > a
    padding: 24px

  > a
    display: flex
    flex: 0 0 auto
    align-items: center

    &:not(:last-child)
      flex-grow: 1

      &::after
        content: ""
        display: block
        margin-right: 12px
        border-bottom: 1px solid #ccc
        flex: auto

    .fbf-activity-icon-plain, .fbf-icon
      flex-shrink: 0
      margin-right: 8px

    .fbf-activity-icon-plain
      width: 32px
      height: 32px
      fill: $color-blue-dark

    p
      margin-right: 16px
      flex-shrink: 0

  > a .fbf-icon
    background-color: $color-blue-dark
    color: white
