.fbf-layout--absolute-bottomright
  @include fbf-layout--absolute-bottomright
.fbf-layout--absolute-center
  @include fbf-layout--absolute-center
.fbf-layout--absolute-centerright
  @include fbf-layout--absolute-centerright
.fbf-layout--fixed-bottomright
  @include fbf-layout--absolute-bottomright
  position: fixed

.fbf-layout--stretch
  @include fbf-layout--stretch
