// Roboto font
@mixin roboto-font($type, $weight)
  @font-face
    font-family: 'Roboto'
    src: url('../font/roboto/Roboto-#{$type}.woff2') format('woff2'), url('../font/roboto/Roboto-#{$type}.woff') format('woff')
    font-weight: $weight
    font-style: normal

@include roboto-font('Thin', 100)
@include roboto-font('Light', 300)
@include roboto-font('Regular', 400)
@include roboto-font('Medium', 500)
@include roboto-font('Bold', 700)

// Material Icon Font
@font-face
  font-family: 'Material Icons'
  font-style: normal
  font-weight: 400
  src: url(../font/material-design-icons/MaterialIcons-Regular.woff2) format('woff2'), url(../font/material-design-icons/MaterialIcons-Regular.woff) format('woff')

.material-icons
  font-family: 'Material Icons'
  font-weight: normal
  font-style: normal
  font-size: 24px
  display: inline-block
  width: 1em
  height: 1em
  line-height: 1
  text-transform: none
  letter-spacing: normal
  word-wrap: normal

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale

  /* Support for IE. */
  font-feature-settings: 'liga'

// Logo font stack
/* FeedbackFruits logo is officially in Helvetica Neue, but mostly only iOS/Mac devices ship it */
$logo-fontstack: "Helvetica Neue",Helvetica,sans-serif
$monospace-fontstack: Consolas,Monaco,'Andale Mono',monospace
