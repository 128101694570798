$insight-icon-size-lg: 64px
$insight-icon-size-sm: 40px
$insight-icon-size-xs: 32px

$insight-header-height-sm: 48px
$insight-header-height-lg: 72px
$insight-chart-width-lg: 640px

$insight-border-radius: 4px

@mixin fbf-insight-card-transition
  transition: all .25s ease-in-out

.fbf-insight-card__assistant
  @include fbf-insight-card-transition
  position: relative
  background-color: $color-primary-dark
  border-radius: $insight-border-radius

  .fbf-stepper__step--mobile-dots
    margin: 6px 4px

  .fbf-stepper__step--xs
    margin: 6px 2px
    height: 6px
    width: 6px

.fbf-insight-card__assistant--animate-in
  animation-name: animateAssistantIn
  animation-duration: .25s
  animation-timing-function: ease-out

  @keyframes animateAssistantIn
    from
      margin-top: -30px
      height: 0px
    99%
      height: $insight-header-height-lg
    to
      margin-top: 0px
      height: auto

.fbf-insight-card__header
  @include fbf-insight-card-transition
  border-radius: $insight-border-radius $insight-border-radius 0 0
  flex: 1 100%

.fbf-insight-card__header__left
  @include fbf-insight-card-transition
  flex-grow: 1
  padding: 16px
  height: 16px
  display: flex
  align-items: center

  h6
    @include fbf-insight-card-transition
    max-width: 100%
    max-height: 16px
    line-height: 16px
    font-weight: 300
    opacity: 1

.fbf-insight-card__header__right
  @include fbf-insight-card-transition
  flex-grow: 0
  position: relative
  z-index: 1

  .fbf-button
    @include fbf-insight-card-transition
    margin-right: 8px

.fbf-insight-card__icon
  @include fbf-insight-card-transition
  @include shadow-2dp
  border-radius: $insight-icon-size-lg
  align-self: flex-start
  position: relative
  z-index: 1
  margin-right: 16px

  svg
    @include fbf-insight-card-transition
    width: $insight-icon-size-lg
    height: $insight-icon-size-lg

.fbf-insight-card
  @include fbf-insight-card-transition
  display: flex
  padding: 16px 32px
  margin-left: 0
  margin-top: 0
  min-height: 40px
  flex: 2
  flex-direction: column
  justify-content: center

  &:after
    @include fbf-insight-card-transition
    right: 100%
    top: 50%
    border: solid transparent
    content: " "
    height: 0
    width: 0
    position: absolute
    pointer-events: none
    border-color: rgba(136, 183, 213, 0)
    border-right-color: #fff
    border-width: 0px
    margin-top: -8px

.fbf-insight-card--pinned
    animation-name: animateInsightIn
    animation-duration: .25s
    animation-timing-function: ease-out

    @keyframes animateInsightIn
      from
        max-height: 0px
      to
        max-height: 400px

.fbf-insight-card--pinned:last-of-type
  margin-bottom: 8px

.fbf-insight-card:first-of-type
  border-top-left-radius: $insight-border-radius
  border-top-right-radius: $insight-border-radius

.fbf-insight-card:last-of-type
  border-bottom-left-radius: $insight-border-radius
  border-bottom-right-radius: $insight-border-radius

.fbf-insight-card:last-child:not(.fbf-insight-card--pinned)
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0)

.fbf-task.fbf-task--standalone > .fbf-insight-card
  padding: 24px 32px
  width: initial

.fbf-insight-card__assistant .fbf-insight-card
  border-radius: $insight-border-radius

.fbf-insight-card__story
  @include fbf-insight-card-transition

.fbf-insight-card__title
  @include fbf-insight-card-transition
  margin-bottom: 16px
  max-height: 72px
  text-overflow: ellipsis
  height: 5em

.fbf-insight-card__assistant .fbf-insight-card__title
  margin-left: calc(#{$insight-icon-size-lg})

.fbf-insight-card__explanation
  @include text--muted-more
  @include fbf-insight-card-transition
  max-height: 400px
  width: $insight-chart-width-lg
  opacity: 1

.fbf-insight-card__actions
  @include fbf-insight-card-transition
  height: 36px

.fbf-insight-card__feedback
  @include fbf-layout--flow-right
  justify-content: flex-end
  flex-grow: 1

  .text--green
    @include fbf-layout--flow-right
    margin-right: 16px

.fbf-insight-card__footer
  @include fbf-insight-card-transition
  height: 64px
  margin-top: -4px
  padding: calc(8px + #{$insight-border-radius}) 16px 8px 32px
  border-bottom-left-radius: 4px
  border-bottom-right-radius: 4px
  background-color: $bg-grey--100

.fbf-insight-card__stepper--top
  @include fbf-insight-card-transition
  position: relative
  opacity: 0
  margin: 0px
  height: 0px
  white-space: nowrap

  .fbf-stepper
    margin-left: 16px

.fbf-insight-card__stepper--bottom
  text-align: center

.fbf-insight-card__assistant--collapsed
  .fbf-insight-card__header
    border-radius: 4px

  .fbf-insight-card__header__left
    height: 40px


  .fbf-insight-card
    margin-left: calc(16px + 140px + 16px)
    margin-top: -72px
    margin-bottom: 0
    padding-left: 16px
    min-width: 96px
    flex-basis: 96px
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0)
    border-radius: 0 $insight-border-radius $insight-border-radius 0

    &:after
      border-width: 8px

  .fbf-insight-card__story
    margin-left: 0px
    margin-right: 40px
    overflow: hidden

  .fbf-insight-card__title
    margin-left: 0
    margin-bottom: 0px
    max-height: 24px
    white-space: nowrap
    overflow: hidden

  .fbf-insight-card__stepper--top
    height: 16px
    opacity: 1
    margin-right: 40px

  .fbf-insight-card__icon
    margin-top: 0

    svg
      width: $insight-icon-size-sm
      height: $insight-icon-size-sm

  .fbf-insight-card__explanation
    margin-bottom: 0px
    max-height: 0
    opacity: 0
    overflow: hidden

  .fbf-insight-card__actions
    height: 0px
    min-height: 0px
    overflow: hidden

  .fbf-insight-card__footer
    height: 0px
    padding: 0px
    margin-top: 0px
    overflow: hidden

@media(max-width: $tablet-max-width)
  .fbf-insight-card__explanation
    width: inherit

@media(max-width: $phone-max-width)
  .fbf-insight-card__assistant:not(.fbf-insight-card__assistant--collapsed)
    .fbf-insight-card__icon
      margin-top: -8px
      margin-left: -8px
      margin-right: 8px

      svg
        width: $insight-icon-size-xs
        height: $insight-icon-size-xs

  .fbf-insight-card__assistant .fbf-insight-card__title
    margin-left: 0

  .fbf-insight-card__assistant, .fbf-insight-card__footer
    border-radius: 0px

  .fbf-insight-card__stepper--top .fbf-stepper
      margin-left: 0

  .fbf-task.fbf-task--standalone > .fbf-insight-card, .fbf-insight-card
    padding: 16px

  .fbf-insight-card__assistant--collapsed
    .fbf-insight-card__header__left
      h6
        opacity: 0

    .fbf-insight-card
      margin-left: calc(16px + #{$insight-icon-size-sm} + 16px + 8px)
      border-radius: 0px

  .fbf-insight-card:first-of-type
    border-top-left-radius: 0
    border-top-right-radius: 0

  .fbf-insight-card:last-of-type
    border-bottom-left-radius: 0
    border-bottom-right-radius: 0
