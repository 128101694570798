.fbf-page__main
  .fragment-view--question
    @include fbf-layout--flow-down
    @include shadow-8dp
    background-color: $color-palette-grey-100
    @media(min-width: $desktop-min-width)
      min-width: $desktop-min-width - 32px
    @media(min-width: $notepad-width-out) and (max-width: $tablet-max-width)
      min-width: $notepad-width-out - 32px

.fragment-view--question__contents
  display: flex
  flex-direction: column
  flex-grow: 1
  flex-shrink: 1
  // Needed for the overflow indicator layout positioning
  position: relative
  overflow: hidden

  & > .fbf-layout--scroll-overflow
    flex-grow: 1

.fragment-view--question__contents--compact
  .fbf-question__header
    z-index: auto
    .markdown
      align-self: stretch
      overflow-x: auto

.fragment-view--question__controls-container
  @include fbf-layout--flow-right
  border-top: 1px solid $bg-grey--300
  background-color: $bg-grey
  justify-content: space-between
  flex-shrink: 0
  .fbf-stepper
    justify-content: center
    flex-grow: 1
    width: auto
    margin: 0 auto

.fragment-view--question__answer
  @include content-padding-md
  @include fbf-layout__center-column
  @include fbf-layout__center-column--large-width
  flex-grow: 1
  margin-bottom: 0
  @media(max-width: $notepad-width-in)
    padding-left: 16px
    padding-right: 16px
  .share-url
    margin-bottom: -16px

.fragment-view--open-question__answer
  @media(max-width: $notepad-width-in)
    padding-left: 8px
    padding-right: 8px

  .fbf-text-field
    margin-left: 16px
    margin-right: 16px

.fragment-view--open-question__answers .fbf-layout__placeholder-hero .fbf-svg
  width: 120px
  height: 57px
  @media(max-height: $tablet-max-height)
    width: 60px
    height: 32px

.fragment-view--question__answering-status
  flex-grow: 0
  align-self: center
  padding-left: 16px
  padding-right: 16px
  border-radius: 48px
  min-height: 66px

  .fbf-list-item__lines p
    line-height: 20px
    margin-top: 4px

  .context--beamer &.fbf-list-item
    margin-bottom: 0px
    .fbf-list-item__lines
      margin: 16px

.fragment-view--question__tap-to-open
  background: $scrim-white-down
  z-index: 1
  & > span
    @include fbf-layout--flow-down
    @media(max-height: $tablet-max-height)
      margin-top: calc(20vh - 80px)
      margin-bottom: calc(20vh - 80px)
    @media(max-width: $tablet-max-width)
      margin-top: calc(20vw - 80px)
      margin-bottom: calc(20vw - 80px)
    .fbf-icon
      background-color: white
      padding: 32px
      @media(max-width: $phone-max-width)
        padding: 24px
      margin-bottom: 16px
    .fbf-chip
      text-align: center

.fragment-view--question__scroll-indicator
  background: $scrim-white-down
  position: absolute
  transition: bottom 0.2s $animation-curve-default
  bottom: -78px
  left: 0
  display: flex
  width: 100%
  justify-content: center
  padding: 16px
  box-sizing: border-box
  pointer-events: none
  &.is-active
    bottom: 0px

  .fbf-media-action-button
    pointer-events: all

.fragment-view--question .fbf-dialog
  .fbf-dialog__content
    padding-bottom: 48px

.fragment-view--broadcasting
  .fragment-view--question__scroll-indicator
    bottom: 0
    &.is-active
      bottom: 80px

.fragment-view--open-question
  .fbf-comments-list.fbf-card, .fbf-comments-inline-list-item.fbf-card
    border-radius: 4px
