.fbf-reuse-dialog
  &__options, &__collaboration
    .fbf-list-item
      padding: 0 16px

      &__lines
        margin-left: 32px
      &__media
        margin: 8px 8px 8px 0
        position: relative
        transition: width 200ms, height 200ms

        img
          position: absolute
          height: 100%
          width: 100%
          object-fit: cover
          object-position: top

    .fbf-divider-text
      margin: 16px 0
      padding: 0 24px

  &__scale
    padding: 16px

  &__collaboration
    .fbf-list-item__media
      width: 160px

  &__content-padded
    @include content-padding-md

@include mq-in-notepad
  .fbf-reuse-dialog
    &__content-padded
      @include content-padding-sm

@include mq-in-phone
  .fbf-reuse-dialog
    &__options, &__collaboration
      .fbf-list-item
        padding: 0 8px

        &__lines
          margin-left: 24px
        &__media
          height: 72px
          width: 110px

      .fbf-divider-text
        padding: 0 16px

@include mq-in-phone-small
  .fbf-reuse-dialog
    &__options, &__collaboration
      .fbf-list-item
        padding: 0 8px 0 0

        &__lines
          margin-left: 16px
        &__media
          height: 56px
          width: 90px
