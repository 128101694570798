.fbf-landing__awards-panel
  .fbf-landing__panel__column
    @include fbf-layout__center-column--medium-width

  .fbf-landing__awards-panel__row
    @include fbf-layout--flow-right
    img + img
      margin-left: 5%
    img#wharton
      width: 50%
    img#computable
      width: 45%
