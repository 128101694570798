.fbf-landing__extension-header-panel
  > .fbf-app-bar
    z-index: auto
  > .fbf-image
    @include fbf-layout--flow-down
    justify-content: flex-start
    height: calc(100vh - 140px)
    @media(max-height: $tablet-max-height)
      height: calc(100vh - 120px)
    @media(max-width: $phone-max-width)
      height: calc(100vh - 200px)
    @media(max-height: $tablet-max-height) and (min-width: $tablet-min-width)
      height: calc(100vh - 110px)

    .fbf-layout__bg-scrim
      background: $scrim-up-50
      height: 60vh

    .fbf-app-bar
      @include fbf-layout--flex-spread

      .fbf-button--outlined
        margin-left: 16px
        &.fbf-button--raised
          color: black

    .fbf-layout__hero-unit
      @include fbf-layout__center-column
      @include fbf-layout__center-column--huge-width
      z-index: 1

      .text--h2, .text--h5
        @include text--centered

      .fbf-media-action-button
        margin-top: calc(20vh - 40px)
        @media(max-height: $tablet-max-height)
          margin-top: 10vh
        @media(max-height: $phone-max-height)
          margin-top: 16px

  .fbf-landing__extension-header-panel__call-to-action
    @include fbf-layout--flex-center
    flex-direction: row
    @media(max-width: $phone-max-width)
      flex-direction: column
      .fbf-activity-icon
        margin-top: -16px
    padding: 4vh
    position: relative

    @media(min-width: $notepad-width-out)
      .fbf-activity-icon--medium
        margin-top: -8px
        margin-bottom: -8px
      .fbf-activity-icon--large
        margin-top: -16px
        margin-bottom: -16px

    span.fbf-layout--flow-right
      @media(max-width: $phone-max-width)
        margin-right: 0
        margin-top:  8px
        margin-bottom: 16px

    .fbf-app-logo
      margin-left: 8px
      margin-top: 0
      margin-bottom: 0

    .fbf-button--raised
      @media(min-width: $phone-max-width)
        margin-left: 16px

      @media(min-width: $desktop-min-width)
        margin-left: 8vw
        margin-top: -8px
        margin-bottom: -8px

    .fbf-landing__extension-header-panel__pan-down
      position: absolute
      bottom: -20px
      z-index: 1
      left: 50%
      margin-left: -16px
      color: $color-palette-grey-500
      background-color: $color-palette-grey-100
      @media(max-width: $tablet-max-width)
        display: none

  .fbf-direct-popover.fbf-popover .fbf-popover__arrow
    right: 202px

  .fbf-lti-popover
    right: 136px
    margin-top: 16px
    @media(max-width: $notepad-width-in)
      right: 104px
    @media(max-width: $phone-max-width)
      right: 8px
