@import 'layout/center-column'
@import 'layout/components'
@import 'layout/flow'
@import 'layout/page'
@import 'layout/positioning'
@import 'layout/states'

.fbf-layout__placeholder-hero
  @include fbf-layout--flex-center
  padding: 24px
  @media(min-width: $desktop-layout-min-width)
    padding: 32px

.fbf-layout__hero-unit
  padding: 24px
  display: flex
  flex-shrink: 0
  @media(min-width: $tablet-min-width), (min-height: $tablet-min-height)
    padding: 32px
  @media(min-width: $notepad-width-in), (min-height: $desktop-min-height)
    padding: 64px
  @media(min-width: $desktop-layout-min-width), (min-height: $desktop-layout-min-height)
    padding: 80px

// e.g. For filling/spanning up the space between the leading and trailing
// elements in the app bar.
.fbf-layout__spanner
  flex-grow: 1
  align-self: stretch

.fbf-layout__bg-scrim
  @include fbf-layout--flex-center
  position: absolute
  top: 0
  left: 0
  right: 0
  min-height: 200px
  padding: 16px
  align-items: flex-end

.fbf-layout__bg-scrim--bottom
  top: auto
  bottom: 0
