$navigation-drawer-width: 240px

.fbf-navigation-drawer
  @include shadow-2dp
  @include fbf-layout--flow-down
  @include fbf-layout--scroll-overflow
  @include material-animation-default
  flex-wrap: nowrap
  width: $navigation-drawer-width
  height: 100vh
  position: absolute
  top: 0
  left: 0
  box-sizing: border-box
  background: white
  z-index: $z-navigation-drawer
  visibility: hidden

  // Transform offscreen.
  transform: translateX(-$navigation-drawer-width - 10px)
  transform-style: preserve-3d
  will-change: transform
  transition-property: transform

  &.is-visible
    transform: translateX(0)
    visibility: visible

.fbf-navigation-drawer__header
  background-color: $color-palette-blue-grey-800
  .fbf-profile-picture
    margin: 16px
    margin-bottom: 0

.fbf-navigation-drawer__menu
  flex-grow: 1
  & .fbf-list-item:not(.fbf-group-list-item):not(.fbf-group-list-item__main), .fbf-navigation-drawer__menu-item
    h6, p, small, .text--p, .text--h6, .text--small
      font-weight: 600

    &.active
      color: $color-primary-dark
      background-color: $bg-grey--100

    &:not(.fbf-navigation-drawer__menu-category)
      @include fbf-layout--actionable

  .fbf-svg
    color: $color-grey-light

.fbf-navigation-drawer__menu-category
  @include fbf-layout--flow-down

  &.fbf-list-item > .fbf-list
    padding-left: 0
    .fbf-list-item
      padding-left: 24px
      h6, p, small, .text--p, .text--h6, .text--small
        font-weight: normal

      &.active
        h6, p, small, .text--p, .text--h6, .text--small
          font-weight: 500

.fbf-navigation-drawer__app-version
  @include fbf-layout--flow-right
  padding: 16px
  justify-content: flex-end

.fbf-navigation-drawer--fixed
  position: relative
  flex-shrink: 0
  margin-right: -$navigation-drawer-width
  left: -$navigation-drawer-width
  transition-property: margin-right, left
  &.is-visible
    margin-right: 0
    left: 0
