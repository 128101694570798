.fbf-direct-popover
  position: absolute
  flex-direction: column
  max-width: 336px
  right: 16px
  margin-top: 16px
  &.fbf-popover .fbf-popover__arrow
    right: 88px

  .fbf-popover__arrow
    left: inherit

.fbf-direct-popover__items
  display: flex
  flex-direction: row
  flex-wrap: wrap
  padding-left: 8px
  padding-right: 8px
  & > *
    padding-left: 8px
    padding-right: 8px
    min-width: auto
    width: calc(100% / 3)

.fbf-direct-popover__footer
  background-color: $bg-grey
  border-bottom-right-radius: 4px
  border-bottom-left-radius: 4px

  a
    display: flex
    align-items: center
    justify-content: center
    .fbf-list-item__lines
      flex-grow: 0
      margin-left: 8px

  small
    font-weight: 400
    color: black

.fbf-direct-popover__lms-footer
  background-color: $color-palette-grey-800

  img
    width: 100%

  span
    color: white
