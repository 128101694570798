// Input style that appears when searching in the header
.fbf-textfield--style-header
  .mdl-textfield__input
    border-bottom: 0
    color: #ccc

// Note that this !important should be considered temporary until the overriding
// dialog styles are refactored without regressions
.fbf-textfield--fullwidth
  width: 100% !important

.fbf-textfield--small
  width: calc(100% - 8px)
  margin-top: -20px
  margin-bottom: -20px

.fbf-textfield--small-text
  input
    font-size: 14px

.fbf-textfield--dirty
  @extend .is-dirty

.fbf-textfield--link.is-disabled
  .mdl-textfield__input
    color: $color-blue !important //Felix would place a comment here about how he does not want to hack

  .mdl-textfield__label
    color: $color-primary !important

.fbf-textfield
  .mdl-button
    right: 0

  .fbf-button
    position: absolute
    right: 0
    top: 12px

  .flatpickr-wrapper
    display: inline-block
    width: 100%

@mixin fbf-textfield-color-variant($input-text-label-color, $input-text-bottom-border-color, $input-text-highlight-color)
  $input-text-disabled-color: $input-text-bottom-border-color
  $input-text-disabled-text-color: $input-text-label-color
  $input-text-error-color: $color-palette-red-200

  .mdl-textfield__input
    border-bottom-color: $input-text-bottom-border-color

  .mdl-textfield.is-invalid .mdl-textfield__input
    border-color: $input-text-error-color

  fieldset[disabled] .mdl-textfield .mdl-textfield__input,
  .mdl-textfield.is-disabled .mdl-textfield__input
    border-bottom-color: $input-text-disabled-color
    color: $input-text-disabled-color

  .mdl-textfield__label
    color: $input-text-label-color

  fieldset[disabled] .mdl-textfield .mdl-textfield__label,
  .mdl-textfield.is-disabled .mdl-textfield__label
    color: $input-text-disabled-text-color

  .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
  .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
  .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label
    color: $input-text-highlight-color

  .mdl-textfield--floating-label.is-invalid .mdl-textfield__label
    color: $input-text-error-color

  .mdl-textfield__label:after
    background-color: $input-text-highlight-color

  .mdl-textfield.is-invalid .mdl-textfield__label:after
    background-color: $input-text-error-color

  .mdl-textfield__error
    color: $input-text-error-color

.context--dark
  @include fbf-textfield-color-variant(transparentize(white, 0.74), transparentize(white, 0.88), white)
  .mdl-textfield__input
    color: white

.context--embedded
  @each $color-name, $color in $colors
    &.context--#{$color-name} .fbf-app-bar
      @include fbf-textfield-color-variant($color-grey-light, $color, $color)
