@import 'fbf-backdrop'

$dialog-padding: 24px
$dialog-max-height: 90vh

@keyframes slideIn
from
  opacity: 0
  transform: translateY(72px)
to
  opacity: 1
  transform: translateY(0)

.fbf-dialog
  @include fbf-layout--flow-down
  @include shadow-16dp()
  background-color: white
  cursor: auto
  border-radius: 4px
  max-height: 80vh
  animation-name: slideIn
  animation-duration: 0.4s
  position: relative
  margin-left: 8px
  margin-right: 8px
  overflow: hidden

  #ember-testing &
    animation-duration: 10ms

  &__backdrop
    @include fbf-backdrop

  &__header, &__header-extension, & > h5, & > .text--h5
    @include fbf-layout--flow-right
    padding: $dialog-padding
    position: relative
    flex-shrink: 0

    > .fbf-button
      margin-right: 8px

    & ~ .fbf-dialog__header-extension
      padding-top: 0

  &__header
    border-radius: 4px 4px 0 0

    &--no-shadow
      box-shadow: none !important

  &__cover
    display: flex
    flex-direction: row
    align-items: flex-end
    flex-shrink: 0
    height: 30vh
    min-height: 200px
    background-color: $gradient-media-placeholder
    background-size: cover
    background-position: center

    h5, .text--h5
      background-image: $scrim-down-50
      padding: 30px $dialog-padding 16px $dialog-padding
      flex-grow: 1

  .fbf-app-bar.fbf-app-bar
    display: none

    & + *
      margin-top: 0

  &__content
    @include fbf-layout--flow-down
    @include fbf-layout--scroll-overflow
    position: relative
    flex-grow: 1
    flex-shrink: 1
    padding-left: $dialog-padding
    padding-right: $dialog-padding
    padding-bottom: $dialog-padding

    &#{&}--no-padding
      padding: 0

    &.fbf-rich-text
      display: block

    &:not(&--no-padding)
      .fbf-data-table
        flex-grow: 1
        margin-left: -$dialog-padding
        margin-right: -$dialog-padding

      .fbf-data-table__row
        padding-left: $dialog-padding / 2
        padding-right: $dialog-padding / 2

    & > *
      flex-shrink: 0

      & > hr
        margin-left: -$dialog-padding
        margin-right: -$dialog-padding
        margin-top: 8px
        margin-bottom: 8px

    & > .fbf-list
      margin-left: -$dialog-padding
      margin-right: -$dialog-padding
      & > .fbf-list-item
        padding-left: $dialog-padding / 2
        padding-right: $dialog-padding / 2

  &__footer
    padding: 8px
    display: flex
    justify-content: flex-end
    align-items: center
    flex-shrink: 0

    .fbf-button
      margin-left: 8px

  &__overlay
    @include fbf-layout--flex-center
    position: absolute
    border-radius: 4px
    background-color: transparentize($bg-grey, 0.5)
    top: 0
    left: 0
    right: 0
    bottom: 0

  // TODO: generalize this for all dialogs. This can be done if all dialogs having
  // a combo {HEADER} ~ {HEADER} append `.fbf-dialog__header--no-shadow` to the first header.
  // Otherwise this would cause two shadows.
  &#{&}--scrolling.fbf-scrollable--overflow-top
    .fbf-dialog__header, .fbf-dialog__header-extension, & > h5, & > .text--h5
      box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.3)
      z-index: 21

  &#{&}--scrolling.fbf-scrollable--overflow-bottom
    .fbf-dialog__footer
      @include shadow-top-2dp
      z-index: 21

  &#{&}--scrolling
    max-height: $dialog-max-height
    .fbf-dialog__content:not(.fbf-dialog__content--no-padding)
      padding: $dialog-padding
    .fbf-dialog__sidebar
      padding: $dialog-padding 0 $dialog-padding $dialog-padding

.browser-firefox .fbf-dialog
  .fbf-grid, .fbf-grid *
    min-height: 1px
    max-height: 100%

.fbf-dialog
  .fbf-card
    .fbf-dialog__greyhead span
      display: flex
      align-items: center
      padding: 8px

      p
        flex-grow: 1
        text-align: center

.fbf-dialog--full-height
  min-height: $dialog-max-height

// This still requires the author to implement some UI elements.
// See the docs in the logic.
@mixin fbf-dialog-fullscreen
  position: fixed
  top: 0
  left: 0
  border-radius: initial
  max-height: initial
  height: 100%
  width: 100vw
  z-index: $z-dialog
  margin: 0
  padding-bottom: $tablet-max-width
  -webkit-overflow-scrolling: touch
  transition: none

  .fbf-dialog__header, & > h5, & > .text--h5, .fbf-dialog__footer
    display: none

  .fbf-dialog__header-extension
    @include fbf-layout--pad-app-bar

  .fbf-dialog__content:not(.fbf-dialog__content--no-padding)
    @include fbf-layout--margin-app-bar
    padding: 0

    &.fbf-dialog__content--padding
      margin-top: $dialog-padding + $fbf-tool-bar-height

    &:not(.fbf-dialog__content--padding) > .fbf-list
      margin-left: 0
      margin-right: 0

    & > hr
      margin: 0 $dialog-padding

  .fbf-dialog__header-extension ~ .fbf-dialog__content
    padding-top: 0

  .fbf-dialog__content--padding
    margin: $dialog-padding

  .fbf-dialog__content--padding-top
    margin-top: $dialog-padding

  .fbf-dialog__app-bar, .fbf-app-bar
    display: flex !important

  .context--embedded &
    border: 1px solid $color-palette-grey-100
    .fbf-app-bar
      border: none

// Responsive way of spanning to fullscreen
.fbf-dialog--fullscreen
  &.block-width-5, &.block-width-4, &.block-width-3, &.block-width-2
    @media (max-width: 280px), (max-height: 320px)
      @include fbf-dialog-fullscreen
  &.block-width-7, &.block-width-6
    @media (max-width: 420px), (max-height: 500px)
      @include fbf-dialog-fullscreen
  &.block-width-10, &.block-width-9, &.block-width-8
    @media (max-width: 560px), (max-height: 500px)
      @include fbf-dialog-fullscreen
  &.block-width-15, &.block-width-14, &.block-width-13, &.block-width-12, &.block-width-11
    @media (max-width: 840px), (max-height: 500px)
      @include fbf-dialog-fullscreen
  &.block-width-22, &.block-width-21, &.block-width-19, &.block-width-18, &.block-width-17, &.block-width-16
    @media (max-width: 1240px), (max-height: 680px)
      @include fbf-dialog-fullscreen

.fbf-dialog--force-fullscreen
  @include fbf-dialog-fullscreen

.fbf-sidebar .fbf-dialog
  width: 100%
  height: auto
  border-radius: 0
  @include shadow-2dp()

  .fbf-dialog__header, & > h5, & > .text--h5
    display: none

  .fbf-dialog__content
    padding: $dialog-padding

  .fbf-dialog__app-bar, .fbf-app-bar
    display: flex

.browser-ios .fbf-app-bar__sibling--has-fullscreen-dialog.fbf-dialog__content
  margin: 0
  z-index: 20
  height: inherit

.fbf-dialog--media
  background-color: $color-palette-grey-900
  justify-content: center
  img
    object-fit: contain
    width: 100%
    height: 100%
  iframe
    display: flex
    flex-grow: 1

  &.fbf-dialog--size-extra-large
    min-height: 480px

  &.fbf-dialog--size-huge
    min-height: 560px

// DEPRECATED: use `fbf-dialog__header--grey` instead
.fbf-dialog
  & > h5, & > .text--h5, .fbf-dialog__greyhead > h5, .fbf-dialog__greyhead > .text--h5
    padding: $dialog-padding
    padding-bottom: 20px
// DEPRCATED: use the regularized `.bg-grey` classes
.fbf-dialog__header--grey
  background-color: $bg-grey--100
.fbf-dialog--grey-bg-light
  background-color: $bg-grey--100
.fbf-dialog--grey-bg
  background-color: $bg-grey
.fbf-dialog--grey-bg-dark
  background-color: $bg-grey--300
  &.fbf-dialog--scrolling
    .fbf-dialog__header, .fbf-dialog__header-extension, .fbf-dialog__footer
      border-color: $bg-grey--400
.fbf-dialog--dimmed
  background-color: $bg-grey--300

.fbf-dialog__greyhead
  background-color: $bg-grey--100
  border-radius: 4px 4px 0 0
  overflow: hidden
  flex-shrink: 0

  center
    display: flex
    flex-direction: column
    margin: $dialog-padding

    .fbf-profile-picture
      margin-bottom: 8px

  .fbf-activity-icon
    width: 52px
    height: 52px
    flex: 1 0 auto
    margin-right: 16px
    align-self: flex-start
