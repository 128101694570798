.fbf-text
  font-family: $roboto-family
  margin-bottom: 0
  margin-top: 0
  flex-shrink: 1
  overflow: hidden
  text-overflow: ellipsis
  word-wrap: break-word
  opacity: 0.87

  // Links (link-to) within fbf-text
  a
    color: $color-accent
    font-weight: normal

  h1
    font-size: 16px

  h2
    font-size: 15px

  h3, h4, h5, h6
    font-size: 14px

// Color and shading
.fbf-text--muted
  opacity: 0.54

.fbf-text--white
  color: white

// Weight
.fbf-text--strong
  font-weight: bold

// Line height
.fbf-text--dense
  line-height: 20px

// Layout
.fbf-text--padding
  padding-left: 11px
  padding-bottom: 10px

.fbf-text--divider
  padding-left: 6px
  padding-right: 6px
  font-weight: 800

.fbf-text--center
  text-align: center

.fbf-text--readmore
  cursor: pointer

// Deprecated: see component logic for details
.fbf-text--light
  color: $color-grey-light

.fbf-text--title
  font-size: 16px
  padding-bottom: 0

.fbf-text--caption
  font-size: 12px
  line-height: 16px
