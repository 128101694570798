@mixin fbf-layout__activity-icon
  width: 52px
  height: 52px
  margin: 10px
  flex: none

@mixin fbf-layout__card-list-item
  display: flex
  margin-bottom: 8px
  transition-property: margin-bottom
  transition-duration: 0.2s

  @media(min-width: $desktop-layout-min-width)
    margin-bottom: 16px

  .fbf-card
    margin-bottom: 0
    flex-grow: 1

@mixin fbf-layout--margin-app-bar
  margin-top: $fbf-tool-bar-height
@mixin fbf-layout--pad-app-bar
  padding-top: $fbf-tool-bar-height
@mixin fbf-layout--pad-app-bar-collapsing
  padding-top: $fbf-app-bar-height--collapsing
