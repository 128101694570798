.fbf-study-picker
  background-color: $bg-grey--100
  padding: 16px

  > h6
    margin-top: 32px

  .fbf-breadcrumbs
    margin: 24px 0 8px

.fbf-study-picker__search
  padding: 16px
  display: flex
  margin-bottom: 16px

  .fbf-icon
    margin-right: 16px

  input
    border: 0
    flex: auto
    font-size: 16px

  input:focus
    outline: none