//Width
$phone-small-width-in: 360px
$phone-small-width-out: 361px

$phone-max-width: 479px

$notepad-width-in: 639px
$notepad-width-out: 640px

$tablet-min-width: 480px
$tablet-max-width: 839px

$desktop-min-width: 840px
$desktop-max-width: 1023px

$desktop-layout-min-width: 1024px
$desktop-large-min-width: 1200px

// Height
$phone-max-height: 639px

$tablet-min-height: 640px
$tablet-max-height: 719px

$desktop-min-height: 720px
$desktop-max-height: 849px

$desktop-layout-min-height: 850px
$desktop-large-min-height: 960

// Media queries
$within-phone: "(orientation: portrait) and (max-width: #{$phone-max-width}) and (max-height: #{$phone-max-height}), (orientation: landscape) and (max-width: #{$phone-max-height}) and (max-height: #{$phone-max-width})"
$not-within-phone: "(orientation: portrait) and (min-width: #{$tablet-min-width}), (orientation: portrait) and (min-height: #{$tablet-min-height}), (orientation: landscape) and (min-width: #{$tablet-min-height}), (orientation: landscape) and (min-height: #{$tablet-min-width})"

$within-phone-size: "(orientation: portrait) and (max-width: #{$phone-max-width}), (orientation: portrait) and (max-height: #{$phone-max-height}), (orientation: landscape) and (max-width: #{$phone-max-height}), (orientation: landscape) and (max-height: #{$phone-max-width})"
$not-within-phone-size: "(orientation: portrait) and (min-width: #{$tablet-min-width}) and (min-height: #{$tablet-min-height}), (orientation: landscape) and (min-width: #{$tablet-min-height}) and (min-height: #{$tablet-min-width})"

@mixin mq-in-phone-small
  @media (max-width: $phone-small-width-in)
    @content

@mixin mq-in-phone
  @media (max-width: $phone-max-width)
    @content

@mixin mq-in-phone-or-sidebar
  @include mq-in-phone
    @content

  @include mq-gt-desktop
    .fbf-sidebar &
      @content

@mixin mq-in-notepad
  @media (max-width: $notepad-width-in)
    @content

@mixin mq-in-tablet
  @media (max-width: $tablet-max-width)
    @content

@mixin mq-in-desktop
  @media (max-width: $desktop-max-width)
    @content

@mixin mq-gt-desktop
  @media (min-width: $desktop-layout-min-width)
    @content
