@mixin fbf-layout__fullscreen-page
  position: fixed
  display: flex
  flex-direction: column
  box-sizing: border-box
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: $bg-grey

  .browser-mobile &, .browser-tablet &
    // Override 100vh because full view height is of writing problematic on most
    // mobile browsers due to those ofsetting the entire page downwards to make
    // room for an address bar (which only collapses when you scroll down and have
    // reached the end of the page. This causes vital bottom aligned UI's to be hidden
    // (e.g. 'show annotations' in documents/videos or 'login with email' on login)
    height: 100%

  .context--embedded &, &.context--embedded
    border: 1px solid $bg-grey--300

@mixin fbf-layout--autowidth
  width: auto
@mixin fbf-layout--autoheight
  height: auto
@mixin fbf-layout--fullwidth
  width: 100%
  min-width: 1px
@mixin fbf-layout--fullheight
  height: 100%
  min-height: 1px

@mixin fbf-layout__column-container
  @include fbf-layout--scroll-overflow
  box-sizing: border-box
  display: flex
  flex-direction: column
  flex-grow: 1

@mixin fbf-layout__column-container--padding($base-top: 0, $responsive: true)
  @include _fbf-layout__column-container--padding($base-top, $fbf-layout-content-padding--phone)
  box-sizing: border-box
  transition: padding 0.25s

  @if $responsive
    @media (min-width: $tablet-min-width)
      @include _fbf-layout__column-container--padding($base-top, $fbf-layout-content-padding--tablet)
    @media (min-width: $desktop-min-width)
      @include _fbf-layout__column-container--padding($base-top, $fbf-layout-content-padding--desktop)
    @media (min-width: $desktop-layout-min-width)
      @include _fbf-layout__column-container--padding($base-top, $fbf-layout-content-padding--desktop-layout)

@mixin fbf-layout__column-container--margin($base-top: 0, $responsive: true)
  @include _fbf-layout__column-container--margin($base-top, $fbf-layout-content-padding--phone)
  box-sizing: border-box
  transition: padding 0.25s

  @if $responsive
    @media (min-width: $tablet-min-width)
      @include _fbf-layout__column-container--margin($base-top, $fbf-layout-content-padding--tablet)
    @media (min-width: $desktop-min-width)
      @include _fbf-layout__column-container--margin($base-top, $fbf-layout-content-padding--desktop)
    @media (min-width: $desktop-layout-min-width)
      @include _fbf-layout__column-container--margin($base-top, $fbf-layout-content-padding--desktop-layout)

@mixin fbf-layout__column-container--padding-app-bar
  .fbf-app-bar--fixed.fbf-app-bar--static + &
    @include fbf-layout__column-container--padding($fbf-app-bar-shadow-height)
  .fbf-app-bar--fixed.fbf-app-bar--collapsing + &
    @include fbf-layout__column-container--padding($fbf-app-bar-height--collapsing + $fbf-app-bar-shadow-height)

@mixin fbf-layout__column-container--padding-sidebar
  @include fbf-layout__column-container--padding($fbf-app-bar-shadow-height, false)

@mixin fbf-layout--content-margin-top($responsive: true)
  transition: margin-top 0.25s
  margin-top: $fbf-layout-content-padding--phone

  @if $responsive
    @media (min-width: $tablet-min-width)
      margin-top: $fbf-layout-content-padding--tablet
    @media (min-width: $desktop-min-width)
      margin-top: $fbf-layout-content-padding--desktop
    @media (min-width: $desktop-layout-min-width)
      margin-top: $fbf-layout-content-padding--desktop-layout

@mixin _fbf-layout__column-container--padding($base-top, $padding)
  padding: #{$base-top + $padding} $padding $padding
  .fbf-app-bar + &
    padding: #{$base-top + $padding + $fbf-app-bar-shadow-height} $padding $padding

  .browser-firefox &::after
    padding-bottom: $padding
    content: ''

@mixin _fbf-layout__column-container--margin($base-top, $margin)
  margin: #{$base-top + $margin} $margin $margin
  .fbf-app-bar + &
    margin: #{$base-top + $margin + $fbf-app-bar-shadow-height} $margin $margin
