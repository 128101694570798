// This is a stripped reset based on MDL
// https://github.com/google/material-design-lite/blob/mdl-1.x/src/resets

/*
 * Make html take up the entire screen
 * Then set touch-action to avoid touch delay on mobile IE
 */
html
  width: 100%
  height: 100%
  -ms-touch-action: manipulation
  touch-action: manipulation

/*
 * Make body take up the entire screen
 * Remove body margin so layout containers don't cause extra overflow.
 */
body
  width: 100%
  min-height: 100%
  margin: 0

/*
 * Main display reset for IE support.
 * Source: http://weblog.west-wind.com/posts/2015/Jan/12/main-HTML5-Tag-not-working-in-Internet-Explorer-91011
 */
main
  display: block

/*
 * Apply no display to elements with the hidden attribute.
 * IE 9 and 10 support.
 */
*[hidden]
  display: none !important

/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::selection
    background: #b3d4fc
    text-shadow: none


/*
 * A better looking default horizontal rule
 */
hr
    display: block
    height: 1px
    border: 0
    border-top: 1px solid #ccc
    margin: 1em 0
    padding: 0

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video
    vertical-align: middle

/*
 * Remove default fieldset styles.
 */

fieldset
    border: 0
    margin: 0
    padding: 0

/*
 * Allow only vertical resizing of textareas.
 */

textarea
    resize: vertical

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print
  *,
  *:before,
  *:after,
  *:first-letter
    background: transparent !important
    // Black prints faster: http://www.sanbeiji.com/archives/953
    color: #000 !important
    box-shadow: none !important

  a,
  a:visited
    text-decoration: underline

  a[href]:after
    content: " (" attr(href) ")"

  abbr[title]:after
    content: " (" attr(title) ")"

  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */

  a[href^="#"]:after,
  a[href^="javascript:"]:after
    content: ""

  pre,
  blockquote
    border: 1px solid #999
    page-break-inside: avoid

  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */

  thead
    display: table-header-group

  tr,
  img
    page-break-inside: avoid

  img
    max-width: 100% !important

  p,
  h2,
  h3
    orphans: 3
    widows: 3

  h2,
  h3
    page-break-after: avoid
