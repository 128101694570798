.fbf-video
  display: flex
  background-color: $color-palette-grey-800

.fbf-video--stretch
  position: absolute
  min-width: 100%
  min-height: 100%
  width: auto
  height: auto
  z-index: $z-video-fullpage
  background-size: cover
