.markdown
  display: flex
  flex-direction: column
  // Might be delete-able by now
  a
    @include text--link

// Used override Markdown paragraphs with a baseline typography size
.markdown--h1
  p, ol, ul
    @include text--h1

.markdown--h2
  p, ol, ul
    @include text--h2

.markdown--h3
  p, ol, ul
    @include text--h3

.markdown--h4
  p, ol, ul
    @include text--h4

.markdown--h5
  p, ol, ul
    @include text--h5

.markdown--h6
  p, ol, ul
    @include text--h6

.markdown--p
  p, ol, ul
    @include text--p

// For beamer, offset size upwards by 2
.context--beamer
  .markdown--h3
    p, ol, ul
      @include text--h1

  .markdown--h4
    p, ol, ul
      @include text--h2

  .markdown--h5
    p, ol, ul
      @include text--h3

  .markdown--h6
    p, ol, ul
      @include text--h4

  .markdown--p
    p, ol, ul
      @include text--h5
