.activity-view--slides
  // Darker background than usual to let the fragments (e.g. slides) stand out
  background-color: $bg-grey--300
  &.fbf-page--focus-mode
    background-color: black

  // Special mobile landscape view
  @media (orientation: landscape) and (max-height: $phone-max-width) and (max-width: $tablet-max-width)
    background-color: black
    .fbf-app-bar, .fbf-progress-controls, .fbf-fullscreen-controls
      display: none
    .fbf-page__main
      padding: 0 !important
      margin-top: 0 !important

  .fbf-page__main
    .fbf-swipe
      @include fbf-layout--flex-center
      height: 100%

    .fbf-zoom-controls
      top: 16px
      right: 16px

    // The actual slide/fragment
    .fragment-view
      @include shadow-8dp

    .fragment-view--document
      background-color: $color-palette-grey-800
      img
        width: 100%

  &.fbf-page--focus-mode .fbf-page__main .fragment-view--document
    cursor: pointer

  &:not(.fbf-page--focus-mode) .fbf-proportional-scalable
    @include fbf-layout__column-container--margin


.activity-view--presentation__audience-discussion__settings
  @include fbf-layout--flex-center
  .fbf-options-field
    margin-left: 8px

  @media (min-width: $notepad-width-out)
    @include fbf-layout--flow-right
    padding-bottom: 24px
    & > * + *
      margin-left: 64px

    .context--beamer &
      @include content-padding-lg(row)
      margin-top: 40px
      & > * + *
        margin-left: 80px

  @media (max-width: $notepad-width-in)
    @include fbf-layout--flow-down
    padding-bottom: 8px
    padding-top: 8px
    & > * + *
      margin-top: 16px

.activity-view--presentation__offline.fbf-chip
  background-color: $color-red
  @include pulse
  @include pulse--fast
