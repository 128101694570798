
// * ---------------------------
// * DEPRECATED: used fbf-badge
// * ---------------------------

.fbf-label
  border-radius: 10%
  text-align: center
  margin: 4px 12px
  padding: 0 8px

// Used within the navbar flexbox
.fbf-label--fixed-height
  height: 22px
  line-height: 22px

.fbf-label--style-red
  background-color: $color-red
  color: #fff
