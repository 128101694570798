// Override mdl-selectfield's default colorscheme
.mdl-selectfield__label
  .mdl-selectfield--floating-label.is-focused &,
  .mdl-selectfield--floating-label.is-dirty &
    color: $color-primary !important

  &:after
    background-color: $color-primary !important

.fbf-selectfield
  min-width: 48px
  &::before
    content: "▼"
    position: absolute
    right: 0
    margin-top: 2px
    transform: scaleY(0.5) scaleX(0.75)
    pointer-events: none

.mdl-checkbox .mdl-selectfield
  margin: -20px 0

.fbf-selectfield--no-label
  padding-top: 0

.fbf-selectfield--disabled
  @include text--muted-more

.fbf-selectfield--inline
  display: inline-block !important
  margin: 0 8px -12px
  padding: 4px 0 20px 0
  width: auto
