.fbf-pagination
  @include fbf-layout--flex-center
  flex-shrink: 0
  height: 56px

  a
    color: $bg-grey--400

    &:empty
      width: 8px
      height: 8px
      margin: 4px
      background-color: $bg-grey--300
      transition-duration: 0.2s
      transition-property: background-color
      border-radius: 50%

    &.is-active
      background-color: $bg-grey--400
