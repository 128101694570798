@mixin app-bar-color-variant($color)
  &, & + .fbf-app-bar__extension, &--cover-image::after
    background-color: $color

@each $color-name, $color in $colors
  .context--#{$color-name} > .fbf-app-bar
    @include app-bar-color-variant($color)
  .fbf-app-bar.color-#{$color-name}
    @include app-bar-color-variant($color)

@each $color-name, $color in $colors-dark
  .fbf-sidebar .context--#{$color-name} > .fbf-app-bar
    @include app-bar-color-variant($color)

@each $color-name, $color in $colors-grey
  @if $color-name != ''
    $color-name: -#{$color-name}

  .fbf-app-bar.fbf-app-bar.color-grey#{$color-name}
    @include app-bar-color-variant($color)

.fbf-app-bar.fbf-app-bar.color-white
  @include app-bar-color-variant(white)
