@mixin fbf-backdrop
  @include fade--in
  z-index: $z-page-backdrop
  cursor: pointer
  background-color: transparentize(black, 0.5)
  display: flex
  justify-content: center
  align-items: center
  position: fixed
  bottom: 0
  right: 0
  left: 0
  top: 0

.fbf-backdrop
  @include fbf-backdrop

.fbf-backdrop--low
  z-index: $z-backdrop
