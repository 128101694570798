.fbf-progress-dot
  z-index: 4
  width: $progress-dot-size
  height: $progress-dot-size
  margin-left: -($progress-dot-size / 2)
  position: absolute
  top: ($progress-bar-thickness-horizontal / 2) - ($progress-dot-size / 2)
  border-radius: ($progress-dot-size / 2)

.fbf-progress-dot--normal
  background-color: $color-palette-white
  border: 2px solid transparentize(black, 0.5)
  box-sizing: border-box

.fbf-progress-dot--solid.fbf-progress-dot--yellow
  background-color: $color-yellow-dark

.fbf-progress-dot--required,.fbf-progress-dot--done
  width: $progress-dot-icon-size
  height: $progress-dot-icon-size
  margin-left: -($progress-dot-icon-size / 2)
  top: ($progress-bar-thickness-horizontal / 2) - ($progress-dot-icon-size / 2)
  border-radius: $progress-dot-icon-size / 2
  background-color: black
  display: flex
  justify-content: center
  transition: background-color 0.35s ease-in-out 0.15s

  &.fbf-progress-dot--yellow
    .fbf-progress-dot__icon
      color: $color-yellow-dark
      transition: color 0.35s ease-in-out

    &:hover
      background-color: $color-yellow-dark
      .fbf-progress-dot__icon
        color: white
