.fbf-snackbar
  @include fbf-layout--flow-right
  position: fixed
  bottom: 0
  left: 50%
  cursor: default
  background-color: mix($color-palette-grey-800, $color-palette-grey-900)
  z-index: 3
  display: block
  display: flex
  justify-content: space-between
  will-change: transform
  transform: translate(0, 80px)
  transition: transform 0.25s $animation-curve-fast-out-linear-in
  pointer-events: none
  z-index: $z-snackbar

  @media(max-width: $phone-max-width)
    width: 100%
    left: 0
    min-height: 48px
    max-height: 80px

  @media(min-width: $tablet-min-width)
    min-width: 288px
    max-width: 568px
    border-radius: 2px
    transform: translate(-50%, 80px)

  #ember-testing &
    transition-duration: 10ms

  &--active
    transform: translate(0, 0)
    pointer-events: auto
    transition: transform 0.25s $animation-curve-linear-out-slow-in

    @media(min-width: $tablet-min-width)
      transform: translate(-50%, 0)

  &__text
    padding: 12px 16px
    vertical-align: middle
    color: white
    float: left

  &__action
    @include text--button
    background: transparent
    border: none
    color: $color-accent
    float: right
    text-transform: uppercase
    padding: 14px 24px 14px 12px
    overflow: hidden
    outline: none
    opacity: 0
    pointer-events: none
    cursor: pointer
    text-decoration: none
    text-align: center
    align-self: center
    overflow: initial
    flex-shrink: 0

    &:not([aria-hidden])
      opacity: 1
      pointer-events: auto
