.fbf-text-area.fbf-text-field
  .fbf-textarea-inline
    align-self: flex-start
    overflow-y: auto
  .fbf-text-field__input
    padding-top: 0 !important
    padding-bottom: 0 !important
    margin-top: 19px
    margin-bottom: 18px
  .fbf-text-field__input-segment
    transition: height 0.2s $animation-curve-default
  &.is-compact .fbf-text-field__input
    margin-top: 8px
    margin-bottom: 8px

  &.has-label:not(.is-compact)
    &.is-dirty, &.is-focused
      .fbf-text-field__input
        margin-top: 24px
        margin-bottom: 13px
