.fbf-layout__center-column
  @include fbf-layout__center-column

.fbf-layout__center-column--small-width
  @include fbf-layout__center-column--small-width
.fbf-layout__center-column--medium-width
  @include fbf-layout__center-column--medium-width
.fbf-layout__center-column--normal-width
  @include fbf-layout__center-column--normal-width
.fbf-layout__center-column--large-width
  @include fbf-layout__center-column--large-width
.fbf-layout__center-column--huge-width
  @include fbf-layout__center-column--huge-width
.fbf-layout__center-column--full-width
  @include fbf-layout__center-column--full-width

.fbf-layout__center-column--margin-vertical
  @include fbf-layout__center-column--margin-vertical
.fbf-layout__center-column--padding-vertical
  @include fbf-layout__center-column--padding-vertical
