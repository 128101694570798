.fbf-matrix
  @include fbf-layout--flow-down

  &__container
    @include fbf-layout__column-container
    @include fbf-layout__column-container--padding
    @include fbf-layout__center-column
    @include fbf-layout__center-column--huge-width

  &.fbf-card
    border-radius: 4px

  .fbf-data-table__container
    display: flex

  .fbf-data-table__table
    flex-grow: 1

  .fbf-data-table__header
    font-weight: inherit

    .fbf-data-table__header-wrapper small
      @include text--muted
      font-weight: inherit

  .fbf-data-table__cell
    // The `width` attribute should make sure the columns span the entire row,
    // but fixed cells and/or browsers rounding percentages prevent this sometimes.
    // We can fix that by forcing the last cell to span the remaining width.
    &[width*="%"]:last-child
      width: auto

  tbody .fbf-data-table__row
    &:not([height])
      @include block-height(3)

    .fbf-data-table__cell-wrapper
      @include fbf-layout--scroll-overflow

  .fbf-data-table__cell-wrapper
    @include fbf-layout--flow-down
    flex-grow: 1
    align-items: flex-start
