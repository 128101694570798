$page-stepper-transition-duration: .4s

.fbf-page-stepper
  @include fbf-layout--flow-right
  @include fbf-layout--fullwidth
  @include fbf-layout--fullheight
  box-sizing: border-box
  position: relative
  overflow: hidden !important

  &__step
    @include fbf-layout--fullwidth
    @include fbf-layout--fullheight
    @include fbf-layout--flow-down
    @include fbf-layout--scroll-overflow
    position: absolute
    transition: left $page-stepper-transition-duration, z-index 0s $page-stepper-transition-duration
    z-index: 0
    box-sizing: border-box

    &--current
      transition: left $page-stepper-transition-duration
      z-index: 1

  &.is-static, #ember-testing &
    .fbf-page-stepper__step
      transition: none !important

  &.is-animated
    .fbf-page-stepper__step
      left: -100%
      &--current
        left: 0
        & ~ .fbf-page-stepper__step
          left: 100%
