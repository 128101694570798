@mixin focus-shadow() {
  box-shadow: 0 0 8px rgba(0,0,0,.18),0 8px 16px rgba(0,0,0,.36);
}

@mixin shadow-2dp($xOffset: 0, $yOffsetDirection: 1) {
  box-shadow: $xOffset #{$yOffsetDirection * 2px} 2px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              $xOffset #{$yOffsetDirection * 3px} 1px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              $xOffset #{$yOffsetDirection * 1px} 5px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-3dp($xOffset: 0) {
  box-shadow: $xOffset 3px 4px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              $xOffset 3px 3px -2px rgba(0, 0, 0, $shadow-key-umbra-opacity),
              $xOffset 1px 8px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}
@mixin shadow-4dp($xOffset: 0, $yOffsetDirection: 1) {
  box-shadow: $xOffset #{$yOffsetDirection * 4px} 5px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              $xOffset #{$yOffsetDirection * 1px} 10px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
              $xOffset #{$yOffsetDirection * 2px} 4px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity);
}
@mixin shadow-6dp($xOffset: 0) {
  box-shadow: $xOffset 6px 10px 0 rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              $xOffset 1px 18px 0 rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
              $xOffset 3px 5px -1px rgba(0, 0, 0, $shadow-key-umbra-opacity);
}
@mixin shadow-8dp($xOffset: 0) {
  box-shadow: $xOffset 8px 10px 1px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              $xOffset 3px 14px 2px rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
              $xOffset 5px 5px -3px rgba(0, 0, 0, $shadow-key-umbra-opacity);
}

@mixin shadow-16dp($xOffset: 0) {
  box-shadow: $xOffset 16px 24px 2px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              $xOffset 6px 30px 5px rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
              $xOffset 8px 10px -5px rgba(0, 0, 0, $shadow-key-umbra-opacity);
}

@mixin shadow-24dp($xOffset: 0) {
  box-shadow: $xOffset 0  9px 46px  8px rgba(0, 0, 0, $shadow-key-penumbra-opacity),
              $xOffset 0 11px 15px -7px rgba(0, 0, 0, $shadow-ambient-shadow-opacity),
              $xOffset 0 24px 38px  3px rgba(0, 0, 0, $shadow-key-umbra-opacity);
}

@mixin shadow-top-2dp($xOffset: 0) {
  @include shadow-2dp($xOffset, -1);
}

@mixin shadow-top-4dp($xOffset: 0) {
  @include shadow-4dp($xOffset, -1);
}
