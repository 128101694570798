.fbf-overflow-indicator
  background: $scrim-white-down
  position: absolute
  transition: bottom 0.2s $animation-curve-default
  bottom: -78px
  left: 0
  display: flex
  width: 100%
  justify-content: center
  padding: 16px
  box-sizing: border-box
  pointer-events: none
  &.is-active
    bottom: 0px

  .fbf-media-action-button
    pointer-events: all
