@mixin landing-column-padding
  padding: 16px 8px
  @media(min-width: $tablet-min-width)
    padding: 32px 16px
  @media(min-width: $desktop-min-width)
    padding: 48px 24px
  @media(min-width: $desktop-large-min-width)
    padding: 80px 40px

@mixin column-card
  @include fbf-layout__center-column
  @include content-padding-lg(row, false)
  z-index: 1
  margin-bottom: 64px

  @media (max-width: $notepad-width-in), (max-height: $phone-max-height)
    @include content-padding-md
  @media (min-width: $desktop-min-width)
    border-radius: 4px

  h4, .text--h4
    margin-bottom: 24px
    @media (max-width: $notepad-width-in)
      margin-bottom: 16px

@mixin column-card--above-the-fold
  margin-top: -80px
  @media (min-height: $desktop-min-height)
    margin-top: -112px
  @media (max-height: $phone-max-height)
    margin-top: -56px

@mixin direct-story
  display: flex
  margin-top: 48px
  margin-bottom: 32px

  h4, .text--h4
    @include text--light
    margin-top: 0
