.fbf-item
  position: relative
  box-sizing: border-box
  width: 100%
  display: flex
  flex-direction: row
  padding: 16px

.fbf-item__primary-icon
  line-height: 40px
  padding-left: 4px
  padding-right: 15px
  width: 40px
  height: 40px
  flex-shrink: 0

.fbf-item__primary-text
  text-align: right
  padding-right: 15px
  width: 40px
  height: 40px
  flex-shrink: 0

.fbf-item--center-content
  display: flex
  align-items: center

.fbf-item--center-content-horizontally
  display: flex
  justify-content: center

.fbf-item--padded
  padding: 24px

.fbf-item__content
  display: flex
  flex-direction: column
  justify-content: center
  width: 100%
  word-break: break-all

.fbf-item__secondary-content
  margin-right: -3px

.fbf-item__secondary-icon
  line-height: 40px
  padding-left: 15px
  padding-right: 4px
  width: 40px
  height: 40px
  flex-shrink: 0

.fbf-item--border-top
  border-top: 1px solid $bg-grey--300

.fbf-item--border-bottom
  border-bottom: 1px solid $bg-grey--300

.fbf-item__well
  background-color: $bg-grey--100
  padding: 5px 11px
  text-align: center

.fbf-item--background-grey
  padding: 8px 11px
  background-color: $bg-grey--100

.fbf-item--background-blue
  background-color: $bg-grey--50

.fbf-item--background-green
  background-color: $color-green-bg-light
  p, i, button, a, p a
    color: white
  .fbf-text--light
    opacity: 0.8

.fbf-item--background-dark-green
  background-color: $color-green-bg
  p, i, button, a, p a
    color: white
  .fbf-text--light
    opacity: 0.8

.fbf-item--background-dark-red
  background-color: $color-palette-red-900
  p, i, button, a
    color: white

.fbf-item__focal-header
  color: $color-grey-light
  text-align: center
