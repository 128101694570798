// Universal base
.fbf-button
  @include fbf-layout--flow-right
  flex-shrink: 0
  border: none
  border-radius: $button-border-radius
  position: relative
  margin: 0
  min-width: $button-min-width
  padding: 0 $button-padding
  @include text--button
  overflow: hidden
  will-change: box-shadow
  transition: box-shadow 0.2s $animation-curve-fast-out-linear-in, padding 0.2s $animation-curve-default, max-width 0.2s $animation-curve-default, background-color 0.2s $animation-curve-default, color 0.2s $animation-curve-default
  cursor: pointer
  text-decoration: none
  text-align: center
  line-height: $button-height
  box-sizing: border-box

  // Specificity boosting to prevent override by the vastly more specific context color selectors
  &.fbf-button.fbf-button
    @include button--disabled

  &:hover
    outline: none

  &.sr-only
    opacity: 0

// Flat buttons
.fbf-button--flat
  @include button--flat-grey

// Outlined buttons
.fbf-button--outlined
  @include button--outlined

// Raised and FAB buttons
.fbf-button--raised, .fbf-button--fab, .fbf-button--fab-mini, .fbf-button--fab-text, .fbf-button--fab-location
  @include button--raised-base

// Floating action buttons
.fbf-button--fab
  @include button--fab-base
  @include button--fab-size-regular

  &.fbf-button--fab-muted
    @include button--fab-size-muted

    // Needs to be specific to allow for context color inheritance on hover
    &.fbf-button:not(:hover)
      background-color: $color-palette-grey-100
      .fbf-icon, .fbf-svg
        color: $color-grey-light

    &:hover
      @include button--fab-size-unmuted

.fbf-button--fab-mini
  @include button--fab-base
  @include button--fab-size-mini

// Revert `.fbf-layout--flow-right > .fbf-icon, .fbf-svg` layout default
.fbf-button--fab, .fbf-button--fab-mini, .fbf-button--fab-text, .fbf-button--fab-location
  .fbf-icon, .fbf-svgs
    margin-right: initial

// Prefix icons in buttons
.fbf-button--flat, .fbf-button--outlined, .fbf-button--raised
  & > .fbf-icon:first-child, & > .fbf-svg:first-child
    margin-left: -8px

// Suffix icons in buttons
.fbf-button > .fbf-button__suffix-icon
  margin-right: -8px
  margin-left: 8px !important
