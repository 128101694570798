$default-size: 512

.fbf-tap-target
  display: flex
  justify-content: center
  align-items: center
  position: relative
  transition-property: visibility, opacity
  transition-duration: 0.2s
  transition-delay: 0.2s
  transition-timing-function: linear
  opacity: 0
  visibility: hidden
  z-index: $z-tap-target

  svg
    flex-shrink: 0
  .inner
    fill: white
  .pulse
    fill: transparentize(white, 0.5)
  .outer
    fill: transparentize($color-primary, 0.1)

  .fbf-tap-target__text
    @include fbf-layout--flow-down
    transition-property: opacity
    transition-duration: 0.2s
    position: absolute
    justify-content: center
    text-align: center
    width: 50%

  .fbf-tap-target__icon
    display: flex
    position: absolute
    color: $color-primary
    transform: translate(-50%, -50%)

.fbf-tap-target--open
  opacity: 1
  visibility: visible
  cursor: pointer
  .fbf-tap-target__text
    opacity: 1
  .inner
    animation-name: pulseInner
    animation-duration: 1s
    animation-delay: 2s
    animation-iteration-count: infinite
    animation-timing-function: linear

  .pulse
    animation-name: pulseOuter
    animation-duration: 1s
    animation-delay: 2s
    animation-iteration-count: infinite

.fbf-tap-target--closed
  .fbf-tap-target__text
    opacity: 0
  .inner
    animation-name: closeInner
    animation-duration: 0.6s
  .pulse
    fill: none
  .outer
    animation-name: closeOuter
    animation-duration: 0.6s

.fbf-tap-target--position-absolute
  position: absolute

.fbf-tap-target--position-fixed
  position: fixed

@keyframes pulseInner
  from
    r: 36

  30%
    r: 40

  60%
    r: 36

@keyframes pulseOuter
  from
    r: 36

  30%
    r: 40
    fill: transparentize(white, 0.5)

  to
    r: 72
    fill: transparentize(white, 1)

@keyframes closeInner
  40%
    r: 36
  to
    r: 0

@keyframes closeOuter
  to
    r: 0

// Colors
@mixin tap-target-color-variant($color-fill, $color-icon)
  .outer
    fill: transparentize($color-fill, 0.1)
  .fbf-icon
    color: $color-icon



@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-tap-target, .fbf-tap-target.color-#{$color-name}.color-#{$color-name}
    @include tap-target-color-variant(map-get($colors-dark, $color-name), $color)

// Text positions
@mixin text-top
  width: 80%
  top: 10%
  left: auto
  right: auto
  bottom: auto
  justify-content: center
  text-align: center

@mixin text-bottom
  width: 80%
  bottom: 10%
  left: auto
  right: auto
  top: auto
  justify-content: center
  text-align: center

@mixin text-bottom-left
  bottom: 10%
  right: 10%
  width: 45%
  left: auto
  justify-content: flex-end
  text-align: right

@mixin text-bottom-right
  bottom: 10%
  left: 10%
  width: 45%
  right: auto
  justify-content: flex-end
  text-align: left

@mixin text-top-left
  top: 10%
  left: 10%
  width: 45%
  right: auto
  justify-content: flex-end
  text-align: right

@mixin text-top-right
  top: 10%
  right: 10%
  width: 45%
  left: auto
  justify-content: flex-start
  text-align: left

.fbf-tap-target
  .fbf-tap-target__text
    @include text-top

// Positions
.fbf-tap-target--text-top .fbf-tap-target__text
  @include text-top
.fbf-tap-target--text-bottom .fbf-tap-target__text
  @include text-bottom
.fbf-tap-target--text-bottom-left .fbf-tap-target__text
  @include text-bottom-left
.fbf-tap-target--text-bottom-right .fbf-tap-target__text
  @include text-bottom-right
.fbf-tap-target--text-top-left .fbf-tap-target__text
  @include text-top-left
.fbf-tap-target--text-top-right .fbf-tap-target__text
  @include text-top-right

.fbf-tap-target--clipped
  .fbf-tap-target__text
    width: 65%
  &.fbf-tap-target--text-top-left .fbf-tap-target__text
    right: 10%
    left: auto
    top: 15%
  &.fbf-tap-target--text-top-right .fbf-tap-target__text
    left: 10%
    right: auto
    top: 15%

.fbf-tap-target.fbf-layout--fixed-bottomright
  bottom: $fbf-layout-corner-fixed-padding - ($default-size - 56)/2
  right: $fbf-layout-corner-fixed-padding - ($default-size - 56)/2
  z-index: $z-tap-target

  .fbf-tap-target__text
    @include text-top-left

.fbf-tap-target.fbf-layout--fixed-bottomleft
  bottom: $fbf-layout-corner-fixed-padding - ($default-size - 56)/2
  left: $fbf-layout-corner-fixed-padding - ($default-size - 56)/2
  z-index: $z-tap-target

  .fbf-tap-target__text
    @include text-top-right

.fbf-detail-view .fbf-tap-target.fbf-tap-target--position-fixed
  z-index: $z-detail-view-tap-target
