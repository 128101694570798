.fbf-icon
  display: flex
  justify-content: center
  align-items: center
  align-self: center
  word-break: initial
  color: transparentize(black, 0.5)

  &.fbf-icon--inherit
    font-size: inherit
  &.unavailable
    color: $color-palette-grey-200
  &.new
    color: $color-accent

  span &
    vertical-align: middle

// Size
// (either set manually with size classname, or automatically inherited from typo element)
.fbf-icon.fbf-icon--small, small .fbf-icon
  font-size: $fbf-icon-size--small
.fbf-icon.fbf-icon--h1, h1 .fbf-icon, .text--h1 .fbf-icon
  font-size: 72px
.fbf-icon.fbf-icon--h2, h2 .fbf-icon, .text--h2 .fbf-icon
  font-size: 45px
.fbf-icon.fbf-icon--h3, h3 .fbf-icon, .text--h3 .fbf-icon
  font-size: 34px
.text--h4, .text--h5, .text--h6
  .fbf-icon
    font-size: 24px

// Responsive size
h1.text--responsive, .text--responsive--h1, .text--h1.text--responsive
  .fbf-icon
    font-size: 72px
    @media(max-width: $tablet-max-width)
      font-size: 45px
    @media(max-width: $phone-max-width)
      font-size: 34px

.fbf-icon--circle
  border-radius: 50%

.fbf-icon--absolute
  position: absolute

.fbf-icon--faded
  opacity: 0.35

  &.fbf-icon--context-faded
    opacity: 0.45

.fbf-icon--color-white
  color: white

.fbf-icon--color-red
  color: $color-red

.fbf-icon--color-cyan
  color: $color-cyan

.fbf-icon--color-green
  color: $color-green

.fbf-icon--color-blue
  color: $color-blue

.fbf-icon--color-blue-grey
  color: $color-blue-grey

.fbf-icon--color-purple
  color: $color-purple

.fbf-icon--color-black
  color: black

.fbf-icon--color-orange
  color: $color-orange

.fbf-icon--highlight-success
  animation-name: highlightSucces
  animation-duration: 0.4s

@keyframes highlightSucces
  from:
    color: $color-grey-light
  50%
    color: $color-green
  to:
    color: $color-green

.fbf-icon.fbf-icon--badge
  background-color: $bg-grey--400
  border-radius: 50%
  color: white
  height: 40px
  width: 40px
  line-height: 40px
  text-align: center

// Specificity boosting to trump Material Design icon styles
.fbf-icon--badge
  &.fbf-icon--color-red
    background-color: $color-red

  &.fbf-icon--color-green
    background-color: $color-green

  &.fbf-icon--color-blue
    background-color: $color-blue

  &.fbf-icon--color-blue-grey
    background-color: $color-blue-grey

  &.fbf-icon--color-purple
    background-color: $color-purple

  &.fbf-icon--color-cyan
    background-color: $color-cyan

@mixin icon-color-variant($color)
  .fbf-icon--context,
  .fbf-icon--context-faded
    color: $color

.context--red
  @include icon-color-variant($color-red)

.context--green
  @include icon-color-variant($color-green)

.context--blue
  @include icon-color-variant($color-blue)

.context--blue-grey
  @include icon-color-variant($color-blue-grey)

.context--purple
  @include icon-color-variant($color-purple)

.context--cyan
  @include icon-color-variant($color-cyan)

.context--brown
  @include icon-color-variant($color-brown)
