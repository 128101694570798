.fbf-media-from-link
  @include fbf-layout--flow-right

  @media(max-width: $phone-max-width)
    flex-direction: column
  @media(min-width: $tablet-min-width)
    button
      margin-left: 16px

  small
    @include fbf-layout--flow-right
    @include text--green
    margin-left: 16px
    flex-shrink: 0
