$input-side-padding: 12px

.fbf-text-field
  position: relative
  display: flex
  flex-direction: column

  &__container
    display: flex
    position: relative
    align-items: center
    background-color: transparentize(black, 0.97)
    border-bottom: 1px solid transparentize(black, 0.9)
    border-radius: 4px 4px 0 0
    // The after label is the colored underline for the TextField.
    &:after
      @include underline
    .fbf-text-field.is-focused &:after
      @include underline--visible
    .fbf-text-field.is-invalid.is-invalid &:after
      background-color: $color-red

  &__input-segment
    display: flex
    flex-direction: row
    align-items: center
    position: relative
    min-width: 0
    flex-grow: 1

  &__input
    @include text--unmuted
    transition: padding 0.2s $animation-curve-default
    display: flex
    padding-left: $input-side-padding
    padding-right: $input-side-padding
    padding-top: 15px
    padding-bottom: 14px
    border: 0
    box-shadow: none
    outline: none
    background-color: transparent
    flex-grow: 1
    max-width: 100%
    min-width: 0

    &::placeholder
      @include text--muted-more

    /* clears the 'X' from Internet Explorer */
    &[type=search]::-ms-clear,
    &[type=search]::-ms-reveal
      display: none
      width : 0
      height: 0

    /* clears the 'X' from Chrome */
    &[type=search]::-webkit-search-decoration,
    &[type=search]::-webkit-search-cancel-button,
    &[type=search]::-webkit-search-results-button,
    &[type=search]::-webkit-search-results-decoration
      display: none

  &__label
    @include material-animation-default()
    @include text--muted-more
    left: 0
    right: 0
    top: 15px
    padding-left: $input-side-padding
    padding-right: $input-side-padding
    pointer-events: none
    position: absolute
    display: flex
    overflow: hidden
    white-space: nowrap
    text-align: left

  &__clear
    display: flex
    border: none
    background-color: transparent
    padding: 0
    width: 24px
    height: 24px
    align-self: center
    &:focus, &:hover
      @include text--unmuted

  &__helper-text
    @include text--muted
    padding: 4px $input-side-padding

  &__error
    @include text--red
    padding: 4px $input-side-padding

  &--error-compact &__error
    align-self: start

  &.fbf-datepicker
    max-width: 100%

  &.fbf-datepicker &__input
    cursor: pointer
