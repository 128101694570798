.fbf-tap-target.fbf-migration-tap-target
  background-image: url('/assets/images/backgrounds/migration.jpg')
  background-size: cover
  border-radius: 50%
  width: 280px
  height: 280px
  @include shadow-2dp
  right: 254px
  top: -112px
  z-index: 2
  position: absolute !important
  @include appear
  @include appear--invisible
  transform-origin: center
  &:hover
    @include shadow-8dp
  .inner
    r: 60 !important
    animation-name: none !important
  .fbf-tap-target__text
    bottom: 8% !important
  .fbf-migration-tap-target__inner
    @include text--button
    color: $color-primary
    width: 100px
    height: 100px
    background-color: white
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
  &.fbf-tap-target--open
    @include appear--visible
  @media (max-width: $notepad-width-in)
    position: absolute
    width: 400px
    height: 400px
    left: -172px
    top: -172px
    .fbf-tap-target__text
      text-align: left
      width: 44%
      left: 47%
      bottom: 10% !important
