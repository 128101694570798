.fbf-page__sidebar-container
  @include fbf-layout--fullheight
  align-items: initial
  position: relative

  & > .fbf-page__main
    flex-shrink: 0

  // ---------------------------
  // LARGE SCREENS
  // ---------------------------
  @include mq-gt-desktop
    @include fbf-layout--flow-right
    align-items: initial

  // ---------------------------
  // SMALL SCREENS
  // ---------------------------
  @include mq-in-desktop
    @include fbf-layout--flow-down
    @include fbf-layout--scroll-overflow
    @include fbf-layout--fullheight

    & > .fbf-page__main
      @include fbf-layout--fullheight
