.fbf-layout__fullscreen-page
  @include fbf-layout__fullscreen-page

.fbf-layout--autowidth
  @include fbf-layout--autowidth
.fbf-layout--autoheight
  @include fbf-layout--autoheight
.fbf-layout--fullwidth
  @include fbf-layout--fullwidth
.fbf-layout--fullheight
  @include fbf-layout--fullheight

.fbf-layout__column-container
  @include fbf-layout__column-container
.fbf-layout__column-container--padding
  @include fbf-layout__column-container--padding
.fbf-layout__column-container--padding-app-bar
  @include fbf-layout__column-container--padding-app-bar
.fbf-layout__column-container--padding-sidebar
  @include fbf-layout__column-container--padding-sidebar
