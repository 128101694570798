@mixin sidebar-under-transparent-app-bar
  &.fbf-app-bar--transparent ~ .fbf-page__sidebar-container .fbf-sidebar
    @content

.fbf-page > .fbf-app-bar
  transition-duration: .35s
  transition-property: margin-top, visibility, height, opacity
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)

  // ---------------------------
  // LARGE SCREENS
  // ---------------------------
  @include mq-gt-desktop
    // Transparent app-bars overlap their main content, but the sidebar shouldn't
    // be included, so we move it down below the main app-bar.
    @include sidebar-under-transparent-app-bar
      margin-top: $fbf-tool-bar-height
      border-top-left-radius: 4px

  // ---------------------------
  // SMALL SCREENS
  // ---------------------------
  @include mq-in-desktop
    // The sidebar app-bar should become on top of transparent app-bars
    @include sidebar-under-transparent-app-bar
      z-index: $z-sidebar--mobile
