$tab-inactive-fade: 0.4
$tab-inactive-color: transparentize(white, $tab-inactive-fade)
$tab-desktop-padding: 24px
$tab-mobile-padding: 12px
$tab-bar-height: 48px

// Some specificity boosting to overcome context--dark
.fbf-tab-bar .fbf-tab-bar-item
  padding: 0 $tab-desktop-padding 0 $tab-desktop-padding

  position: relative
  display: flex
  flex-direction: column

  text-align: center
  @include text--button
  line-height: $tab-bar-height

  color: $tab-inactive-color
  overflow: hidden

  @media screen and (max-width: 1023px)
    padding: 0 $tab-mobile-padding 0 $tab-mobile-padding

  .fbf-icon, .fbf-svg
    color: $tab-inactive-color
    position: relative
    top: 10px
  .fbf-svg
    align-self: center
  .fbf-badge
    opacity: 0.6

  &.is-active
    color: white
    .fbf-icon, .fbf-svg
      color: white
    .fbf-badge
      opacity: initial

  &.is-active::after
    height: 2px
    width: 100%
    display: block
    content: " "
    bottom: 0
    left: 0
    position: absolute
    background: white
    animation: border-expand 0.2s cubic-bezier(0.4, 0.0, 0.4, 1) 0.01s alternate forwards
    transition: all 1s cubic-bezier(0.4, 0.0, 1, 1)

@mixin white-tab-bar-color-variant($color)
  $color-faded: transparentize($color, $tab-inactive-fade)

  .fbf-tab-bar-item
    color: $color-faded

    .fbf-icon, .fbf-svg
      color: $color-faded
    .fbf-badge
      opacity: initial
      background-color: $color-faded

    &.is-active
      color: $color
      .fbf-icon, .fbf-svg
        color: $color
      .fbf-badge
        background-color: $color

    &.is-active::after
      background: $color

.context--dark .fbf-tab-bar
  @include white-tab-bar-color-variant(white)

@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-tab-bar.color-white.color-white
    @include white-tab-bar-color-variant($color)
