.fbf-landing__appstore-panel
  .fbf-landing__panel__column
    @include fbf-layout__center-column--normal-width

  .fbf-landing__appstore-panel__row
    @include fbf-layout--flow-right
    @include fbf-grid(2, 32)
    a
      @include fbf-card--base
      @include content-padding-lg
      @include fbf-layout--actionable
      position: relative
      img
        width: 100%
