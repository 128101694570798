// Repeating `.fbf-button` for specificity boost to trump `.context--` selectors
.fbf-button.fbf-button--color-context
  @include button--color-variant($color-primary)
.context--dark .fbf-button.fbf-button--color-context
  @include button--color-variant-context-dark($color-primary)

.fbf-button.fbf-button--color-green
  @include button--color-variant($color-green)
.context--dark .fbf-button.fbf-button--color-green
  @include button--color-variant-context-dark($color-green)

.fbf-button.fbf-button--color-blue
  @include button--color-variant($color-blue)
.context--dark .fbf-button.fbf-button--color-blue
  @include button--color-variant-context-dark($color-blue)

.fbf-button.fbf-button--color-red
  @include button--color-variant($color-red)
.context--dark .fbf-button.fbf-button--color-red
  @include button--color-variant-context-dark($color-red)

.fbf-button.fbf-button--color-purple
  @include button--color-variant($color-purple)
.context--dark .fbf-button.fbf-button--color-purple
  @include button--color-variant-context-dark($color-purple)

.fbf-button.fbf-button--color-yellow
  @include button--color-variant($color-yellow)
.context--dark .fbf-button.fbf-button--color-yellow
  @include button--color-variant-context-dark($color-yellow)

.fbf-button.fbf-button--color-cyan
  @include button--color-variant($color-cyan)
.context--dark .fbf-button.fbf-button--color-cyan
  @include button--color-variant-context-dark($color-cyan)

.fbf-button.fbf-button--color-brown
  @include button--color-variant($color-brown)
.context--dark .fbf-button.fbf-button--color-brown
  @include button--color-variant-context-dark($color-brown)

.fbf-button.fbf-button--color-blue-grey
  @include button--color-variant($color-blue-grey)
.context--dark .fbf-button.fbf-button--color-grey
  @include button--color-variant-context-dark($color-blue-grey)

.fbf-button.fbf-button--color-black
  @include button--color-variant($color-palette-grey-600)
.context--dark .fbf-button.fbf-button--color-black
  @include button--color-variant-context-dark($color-palette-grey-600)

.fbf-button.fbf-button--color-orange
  @include button--color-variant($color-orange)
.context--dark .fbf-button.fbf-button--color-orange
  @include button--color-variant-context-dark($color-orange)

.fbf-button.fbf-button--color-white, .context--dark .fbf-button.fbf-button--color-white
  &.fbf-button--raised, &.fbf-button--fab, &.fbf-button--fab-mini
    @include button--raised-base

.fbf-button.fbf-button--flat,
.fbf-button.fbf-button--outlined
  &.fbf-button--color-white, &.fbf-button--color-grey
    @include button--flat-grey
    .context--dark &
      @include button--flat-white
