.activity-view--slides
  // Need to hide overflow to allow .fbf-age__main to flex-shrink for some reason
  .fbf-page__main
    // Must be 'auto' instead to enable scrolling for when zoomed in
    overflow: auto
    &::after
      // Override for https://github.com/feedbackfruits/feedbackfruits-ui-styles/pull/615
      // because slides is not a vertically scrolling layout. Needs a solution soon!
      display: none
  .fbf-swipe
    // Flex-grow is needed for Safari/iOS for some reason
    flex-grow: 1

  // Workaround for Safari ignoring .fbf-zoom-container height: 100%
  .fbf-swipe
    flex-direction: column
  .fbf-zoom-container
    flex-grow: 1

.activity-view--presentation
  // Temporary workaround that should be removed as soon we can more easily create
  // space for the app bar (.fbf-app-bar__sibling) like with an app-bar that is position:relative/static
  .fbf-app-bar.fbf-app-bar--static.fbf-app-bar--tabbed ~ .fbf-app-bar__sibling
    margin-top: 128px

// Some specificity boosting to allow the background classes to appear
.activity-view--presentation--finished
  .fbf-tool-bar.bg-orange
    background-color: $color-orange

.activity-view--slides
  .fbf-app-bar.fbf-app-bar--tabbed + .fbf-page__main
    // Due to icons in the tab-bar, the app-bar is higher than currently programmed for
    // so in await for app-bar fixes that remove the need to hard-code the margin-top
    // pixel values like this for every app-bar case, this one override fixes it
    margin-top: 128px
