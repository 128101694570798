.fbf-bundle
  overflow: hidden
  margin-bottom: 16px
  transition-property: background-color, margin
  transition-duration: 0.2s
  background-color: white
  @media(min-width: $desktop-layout-min-width + 1)
    margin-bottom: 24px

  .fbf-bundle__header
    @include fbf-layout--flow-right
    transition-property: padding
    transition-duration: 0.2s
    .fbf-icon
      margin-right: 16px

.fbf-bundle--expanded
  background-color: $bg-grey--300
  border-radius: 8px
  box-shadow: none
  margin-top: 40px
  margin-bottom: 40px

  .fbf-bundle__header
    padding: 24px $card-icon-gutter-width
    .fbf-icon
      color: black

  .fbf-bundle__body
    padding-bottom: 24px
    @media(min-width: 840px)
      margin-right: $card-icon-gutter-width

.fbf-bundle--collapsed
  margin-left: $card-icon-gutter-width

  border-radius: 2px
  @include shadow-2dp()

  .fbf-bundle__header
    padding-top: 24px
    padding-bottom: 24px
    padding-left: 16px
    padding-right: 16px

    @media(min-width: $desktop-layout-min-width + 1)
      padding-left: 24px
      padding-right: 24px
