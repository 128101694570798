.fbf-path-direction
  display: flex
  flex-direction: column
  margin-bottom: 16px

  .fbf-card
    margin-bottom: 0
    background-color: $color-palette-grey-200
    @media(min-width: $desktop-layout-min-width + 1)
      margin-left: 80px
      margin-right: 80px

    .fbf-list
      background-color: white
      border-top: 1px solid $color-palette-grey-300

.fbf-path-direction__arrow-before, .fbf-path-direction__arrow-after
  fill: $color-palette-grey-400
  align-self: center
  padding-left: 90px

.fbf-path-direction__status
  text-align: center
  padding: 8px 16px

.fbf-path-direction__status-icon
  display: inline-block
  background-color: white
  color: $color-primary
  padding: 16px
  height: 24px
  width: 24px

.fbf-path-direction__award
  display: flex
  align-items: center
  justify-content: center
  padding: 16px

  & > .fbf-text
    margin: 0 16px
