$media-width: 15%

.fbf-option-list
  list-style: none
  padding: 0
  margin: 16px 0

.fbf-option-list--no-margin
  margin: 0

.fbf-option-list--media .fbf-option-list-item:not(.fbf-option-list-item--media)::after
  content: ''
  width: $fbf-option-list-media-preview-width
  min-width: $fbf-option-list-media-preview-min-width
