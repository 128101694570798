.fbf-timer
  @include fbf-layout--flow-right
  .fbf-text-field
    width: 64px
    margin-right: 4px
    margin-left: 4px

    &.is-invalid
      width: 100px

    + .fbf-timer__controls
      margin-left: 0

.fbf-timer__time
  display: flex
  align-items: flex-end
  small
    line-height: 1.2em
    margin-right: 4px
    align-self: flex-end
    margin-bottom: 4px

.fbf-timer--edit, .fbf-timer--set, .fbf-timer--running, .fbf-timer--expired
  height: 56px
  padding-left: 16px
  padding-right: 24px
  border-radius: 32px
  background-color: $bg-grey

  &.fbf-timer--editable
    padding-right: 16px

.fbf-timer.fbf-timer--edit
  padding-right: 10px

.fbf-timer__controls
  @include fbf-layout--flow-right
  margin-left: 16px
  .fbf-button--fab-mini
    margin-left: 8px

.fbf-timer--running
  animation-name: timerRunningHighlight
  animation-duration: 0.3s

  @keyframes timerRunningHighlight
    from
      background-color: $bg-grey
    50%
      background-color: $color-palette-blue-300
    to
      background-color: $bg-grey

.fbf-timer--expired
  animation-name: timerExpiredHighlight
  animation-duration: 0.3s

  @keyframes timerExpiredHighlight
    from
      background-color: $bg-grey
    50%
      background-color: $color-palette-red-300
    to
      background-color: $bg-grey
