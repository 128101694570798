// Light context greyshading
@mixin text-dark--unmuted
  @include text--colored($color-palette-grey-800)
@mixin text-dark--muted
  @include text--colored($color-palette-grey-600)
@mixin text-dark--muted-more
  @include text--colored($color-palette-grey-400)

// Dark context whiteshading
@mixin text-light--unmuted
  @include text--colored(transparentize(white, 0))
@mixin text-light--muted
  @include text--colored(transparentize(white, 0.3))
@mixin text-light--muted-more
  @include text--colored(transparentize(white, 0.5))

.context--dark
  @include text-light--unmuted

  .text--unmuted
    @include text-light--unmuted
  .text--muted
    @include text-light--muted
  .text--muted-more
    @include text-light--muted-more

.context--dark .context--light
  @include text-dark--unmuted

  .text--unmuted
    @include text-dark--unmuted
  .text--muted
    @include text-dark--muted
  .text--muted-more
    @include text-dark--muted-more


// Aliases for backwards compatibility
@mixin text--unmuted
  @include text-dark--unmuted
@mixin text--muted
  @include text-dark--muted
@mixin text--muted-more
  @include text-dark--muted-more

.text--unmuted
  @include text-dark--unmuted
.text--muted
  @include text-dark--muted
.text--muted-more
  @include text-dark--muted-more


.inner-text--muted
  h1, h2, h3, h4, h5, h6, p, small, span
    @include text--muted

.inner-text--muted-more
  h1, h2, h3, h4, h5, h6, p, small, span
    @include text--muted-more



// DEPRECATED: use text--muted or text--muted-more
@mixin text--grey
  color: $color-palette-grey-500

.text--grey
  @include text--grey

@mixin text--grey-dark
  opacity: 1
  color: $color-palette-grey-800

.text--grey-dark
  @include text--grey-dark
