@mixin text--h0($usePreferred: true)
  @include text--preferred-font($usePreferred)
  font-size: 112px
  font-weight: 300
  line-height: 1
  letter-spacing: -0.04em
  word-wrap: break-word

@mixin text--h1($usePreferred: true)
  @include text--preferred-font($usePreferred)
  font-size: 56px
  font-weight: 400
  line-height: 1.35
  letter-spacing: -0.02em
  word-wrap: break-word

@mixin text--h2($usePreferred: true)
  @include text--preferred-font($usePreferred)
  font-size: 45px
  font-weight: 400
  line-height: 48px
  word-wrap: break-word

@mixin text--h3($usePreferred: true)
  @include text--preferred-font($usePreferred)
  font-size: 34px
  font-weight: 400
  line-height: 40px
  word-wrap: break-word

@mixin text--h4($usePreferred: true)
  @include text--preferred-font($usePreferred)
  font-size: 24px
  font-weight: 400
  line-height: 32px
  word-wrap: break-word

@mixin text--h5($usePreferred: true)
  @include text--preferred-font($usePreferred)
  font-size: 20px
  font-weight: 500
  line-height: 28px
  letter-spacing: 0.02em
  word-wrap: break-word

@mixin text--h6($usePreferred: true)
  @include text--preferred-font($usePreferred)
  font-size: 16px
  font-weight: 400
  line-height: 24px
  word-wrap: break-word

@mixin text--p($usePreferred: false)
  @include text--preferred-font($usePreferred)
  font-size: 14px
  font-weight: 400
  line-height: 24px
  letter-spacing: 0

@mixin text--small($usePreferred: false)
  @include text--preferred-font($usePreferred)
  font-size: 12px
  line-height: 16px

@mixin text--inherit($usePreferred: false)
  @include text--preferred-font($usePreferred)
  font-size: inherit
  font-weight: inherit
  line-height: inherit
  letter-spacing: inherit
  color: inherit

.text--h0
  @include text--h0
.text--h1
  @include text--h1
.text--h2
  @include text--h2
.text--h3
  @include text--h3
.text--h4
  @include text--h4
.text--h5
  @include text--h5
.text--h6
  @include text--h6
.text--p, .text--body
  @include text--p
.text--small, .text--caption
  @include text--small
.text--inherit
  @include text--inherit
