.fbf-bottom-sheet
  display: flex
  border-radius: 2px
  transition-property: opacity, transform
  transition-duration: 0.2s

  &.fbf-popover
    z-index: $z-bottom-sheet
    margin-bottom: 0

  .fbf-popover__content
    display: flex
    flex-direction: row
    flex-wrap: wrap

  .fbf-bottom-sheet-item
    border-radius: 4px

    @media(min-width: $desktop-layout-min-width + 1)
      min-width: 112px
      width: 33.333%

  @mixin fixed-bottom-right
    position: absolute
    max-width: 340px
    bottom: 24px
    right: 24px

  @mixin fixed-bottomsheet
    @include shadow-top-4dp
    position: absolute
    left: 0
    right: 0
    bottom: 0
    border-radius: 0
    justify-content: center
    .fbf-popover__content
      justify-content: center

  // Positions in bottom right (e.g. for use with common Material Design FABs)
  &.fbf-bottom-sheet--fixed-bottom-right
    // In mobile views, regular bottomsheet behaviour
    @media #{$within-phone-size}
      @include fixed-bottomsheet
      // transform: transformY(50%)
      opacity: 1
      &.is-hidden
        // transform: transformY(0)
        opacity: 0
    @media #{$not-within-phone-size}
      transform-origin: bottom right
      @include fixed-bottom-right
      &.is-hidden
        transform: scale(0)
        opacity: 0

$bottom-sheet-3-item-width: 288px

// Selecting content in e.g. document viewer
.fbf-bottom-sheet--inline
  min-width: $bottom-sheet-3-item-width


.fbf-bottom-sheet-item
  display: flex
  flex-direction: column
  align-items: center
  padding: 16px
  box-sizing: border-box
  cursor: pointer
  min-width: 112px
  width: 20%

  &:hover
    background: $bg-grey--50

  .fbf-activity-icon
    width: 52px
    height: 52px

  .fbf-icon
    padding: 8px
    margin: 6px
    background-color: $bg-grey--100

  .text--muted
    text-align: center
