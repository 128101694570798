// Import from feedbackfruits-styles
@import 'feedbackfruits-components'
@import 'feedbackfruits-platform'

html, body, h1, h2, h3, h4, h5, h6, p, small
  font-family: "Lato", "Roboto", "Helvetica", "Arial", sans-serif !important

section
  @include fbf-layout--flow-down
  height: 100vh
  width: 100vw

.cover
  @include fbf-layout--flow-right
  @include fbf-layout--flex-center

  img
    position: absolute
    height: 100vh
    width: 100vw
    object-fit: cover
    object-position: bottom right

  &::after
    content: ''
    position: absolute
    top: 0
    height: 60vh
    width: 100vw
    background-image: linear-gradient(to top, transparent 0%, rgba(0, 0, 50, 0.2) 100%)

.cover__content
  @include fbf-layout--flex-center
  @include fbf-layout--flow-down
  @include content-padding-md
  align-self: flex-start
  margin-top: 12.5vh
  z-index: 2

  .fbf-button
    text-shadow: transparentize(black, 0.75) 0 0 8px

.cover__name
  @include h1-responsive
  @include text--thin
  text-shadow: black 0 0 48px

.about__content
  @include fbf-layout--flow-down
  @include fbf-layout__center-column--small-width
  @include content-padding-lg
  align-self: center
  align-items: center

.about__portrait
  border-radius: 50%
  width: 50vw
  max-width: 240px

.about__text
  @include text--muted
  @include text--centered

.fbf-app-bar .fbf-tool-bar
  height: 56px*2
