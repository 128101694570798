@import 'fbf-option-list'

.fbf-option-list-item textarea
  resize: none

.fbf-option-list-item.fbf-list-item
  .fbf-option-list-item__selection-control
    margin: 4px 0 4px 4px

  .fbf-list-item__lines
    position: relative
    @include fbf-layout--flow-right
    justify-content: space-between
    // To prevent the results bar from hiding behind the component
    z-index: 0

  .markdown
    margin: 4px 0px
    @media(min-width: $desktop-layout-min-width + 1)
      margin: 8px 0px
    transition: margin-left 0.2s
    .katex-display
      margin: auto

  &.fbf-option-list-item--percentage-visible .fbf-list-item__lines
    .markdown
      margin: 8px 16px

@media(max-width: $desktop-layout-min-width)
  .fbf-option-list-item.fbf-option-list-item
    .fbf-list-item__lines
      margin: 8px

.fbf-option-list-item__bar
  background-color: $bg-grey
  position: absolute
  z-index: -1
  top: 0
  bottom: 0
  width: 0%
  transition: width 0.2s
  display: flex
  align-items: center
  justify-content: flex-end
  overflow: hidden

.fbf-option-list-item__answer-count
  display: flex
  align-self: stretch
  align-items: center
  padding-right: 8px
  padding-left: 40px
  opacity: 0
  transition: opacity 0.2s $animation-curve-default
  color: white
  background-color: transparent
  white-space: nowrap

.fbf-option-list-item:hover
  .fbf-option-list-item__bar.fbf-option-list-item__bar--answer-count
    z-index: auto
  .fbf-option-list-item__answer-count
    opacity: 1

.context--cyan
  .fbf-option-list-item__bar
    background-color: transparentize($color-cyan, 0.6)
  .fbf-option-list-item__answer-count
    background-image: linear-gradient(to right, transparent 0%, $color-cyan 40%)

.fbf-option-list-item--correct .fbf-option-list-item__answer-count
  background-image: linear-gradient(to right, transparent 0%, $color-green 40%)
.fbf-option-list-item--incorrect .fbf-option-list-item__answer-count
  background-image: linear-gradient(to right, transparent 0%, $color-red 40%)

.fbf-option-list-item .fbf-list-item__tail
  position: relative

.fbf-option-list-item__stat-container
  display: flex
  flex-flow: column nowrap
  height: 100%
  justify-content: space-around

.fbf-option-list-item__stat-bar
  background-color: $color-yellow
  height: 10px

.fbf-option-list-item--focus
  background-color: $bg-grey--100

.fbf-list-item.fbf-option-list-item
  & > .fbf-activity-icon-plain
    color: black
    fill: $bg-grey--300
    align-self: flex-start

.fbf-option-list-item--actionable
  border-radius: 4px
  @include fbf-layout--actionable

.fbf-option-list-item:not(.fbf-option-list-item--actionable)
  .fbf-list-item__lines
    cursor: default

.fbf-list-item.fbf-option-list-item--selected
  .fbf-option-list-item__selection-control > .fbf-button
    color: white !important
    &.fbf-button--color-blue
      background-color: $color-blue !important
    .context-cyan &
      background-color: $color-cyan !important

.fbf-list-item.fbf-option-list-item--correct
  .fbf-option-list-item__selection-control > .fbf-button
    color: white !important
    background-color: transparentize($color-palette-green-800, 0.6) !important
    &.fbf-button--active
      background-color: $color-green !important
  .fbf-option-list-item__bar
    background-color: transparentize($color-palette-green-500, 0.6)
  .text--context-color
    color: $color-primary

.fbf-list-item.fbf-option-list-item--incorrect
  .fbf-option-list-item__selection-control > .fbf-button
    color: white !important
    background-color: transparentize($color-palette-red-900, 0.6) !important
    &.fbf-button--active
      background-color: $color-red !important
  .fbf-option-list-item__bar
    background-color: transparentize($color-red, 0.6)
  .text--context-color
    color: $color-red

.fbf-option-list-item__delete
  margin-left: 12px

.fbf-list-item.fbf-option-list-item > .media-preview
  display: flex
  flex: 0 0 auto
  align-self: stretch
  width: $fbf-option-list-media-preview-width
  min-width: $fbf-option-list-media-preview-min-width
  height: auto

.fbf-option-list-item + .fbf-option-list-item
  & > .media-preview
    border-top: 1px solid $color-palette-grey-500

// Contains some overrides for `fbf-list-item` to make it more compact
.context--beamer .fbf-list-item.fbf-option-list-item
  .fbf-option-list-item__selection-control
    margin: 12px 0 12px 12px
  .fbf-list-item__icon, .fbf-list-item__avatar
    margin: 16px 0 16px 16px
  .fbf-list-item__lines
    margin: 16px
  .fbf-list-item__tail
    margin: 16px 16px 16px 0
  .fbf-option-list-item__answer-count
    padding-right: 16px
    padding-left: 80px
