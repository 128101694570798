$tooltip-background-color: rgba(97 ,97 ,97, .9)
$tooltip-text-color: white
$tooltip-font-size: 10px
$tooltip-font-size-large: 14px

.fbf-tooltip
  @include fbf-layout--flex-center
  transform: perspective(1px) scale(0)
  transform-origin: top center
  z-index: $z-tooltip
  background: $tooltip-background-color
  border-radius: 4px
  color: $tooltip-text-color
  font-size: $tooltip-font-size
  font-weight: 500
  line-height: 14px
  max-width: 170px
  position: fixed
  top: -500px
  left: -500px
  padding: 2px 8px
  text-align: center
  min-height: 24px
  justify-content: center

  &.fbf-tooltip--active
    animation: tooltipAppear 200ms cubic-bezier(0, 0, .2, 1) forwards

  &.fbf-tooltip--wide
    max-width: 250px

.browser-mobile .fbf-tooltip
  line-height: 14px
  font-size: $tooltip-font-size-large
  padding: 4px 16px
  min-height: 32px

.fbf-tooltip__owner
  user-select: none
  -webkit-user-select: none

@keyframes tooltipAppear
  0%
    transform: perspective(1px) scale(0)
    opacity: 0

  50%
    // This is for a Safari bug.
    transform: perspective(1px) scale(0.99)

  100%
    transform: perspective(1px) scale(1)
    opacity: 1
    visibility: visible
