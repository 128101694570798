.fbf-expandable-section
  &__toggle
    @include fbf-layout--flow-right
    flex-shrink: 0

    .fbf-icon
      margin: 0 16px 0 32px
      transition: transform 0.25s ease-in-out

    &.is-expanded .fbf-icon
      transform: rotate(180deg)

  &__content
    padding-top: 8px

    &.is-hidden
      visibility: hidden
      height: 0

    &--indented
      padding-left: 72px

    .fbf-list
      padding: 0
    .fbf-list + .fbf-list
      padding-top: 8px

  &__empty
    @include text--muted
    padding: 8px 24px 56px 24px

  & + &
    padding-top: 16px
