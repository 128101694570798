.fbf-drop-target
  display: flex
  justify-content: center
  position: relative
  background-color: $color-palette-grey-900
  text-align: center
  min-width: 480px
  width: 70vw
  max-width: 640px
  border-radius: 32px
  padding: 24px
  box-sizing: border-box
  animation-name: slideIn
  animation-duration: 0.4s

  .fbf-rich-text
    border-radius: 16px
    border: 4px $color-palette-grey-800 dashed
    padding: 0 4vw
    h1
      @include h1-responsive
      @include text--light
    h5
      @include text--light
      @include text--muted
      strong
        @include text-light--unmuted
    p
      @include text--muted

.fbf-drop-target__backdrop
  z-index: $z-dropzone !important

.fbf-drop-target__icons
  position: absolute
  top: -64px
  left: 0
  right: 0
  padding-right: 40px
  .fbf-activity-icon
    height: 120px
    width: 120px
    margin-right: -46px
    animation-duration: 1s
    animation-timing-function: easeIn
    &:nth-child(2), &:nth-child(3)
      margin-top: -24px
    &:nth-child(1)
      animation-name: swingIn1
      @keyframes swingIn1
        10%
          transform: translateX(20px) translateY(40px) scale(0.8)
          fill-opacity: 0
        25%
          transform: translate(0) scale(1)
          fill-opacity: 1
    &:nth-child(2)
      animation-name: swingIn2
      @keyframes swingIn2
        0%
          fill-opacity: 0
        20%
          transform: translateX(10px) translateY(40px) scale(0.8)
          fill-opacity: 0
        35%
          transform: translate(0) scale(1)
          fill-opacity: 1
    &:nth-child(3)
      animation-name: swingIn3
      @keyframes swingIn3
        0%
          fill-opacity: 0
        30%
          transform: translateX(-10px) translateY(40px) scale(0.8)
          fill-opacity: 0
        45%
          transform: translate(0) scale(1)
          fill-opacity: 1
    &:nth-child(4)
      animation-name: swingIn4
      @keyframes swingIn4
        0%
          fill-opacity: 0
        40%
          transform: translateX(-20px) translateY(40px) scale(0.8)
          fill-opacity: 0
        55%
          transform: translate(0) scale(1)
          fill-opacity: 1
