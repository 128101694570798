.fbf-landing__about-us-panel
  .fbf-landing__panel__column
    @include fbf-layout__center-column--medium-width
    margin-bottom: 32px
    .text--h6
      @include fbf-layout--flow-down
      padding: 0 16px
      span
        display: flex
        margin-bottom: 32px
