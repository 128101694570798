// Styles for all sections in a table behaving like a cell,
// so normal cells, headers, fixed sections and titles
.fbf-data-table__section
  @include fbf-data-table--border-support
  @include fbf-data-table--body-border-support
  height: inherit

  &--fixed
    @include fbf-data-table__section--fixed
  &--fixed-left
    left: 0
  &--fixed-right
    right: 0

  &--body-bordered,
  &--body-bordered.body-border-left ~ &
    background-color: white
  &--body-bordered.body-border-right ~ &
    background-color: inherit

  .fbf-data-table__row &--auto:not(td):not(th)
    display: table-cell
    vertical-align: inherit
