.fbf-authentication
  &__container
    @include fbf-layout--flow-down
    @include fbf-layout__column-container
    @include fbf-layout__column-container--padding

    .fbf-layout__center-column
      flex-shrink: 1

  &__login-identity-providers-grid
    & > *
      margin-bottom: 16px
    @include fbf-grid(3, 16)

    @media(max-width: $tablet-max-width)
      & > *
        margin-bottom: 8px
      @include fbf-grid(3, 8)

    @media(max-width: $notepad-width-in)
      @include fbf-grid(2, 8)

    @media(max-width: $phone-small-width-in)
      @include fbf-grid(1, 8)
      .fbf-logo-card--logo-only
        padding: 8% 10%

  &__email-footer
    border-top: 1px solid $bg-grey--400
    background-color: $bg-grey--100
    @include content-padding-md
    align-items: center
    @include fbf-layout--flow-down
    flex-shrink: 0
    .fbf-button
      display: flex
      margin-bottom: 16px
      .browser-mobile &, .browser-tablet &
        margin-bottom: 56px
        margin-top: 8px

  &__form
    @include fbf-layout--flow-down
    display: flex
    flex-shrink: 0
    align-self: center
    max-width: 400px
    width: 100%
    box-sizing: border-box
    @include content-padding-md
    @media(min-height: $tablet-min-height)
      margin-top: 10vh
    .text--h5
      margin-top: 16px

  &__help
    @include text--centered
    padding-top: 16px
    border-top: 1px solid $bg-grey
    .fbf-button + .fbf-button
      margin-left: 16px
