$fbf-question-bg-light-grey: $bg-grey--100
$fbf-question-bg-dark-grey: $bg-grey--200

.fbf-question
  @include fbf-layout--flow-down

  &__body
    background-color: $fbf-question-bg-light-grey
    z-index: 2

    &--card
      @include fbf-card--base
      background-color: $fbf-question-bg-light-grey

  &__settings
    @include fbf-layout--fullheight
    background-color: $fbf-question-bg-dark-grey
    z-index: 1

  &__answering-card
    @include fbf-card--base
    border-radius: 4px
    overflow: hidden

  &__container
    @include fbf-layout__center-column
    @include fbf-layout__center-column--medium-width

  &__notice
    background-color: white

  &__santa-hat
    position: absolute
    top: 0
    transform: translate(-6px, -10px) rotate(-20deg)
    transition: transform 250ms ease-in-out

  &--bg-light-grey
    background-color: $fbf-question-bg-light-grey
  &--bg-dark-grey
    background-color: $fbf-question-bg-dark-grey

  &--compact
    .fbf-question__header .content-padding-md
      padding: 8px 16px

      & > * + *
        margin-top: 8px

    .fbf-option-list-item.fbf-list-item .markdown
      margin: 0
    .fbf-option-list-item.fbf-list-item .fbf-list-item__lines
      margin: 8px

  &.fbf-mini-card
    background-color: $fbf-question-bg-dark-grey

  & + &
    margin-top: 16px

  .fbf-list-item
    & > .fbf-list-item__icon:first-child
      margin-left: 24px
      & + .fbf-list-item__lines
        margin-left: 24px
