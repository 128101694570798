.fbf-menu-button
  position: relative
  .fbf-button:not(.fbf-button--icon)
    @include caret

  &.fbf-menu--absolute .fbf-menu-list
    top: -8px


.context--dark .fbf-menu-button .fbf-button--raised:not(.fbf-button--icon)::after
  @include caret-color-variant(transparentize(black, 0.5))

@each $color-name, $color in $colors
  .context--#{$color-name}
    .fbf-menu-button .fbf-button--raised:not(.fbf-button--icon)::after
      @include caret-color-variant(white)
    .context--dark
      .fbf-menu-button .fbf-button--raised:not(.fbf-button--icon)::after
        @include caret-color-variant($color)

.fbf-menu-button .fbf-button--flat
  &.fbf-button--color-white, &.fbf-button--color-grey
    &::after
      border-top-color: transparentize(black, 0.5) !important
