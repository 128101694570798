.share-url
  display: inline-flex
  align-items: center
  align-self: center
  .fbf-icon
    margin-right: 8px
  p
    margin: 0 !important

.share-url__chip.share-url__chip
  display: inline-flex
  flex-shrink: 0
  padding: 4px 8px
  border-radius: 56px
  background-color: transparentize(black, 0.93)
  .context--dark &
    background-color: transparentize(black, 0.8)

  .share-url--h1 &
    padding: 16px 32px
    .fbf-icon
      margin-right: 16px
    .text--h1
      letter-spacing: 4px
      display: inline

    @media(max-width: $tablet-max-width)
      padding: 8px 16px
    .text--h1
      letter-spacing: 4px
      @media(max-width: $tablet-max-width)
        letter-spacing: 2px

  .share-url--h3 &
    padding: 8px 24px
    .text--h3
      letter-spacing: 2px

  .share-url--h4 &
    padding: 8px 16px
    .text--h4
      letter-spacing: 2px

.share-url--verbose .share-url__chip
  margin-left: 16px

.share-url__domain
  @each $color-name, $color in $colors
    .context--#{$color-name} &
      color: $color

  .context--dark &
    color: white
    @include text-light--muted
