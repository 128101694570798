.fbf-page__main
  @include fbf-layout--flow-down
  flex: auto
  position: relative
  box-sizing: border-box

  &--padded
    @include fbf-layout__column-container--padding
    @include fbf-layout__column-container--padding-app-bar
  &--scrollable
    @include fbf-layout--scroll-overflow
