.fbf-menu-list
  @include fbf-layout--scroll-overflow
  @include shadow-2dp
  @include appear
  @include appear--invisible
  position: absolute
  background-color: white
  border-radius: 4px
  box-sizing: border-box
  white-space: nowrap
  z-index: $z-menu

  .fbf-list-item
    transition-property: opacity
    transition-duration: 0.15s
    transition-delay: 0.15s
    opacity: 0

    #ember-testing &
      transition-duration: 10ms
      transition-delay: 0ms

  #ember-testing &
    transition-duration: 10ms

.context--dark .fbf-menu-list
  background-color: $color-palette-grey-800

.fbf-menu-list--open
  @include appear--visible
  .fbf-list-item
    opacity: 1

.fbf-menu-list--align-left
  left: 0
  right: auto

.fbf-menu-list--align-right
  right: 0
  left: auto

.fbf-menu-list.fbf-menu-list--align-bottom-left
  bottom: 0
  top: auto !important
  left: 0
  right: auto

.fbf-menu-list.fbf-menu-list--align-bottom-right
  bottom: 0
  top: auto !important
  right: 0
  left: auto
