.fbf-shortcuts-dialog
  .fbf-data-table__row
    &:not(.fbf-data-table__row--header)
      .fbf-data-table__cell-wrapper
        padding: 4px 8px 4px $dialog-padding

    &.fbf-data-table__row--header
      .fbf-data-table__cell div
        border-bottom: 2px solid $bg-grey
      .fbf-data-table__cell-wrapper
        padding-bottom: 8px
