.fbf-stepper__step
  display: flex
  flex-shrink: 0
  border: 0
  outline: none
  padding: 0 0 0 24px
  background-color: transparent
  flex-grow: 1
  border-radius: 4px
  @include fbf-layout--actionable

  &:active
    background-color: $color-palette-grey-200

  &:last-child
    flex-grow: 0
    padding-right: 16px
    .fbf-stepper__step__line
      display: none


.fbf-stepper__step__label
  @include fbf-layout--flow-down
  align-items: flex-start
  margin-left: 8px
  margin-right: 8px
  // Tiny amount of padding to offset the absence of heavy font
  // weight and prevent layout jittering on selecting steps
  padding-left: 1px
  padding-right: 1px

.fbf-stepper__step__line
    background-color: transparentize(black, 0.8)

.fbf-stepper__step--active, .fbf-stepper__step--completed
  .fbf-stepper__step__label
    padding: 0

.fbf-stepper__step--active
  .fbf-stepper__step__circle
    background-color: $color-palette-blue-600

.fbf-stepper__step--completed
  .fbf-icon.fbf-stepper__step__circle
    color: $color-palette-blue-600

.fbf-stepper__step--disabled
  .fbf-icon
    @include text--muted-more
  &:hover, &:active
    background-color: initial
    cursor: default

.fbf-stepper__step--active, .fbf-stepper__step--completed
  .fbf-stepper__step__label
    h1, h2, h3, h4, h5, h6, p, small
      font-weight: 500

.fbf-stepper__step--horizontal
  min-height: 72px
  max-width: 480px
  align-items: center

  .fbf-stepper__step__line
    height: 1px
    flex-grow: 1
    align-self: center
    margin-left: 2px
    margin-right: -14px

.fbf-stepper__step--vertical
  min-height: 64px
  position: relative
  .fbf-stepper__step__circle
    align-self: flex-start
    margin: 20px 0
  .fbf-stepper__step__label
    margin-left: auto
    text-align: left
  .fbf-stepper__step__body
    margin-left: 8px
    margin-right: 16px
    margin-top: 19px

    .fbf-stepper__step__content
      display: none
      margin-top: 8px
      margin-bottom: 16px
      text-align: left

  .fbf-stepper__step__line
    width: 1px
    position: absolute
    left: 36px
    top: 48px
    bottom: -16px

  &.fbf-stepper__step--active
    .fbf-stepper__step__content
      @include fbf-layout--flow-down

small.fbf-stepper__step__circle
  @include fbf-layout--flex-center
  flex-shrink: 0
  align-self: center
  width: 24px
  height: 24px
  border-radius: 50%
  background-color: $color-palette-grey-500
  color: white

.fbf-icon.fbf-stepper__step__circle
  transform: scale(1.2)

.fbf-stepper__step--editable
  // Hacks upon hacks ... until we get rid of MDL
  .mdl-checkbox
    padding-left: 16px
    width: auto
    margin-top: 1px
    align-self: center

.fbf-stepper__step--mobile-dots
  width: 8px
  height: 8px
  margin: 20px 4px
  flex-grow: 0
  padding: 0
  background-color: transparentize(black, 0.8)
  .context--dark &
    background-color: transparentize(white, 0.8)
  &:last-child
    padding-right: initial
  &:hover
    background-color: transparentize(black, 0.6)
    .context--dark &
      background-color: transparentize(white, 0.6)
  &.fbf-stepper__step--active
    background-color: $color-palette-blue-600
    .context--dark &
      background-color: white
