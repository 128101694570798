$color-palette-red-50: rgb(255,235,238)
$color-palette-red-100: rgb(255,205,210)
$color-palette-red-200: rgb(239,154,154)
$color-palette-red-300: rgb(229,115,115)
$color-palette-red-400: rgb(239,83,80)
$color-palette-red-500: rgb(244,67,54)
$color-palette-red-600: rgb(229,57,53)
$color-palette-red-700: rgb(211,47,47)
$color-palette-red-800: rgb(198,40,40)
$color-palette-red-900: rgb(183,28,28)
$color-palette-red-A100: rgb(255,138,128)
$color-palette-red-A200: rgb(255,82,82)
$color-palette-red-A400: rgb(255,23,68)
$color-palette-red-A700: rgb(213,0,0)

$color-palette-pink-50: rgb(252,228,236)
$color-palette-pink-100: rgb(248,187,208)
$color-palette-pink-200: rgb(244,143,177)
$color-palette-pink-300: rgb(240,98,146)
$color-palette-pink-400: rgb(236,64,122)
$color-palette-pink-500: rgb(233,30,99)
$color-palette-pink-600: rgb(216,27,96)
$color-palette-pink-700: rgb(194,24,91)
$color-palette-pink-800: rgb(173,20,87)
$color-palette-pink-900: rgb(136,14,79)
$color-palette-pink-A100: rgb(255,128,171)
$color-palette-pink-A200: rgb(255,64,129)
$color-palette-pink-A400: rgb(245,0,87)
$color-palette-pink-A700: rgb(197,17,98)

$color-palette-purple-50: rgb(243,229,245)
$color-palette-purple-100: rgb(225,190,231)
$color-palette-purple-200: rgb(206,147,216)
$color-palette-purple-300: rgb(186,104,200)
$color-palette-purple-400: rgb(171,71,188)
$color-palette-purple-500: rgb(156,39,176)
$color-palette-purple-600: rgb(142,36,170)
$color-palette-purple-700: rgb(123,31,162)
$color-palette-purple-800: rgb(106,27,154)
$color-palette-purple-900: rgb(74,20,140)
$color-palette-purple-A100: rgb(234,128,252)
$color-palette-purple-A200: rgb(224,64,251)
$color-palette-purple-A400: rgb(213,0,249)
$color-palette-purple-A700: rgb(170,0,255)

$color-palette-deep-purple-50: rgb(237,231,246)
$color-palette-deep-purple-100: rgb(209,196,233)
$color-palette-deep-purple-200: rgb(179,157,219)
$color-palette-deep-purple-300: rgb(149,117,205)
$color-palette-deep-purple-400: rgb(126,87,194)
$color-palette-deep-purple-500: rgb(103,58,183)
$color-palette-deep-purple-600: rgb(94,53,177)
$color-palette-deep-purple-700: rgb(81,45,168)
$color-palette-deep-purple-800: rgb(69,39,160)
$color-palette-deep-purple-900: rgb(49,27,146)
$color-palette-deep-purple-A100: rgb(179,136,255)
$color-palette-deep-purple-A200: rgb(124,77,255)
$color-palette-deep-purple-A400: rgb(101,31,255)
$color-palette-deep-purple-A700: rgb(98,0,234)

$color-palette-indigo-50: rgb(232,234,246)
$color-palette-indigo-100: rgb(197,202,233)
$color-palette-indigo-200: rgb(159,168,218)
$color-palette-indigo-300: rgb(121,134,203)
$color-palette-indigo-400: rgb(92,107,192)
$color-palette-indigo-500: rgb(63,81,181)
$color-palette-indigo-600: rgb(57,73,171)
$color-palette-indigo-700: rgb(48,63,159)
$color-palette-indigo-800: rgb(40,53,147)
$color-palette-indigo-900: rgb(26,35,126)
$color-palette-indigo-A100: rgb(140,158,255)
$color-palette-indigo-A200: rgb(83,109,254)
$color-palette-indigo-A400: rgb(61,90,254)
$color-palette-indigo-A700: rgb(48,79,254)

$color-palette-blue-50: rgb(227,242,253)
$color-palette-blue-100: rgb(187,222,251)
$color-palette-blue-200: rgb(144,202,249)
$color-palette-blue-300: rgb(100,181,246)
$color-palette-blue-400: rgb(66,165,245)
$color-palette-blue-500: rgb(33,150,243)
$color-palette-blue-600: rgb(30,136,229)
$color-palette-blue-700: rgb(25,118,210)
$color-palette-blue-800: rgb(21,101,192)
$color-palette-blue-900: rgb(13,71,161)
$color-palette-blue-A100: rgb(130,177,255)
$color-palette-blue-A200: rgb(68,138,255)
$color-palette-blue-A400: rgb(41,121,255)
$color-palette-blue-A700: rgb(41,98,255)

$color-palette-light-blue-50: rgb(225,245,254)
$color-palette-light-blue-100: rgb(179,229,252)
$color-palette-light-blue-200: rgb(129,212,250)
$color-palette-light-blue-300: rgb(79,195,247)
$color-palette-light-blue-400: rgb(41,182,246)
$color-palette-light-blue-500: rgb(3,169,244)
$color-palette-light-blue-600: rgb(3,155,229)
$color-palette-light-blue-700: rgb(2,136,209)
$color-palette-light-blue-800: rgb(2,119,189)
$color-palette-light-blue-900: rgb(1,87,155)
$color-palette-light-blue-A100: rgb(128,216,255)
$color-palette-light-blue-A200: rgb(64,196,255)
$color-palette-light-blue-A400: rgb(0,176,255)
$color-palette-light-blue-A700: rgb(0,145,234)

$color-palette-cyan-50: rgb(224,247,250)
$color-palette-cyan-100: rgb(178,235,242)
$color-palette-cyan-200: rgb(128,222,234)
$color-palette-cyan-300: rgb(77,208,225)
$color-palette-cyan-400: rgb(38,198,218)
$color-palette-cyan-500: rgb(0,188,212)
$color-palette-cyan-600: rgb(0,172,193)
$color-palette-cyan-700: rgb(0,151,167)
$color-palette-cyan-800: rgb(0,131,143)
$color-palette-cyan-900: rgb(0,96,100)
$color-palette-cyan-A100: rgb(132,255,255)
$color-palette-cyan-A200: rgb(24,255,255)
$color-palette-cyan-A400: rgb(0,229,255)
$color-palette-cyan-A700: rgb(0,184,212)

$color-palette-teal-50: rgb(224,242,241)
$color-palette-teal-100: rgb(178,223,219)
$color-palette-teal-200: rgb(128,203,196)
$color-palette-teal-300: rgb(77,182,172)
$color-palette-teal-400: rgb(38,166,154)
$color-palette-teal-500: rgb(0,150,136)
$color-palette-teal-600: rgb(0,137,123)
$color-palette-teal-700: rgb(0,121,107)
$color-palette-teal-800: rgb(0,105,92)
$color-palette-teal-900: rgb(0,77,64)
$color-palette-teal-A100: rgb(167,255,235)
$color-palette-teal-A200: rgb(100,255,218)
$color-palette-teal-A400: rgb(29,233,182)
$color-palette-teal-A700: rgb(0,191,165)

$color-palette-green-50: rgb(232,245,233)
$color-palette-green-100: rgb(200,230,201)
$color-palette-green-200: rgb(165,214,167)
$color-palette-green-300: rgb(129,199,132)
$color-palette-green-400: rgb(102,187,106)
$color-palette-green-500: rgb(76,175,80)
$color-palette-green-600: rgb(67,160,71)
$color-palette-green-700: rgb(56,142,60)
$color-palette-green-800: rgb(46,125,50)
$color-palette-green-900: rgb(27,94,32)
$color-palette-green-A100: rgb(185,246,202)
$color-palette-green-A200: rgb(105,240,174)
$color-palette-green-A400: rgb(0,230,118)
$color-palette-green-A700: rgb(0,200,83)

$color-palette-light-green-50: rgb(241,248,233)
$color-palette-light-green-100: rgb(220,237,200)
$color-palette-light-green-200: rgb(197,225,165)
$color-palette-light-green-300: rgb(174,213,129)
$color-palette-light-green-400: rgb(156,204,101)
$color-palette-light-green-500: rgb(139,195,74)
$color-palette-light-green-600: rgb(124,179,66)
$color-palette-light-green-700: rgb(104,159,56)
$color-palette-light-green-800: rgb(85,139,47)
$color-palette-light-green-900: rgb(51,105,30)
$color-palette-light-green-A100: rgb(204,255,144)
$color-palette-light-green-A200: rgb(178,255,89)
$color-palette-light-green-A400: rgb(118,255,3)
$color-palette-light-green-A700: rgb(100,221,23)

$color-palette-lime-50: rgb(249,251,231)
$color-palette-lime-100: rgb(240,244,195)
$color-palette-lime-200: rgb(230,238,156)
$color-palette-lime-300: rgb(220,231,117)
$color-palette-lime-400: rgb(212,225,87)
$color-palette-lime-500: rgb(205,220,57)
$color-palette-lime-600: rgb(192,202,51)
$color-palette-lime-700: rgb(175,180,43)
$color-palette-lime-800: rgb(158,157,36)
$color-palette-lime-900: rgb(130,119,23)
$color-palette-lime-A100: rgb(244,255,129)
$color-palette-lime-A200: rgb(238,255,65)
$color-palette-lime-A400: rgb(198,255,0)
$color-palette-lime-A700: rgb(174,234,0)

$color-palette-yellow-50: rgb(255,253,231)
$color-palette-yellow-100: rgb(255,249,196)
$color-palette-yellow-200: rgb(255,245,157)
$color-palette-yellow-300: rgb(255,241,118)
$color-palette-yellow-400: rgb(255,238,88)
$color-palette-yellow-500: rgb(255,235,59)
$color-palette-yellow-600: rgb(253,216,53)
$color-palette-yellow-700: rgb(251,192,45)
$color-palette-yellow-800: rgb(249,168,37)
$color-palette-yellow-900: rgb(245,127,23)
$color-palette-yellow-A100: rgb(255,255,141)
$color-palette-yellow-A200: rgb(255,255,0)
$color-palette-yellow-A400: rgb(255,234,0)
$color-palette-yellow-A700: rgb(255,214,0)

$color-palette-amber-50: rgb(255,248,225)
$color-palette-amber-100: rgb(255,236,179)
$color-palette-amber-200: rgb(255,224,130)
$color-palette-amber-300: rgb(255,213,79)
$color-palette-amber-400: rgb(255,202,40)
$color-palette-amber-500: rgb(255,193,7)
$color-palette-amber-600: rgb(255,179,0)
$color-palette-amber-700: rgb(255,160,0)
$color-palette-amber-800: rgb(255,143,0)
$color-palette-amber-900: rgb(255,111,0)
$color-palette-amber-A100: rgb(255,229,127)
$color-palette-amber-A200: rgb(255,215,64)
$color-palette-amber-A400: rgb(255,196,0)
$color-palette-amber-A700: rgb(255,171,0)

$color-palette-orange-50: rgb(255,243,224)
$color-palette-orange-100: rgb(255,224,178)
$color-palette-orange-200: rgb(255,204,128)
$color-palette-orange-300: rgb(255,183,77)
$color-palette-orange-400: rgb(255,167,38)
$color-palette-orange-500: rgb(255,152,0)
$color-palette-orange-600: rgb(251,140,0)
$color-palette-orange-700: rgb(245,124,0)
$color-palette-orange-800: rgb(239,108,0)
$color-palette-orange-900: rgb(230,81,0)
$color-palette-orange-A100: rgb(255,209,128)
$color-palette-orange-A200: rgb(255,171,64)
$color-palette-orange-A400: rgb(255,145,0)
$color-palette-orange-A700: rgb(255,109,0)

$color-palette-deep-orange-50: rgb(251,233,231)
$color-palette-deep-orange-100: rgb(255,204,188)
$color-palette-deep-orange-200: rgb(255,171,145)
$color-palette-deep-orange-300: rgb(255,138,101)
$color-palette-deep-orange-400: rgb(255,112,67)
$color-palette-deep-orange-500: rgb(255,87,34)
$color-palette-deep-orange-600: rgb(244,81,30)
$color-palette-deep-orange-700: rgb(230,74,25)
$color-palette-deep-orange-800: rgb(216,67,21)
$color-palette-deep-orange-900: rgb(191,54,12)
$color-palette-deep-orange-A100: rgb(255,158,128)
$color-palette-deep-orange-A200: rgb(255,110,64)
$color-palette-deep-orange-A400: rgb(255,61,0)
$color-palette-deep-orange-A700: rgb(221,44,0)

$color-palette-brown-50: rgb(239,235,233)
$color-palette-brown-100: rgb(215,204,200)
$color-palette-brown-200: rgb(188,170,164)
$color-palette-brown-300: rgb(161,136,127)
$color-palette-brown-400: rgb(141,110,99)
$color-palette-brown-500: rgb(121,85,72)
$color-palette-brown-600: rgb(109,76,65)
$color-palette-brown-700: rgb(93,64,55)
$color-palette-brown-800: rgb(78,52,46)
$color-palette-brown-900: rgb(62,39,35)

$color-palette-grey-50: rgb(250,250,250)
$color-palette-grey-100: rgb(245,245,245)
$color-palette-grey-200: rgb(238,238,238)
$color-palette-grey-300: rgb(224,224,224)
$color-palette-grey-400: rgb(189,189,189)
$color-palette-grey-500: rgb(158,158,158)
$color-palette-grey-600: rgb(117,117,117)
$color-palette-grey-700: rgb(97,97,97)
$color-palette-grey-800: rgb(66,66,66)
$color-palette-grey-900: rgb(33,33,33)

$color-palette-blue-grey-50: rgb(236,239,241)
$color-palette-blue-grey-100: rgb(207,216,220)
$color-palette-blue-grey-200: rgb(176,190,197)
$color-palette-blue-grey-300: rgb(144,164,174)
$color-palette-blue-grey-400: rgb(120,144,156)
$color-palette-blue-grey-500: rgb(96,125,139)
$color-palette-blue-grey-600: rgb(84,110,122)
$color-palette-blue-grey-700: rgb(69,90,100)
$color-palette-blue-grey-800: rgb(55,71,79)
$color-palette-blue-grey-900: rgb(38,50,56)

$color-palette-black: black
$color-palette-white: white

// The two possible colors for overlayed text.
$color-palette-dark-contrast: white !default
$color-palette-light-contrast: $color-palette-black !default
