.fbf-app-bar--search.fbf-app-bar // For extra selector specificity
  padding-bottom: 0

  h5
    flex-grow: 0
    margin-right: 16px

  .search-bar
    margin-left: calc((100% - 800px)/2 - 16px)
    min-width: 600px

    input
      display: flex
      flex-grow: 1

  @media(max-width: $desktop-max-width)
    h5
      display: none
    .search-bar
      margin-left: calc((100% - 800px)/2 + 44px)

  @media(max-width: $tablet-max-width)
    .search-bar
      margin-left: 0
      flex-grow: 1
      min-width: initial
      background-color: transparent

      .fbf-icon
        display: none


.search-bar
  @include fbf-layout--flow-right
  border-radius: 4px
  background-color: white

  .fbf-icon
    margin-left: 8px

  input
    padding: 8px
    border: none
    font-size: 16px
    background: transparent

    &:focus
      outline: none

    &::placeholder, &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder
      font-weight: 300

.search-results
  @include fbf-layout__center-column
