// Semantically
.bg-warning
  background-color: transparentize($color-orange, 0.9)
.bg-error
  background-color: transparentize($color-red, 0.9)
.bg-notice
  background-color: transparentize($color-blue, 0.9)

// Greyscales
$bg-grey: $color-palette-grey-200

$bg-grey--50: $color-palette-grey-50
$bg-grey--100: $color-palette-grey-100
$bg-grey--200: $color-palette-grey-200
$bg-grey--300: $color-palette-grey-300
$bg-grey--400: $color-palette-grey-400

.bg-grey.bg-grey
  background-color: $bg-grey
.bg-grey--50.bg-grey--50
  background-color: $bg-grey--50
.bg-grey--100.bg-grey--100
  background-color: $bg-grey--100
.bg-grey--200.bg-grey--200
  background-color: $bg-grey--200
.bg-grey--300.bg-grey--300
  background-color: $bg-grey--300
.bg-grey--400.bg-grey--400
  background-color: $bg-grey--400

.bg-black
  background-color: black
.bg-white
  background-color: white

// Basic colors
.bg-blue
  background-color: $color-blue
.bg-blue-dark
  background-color: $color-blue-dark
.bg-red-light
  background-color: $color-red-light
.bg-red
  background-color: $color-red
.bg-red-dark
  background-color: $color-red-dark
.bg-purple
  background-color: $color-purple
.bg-purple-dark
  background-color: $color-purple-dark
.bg-yellow
  background-color: $color-yellow
.bg-yellow-dark
  background-color: $color-yellow-dark
.bg-cyan
  background-color: $color-cyan
.bg-cyan-dark
  background-color: $color-cyan-dark
.bg-brown
  background-color: $color-brown
.bg-brown-dark
  background-color: $color-brown-dark
.bg-blue-grey
  background-color: $color-blue-grey
.bg-blue-grey-dark
  background-color: $color-blue-grey-dark
.bg-light-green
  background-color: $color-light-green
.bg-green
  background-color: $color-green
.bg-green-dark
  background-color: $color-green-dark
.bg-orange
  background-color: $color-orange
