.fbf-rich-text
  h1
    margin-top: 24px
    margin-bottom: 24px

  h2
    margin-top: 24px
    margin-bottom: 24px

  h3
    margin-top: 24px
    margin-bottom: 24px

  h4
    margin-top: 24px
    margin-bottom: 16px

  h5
    margin-top: 24px
    margin-bottom: 16px

  h6
    margin-top: 24px
    margin-bottom: 16px

  p, hr, img, table, ul
    margin-bottom: 16px

  a
    @include text--link

  img
    width: 100%

  li
    h1, h2, h3, h4, h5, h6, p, small
      margin: 0
