@mixin button--context-color-variant($color)
  .fbf-button
    @include button--color-variant($color)
  .fbf-button--selectable
    @include button--selectable-color-variant($color)
  .context--dark .fbf-button
    @include button--color-variant-context-dark($color)

@each $color-name, $color in $colors
  .context--#{$color-name}
    @include button--context-color-variant($color)

// Dark context overrides (maintain position in declaration order)
.context--dark .fbf-button
  @include button--white-disabled
  &.fbf-button--flat, &.fbf-button--outlined, &.fbf-button--icon
    @include button--flat-white
