.fbf-textarea-inline
  display: flex
  font-family: $roboto-family
  font-size: 14px
  border: none
  background-color: transparent
  align-self: center
  margin: 0
  resize: none
  overflow: hidden

  &:focus
    outline: none

  &::placeholder
    @include text-dark--muted-more

.fbf-textarea-inline--manual-resize
  resize: vertical
  overflow-y: auto

.fbf-textarea-inline--stretch
  // For growing when in a vertical flow
  align-self: stretch
  // For growing when in a horizontal flow (e.g. story)
  flex-grow: 1

.fbf-textarea-inline--size-h1
  font-size: 56px
  line-height: 56px

.fbf-textarea-inline--size-h2
  font-size: 45px
  line-height: 45px

.fbf-textarea-inline--size-h3
  font-size: 34px
  line-height: 34px

.fbf-textarea-inline--size-h4
  font-size: 24px
  line-height: 24px

.fbf-textarea-inline--size-h5
  font-size: 20px
  line-height: 20px

.fbf-textarea-inline--size-h6
  font-size: 16px
  line-height: 16px
