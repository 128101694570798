@import 'fbf-toggle/variables'
@import 'fbf-toggle/mixins'

.fbf-checkbox
  @include toggle--base
  align-items: center

.fbf-checkbox__label
  @include toggle__label

.fbf-checkbox__focus-helper
  @include toggle__focus-helper
  @include material-animation-default(0.2s)
  transform: scale(0)
  transition-property: transform
  top: 0
  left: 0

.fbf-checkbox__icon
  @include toggle__icon

  .fbf-checkbox.is-enabled.is-indeterminate &
    color: $toggle-dark-color

.fbf-checkbox.is-focused
  @include toggle--is-focused

  &.is-enabled.is-indeterminate
    .fbf-toggle__focus-helper
      box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($toggle-dark-color, $toggle-focus-fade)
      background-color: transparentize($toggle-dark-color, $toggle-focus-fade)

.fbf-radio:hover
  @include toggle--has-hover

.fbf-radio:active
  @include toggle--is-clicked

@mixin checkbox--color($color)
  &.is-enabled.is-indeterminate
    .fbf-checkbox__icon
      color: $color

    &.is-focused
      .fbf-checkbox__focus-helper
        box-shadow: 0 0 0px $toggle-focus-helper-spread transparentize($color, $toggle-focus-fade)
        background-color: transparentize($color, $toggle-focus-fade)


@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-checkbox, .fbf-checkbox.color-#{$color-name}.color-#{$color-name}
    @include checkbox--color($color)
    @include toggle__icon--color($color)
    @include toggle__focus-helper--color($color)

.context--dark .fbf-checkbox
  @include toggle__icon--white
  @include toggle__focus-helper--white
  &.is-enabled.is-indeterminate .fbf-checkbox__icon
    color: $toggle-white-color
