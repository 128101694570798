@mixin list--border-color($color)
  > .fbf-list-item:first-child
    border-top: 1px solid $color
  > .fbf-list-item
    border-bottom: 1px solid $color

.fbf-list
  padding-top: 8px
  padding-bottom: 8px
  padding-left: 0
  margin-top: 0
  margin-bottom: 0

.fbf-list--no-padding
  padding: 0

.fbf-list--antimargin-16dp
  margin-left: -16px
  margin-right: -16px

.fbf-list--antimargin-24dp
  margin-left: -24px
  margin-right: -24px

.fbf-list--antimargin-32dp
  margin-left: -32px
  margin-right: -32px

.fbf-list--padding-activity-icon
  padding-left: 72px
  @media(max-width: $tablet-max-width)
    padding-left: 0
    .fbf-list-item
      padding-left: 48px

.fbf-list--bordered
  @include list--border-color($bg-grey)

  .context--dark &
    @include list--border-color(transparentize(white, 0.8))
  .bg-grey--100 > &
    @include list--border-color($bg-grey--300)

  // DEPRECATED: use `.bg-grey--100` instead
  .fbf-card--grey-bg-light > &
    @include list--border-color($bg-grey--300)


  &.fbf-list--border-inset
    & > .fbf-list-item
      border-bottom: none
      border-top: none
    & > .fbf-list-item + .fbf-list-item, & > .fbf-activity-list-item__header.fbf-list-item--step + .fbf-activity-card__tasks
      position: relative
      &::before
        top: 0
        content: ""
        position: absolute
        left: 72px
        right: 0
        border-bottom: 1px solid $bg-grey
        @media (max-width: $tablet-max-width)
          .fbf-activity-list-item &
            left: 64px
    &.fbf-activity-card__tasks
      border-bottom: 1px solid $bg-grey

.fbf-list--scrollable
  @include fbf-layout--scroll-overflow
  border-top: 1px solid $bg-grey
  border-bottom: 1px solid $bg-grey

  &.fbf-list--bordered
    // Compensate for borders of parent
    > .fbf-list-item:first-child
      border-top: none
    > .fbf-list-item:last-child
      border-bottom: none

.fbf-list > .fbf-list-item > .fbf-list-item__lines > .fbf-list
  margin-left: -16px
  padding-top: 0
  padding-bottom: 0

.fbf-list > li:not([class])
  display: block

.fbf-list--tree > .fbf-list-item--tree-node > .fbf-list--tree
  margin-top: 0
  padding: 0 0 0 40px

.fbf-list--tree--bordered
  > .fbf-list-item:last-child > .fbf-list-item
    border-bottom: 1px solid $bg-grey--300
  &.fbf-list--tree, .fbf-list--tree
    > .fbf-list-item
      &:not(:first-child)
        border-top: 1px solid $bg-grey--300
      &.fbf-list-item--expanded > .fbf-list-item
        border-bottom: 1px solid $bg-grey--300

.fbf-list > hr, .fbf-list .fbf-list__divider
  margin-top: 8px
  margin-bottom: 8px

.fbf-list > li.text__subheader
  padding: 8px 16px

.fbf-list > .fbf-list-item > .fbf-list-item__lines.text__subheader
  margin: 12px 16px
