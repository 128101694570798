.fbf-comments-inline-list-item
  @include fbf-layout--flow-down

.fbf-comments-inline-list-item__content
  @include fbf-layout--fullwidth
  box-sizing: border-box
  display: flex
  flex-direction: row
  flex-shrink: 0
  padding: 4px 16px 0 16px

  > .fbf-profile-picture, > .fbf-activity-icon
    flex-shrink: 0
  > .fbf-activity-icon
    margin: -6px

.fbf-comments-inline-list-item__primary
  @include fbf-layout--flow-down
  justify-content: center
  margin-left: 16px
  flex-grow: 1

.fbf-comments-inline-list-item__header
  @include fbf-layout--flow-right
  // To retain static layout upon addition/removal of `fbf-badges`
  height: 22px
  margin-top: -4px
  & > small, & > .text--small
    margin-right: 8px
    @include text--truncate

.fbf-comments-inline-list-item__author
  font-weight: bold

.fbf-comments-inline-list-item__time
  @include text--muted-more

.fbf-comments-inline-list-item__body-area
  @include fbf-layout--flow-right
  align-items: flex-start

  .fbf-comments-inline-list-item__body
    + .fbf-comments-inline-list-item__actions, + .fbf-comments-inline-list-item__toolbar
      margin-left: 0
      margin-top: -4px
      padding-top: 0

  .fbf-fragment-tag__container
    margin: 4px 0

  .fbf-activity-options-menu
    margin: -4px 0 -4px 16px

  .fbf-comments-inline-list-item__actions, .fbf-comments-inline-list-item__toolbar
    + .fbf-activity-options-menu
      margin-left: 0

.fbf-comments-inline-list-item__body
  @include text--dense
  @include text--wrap
  @include fbf-layout--fullwidth

  p
    line-height: inherit

  img
    max-width: 100%

.fbf-comments-inline-list-item__actions
  display: flex
  margin-top: 4px
  margin-bottom: 4px
  justify-content: space-between

.fbf-comments-inline-list-item__toolbar
  @include fbf-layout--flow-right
  margin-left: -14px

  .fbf-button--icon:not(.fbf-button--active)
    color: $button-text-color

  & > .fbf-button--icon
    margin-left: 8px

.fbf-comments-inline-list-item--cons
  margin-left: 40px

  .fbf-comments-inline-list-item__content
    padding-top: 0

.fbf-comments-inline-list-item--emoji .fbf-comments-inline-list-item__body p
  font-size: 36px
  line-height: 40px
  margin-top: 4px

@mixin comments-replies-at-depth($depth)
  $indentLevel: $depth - 1
  > .fbf-comments-list > .fbf-comments-inline-list-item > .fbf-comments-inline-list-item__content
    padding-left: $indentLevel * 56px + 16px
    .context--beamer &
      padding-left: $indentLevel * 80px + 16px
    .fbf-sidebar &
      padding-left: $indentLevel * 16px + 16px
    @media(max-width: $tablet-max-width)
      padding-left: $indentLevel * 16px + 16px
  > .fbf-comments-new-inline.fbf-comments-new-inline--rounded
    margin-left: $indentLevel * 56px + 8px
    .context--beamer &
      margin-left: $indentLevel * 80px + 8px
    .fbf-sidebar &
      margin-left: $indentLevel * 16px + 8px
    @media(max-width: $tablet-max-width)
      margin-left: $indentLevel * 16px + 8px

.fbf-comments-inline-list-item__replies
  margin: 8px 0

  > .fbf-comments-list
    background: none
    padding-top: 8px

  &.fbf-comments-inline-list-item__replies--depth-2
    @include comments-replies-at-depth(2)
    background-color: $color-palette-grey-100
  &.fbf-comments-inline-list-item__replies--depth-3
    @include comments-replies-at-depth(3)
    background-color: $color-palette-grey-200

  &.fbf-comments-inline-list-item__replies--depth-border
    border-bottom: 2px solid $color-palette-grey-300
    border-top: 2px solid $color-palette-grey-300
    > .fbf-comments-new-inline:not(.fbf-comments-new-inline--rounded)
      border-top-width: 2px

.fbf-comments-inline-list-item--bordered
  border-top: 1px solid $color-palette-grey-100

  &:first-child
    border-top: none
  > .fbf-comments-inline-list-item__content
    padding: 16px

.context--beamer
  .fbf-comments-inline-list-item > .fbf-comments-inline-list-item__content
    padding: 16px 24px

  .fbf-comments-inline-list-item__replies > .fbf-comments-list > .fbf-comments-inline-list-item:last-child > .fbf-comments-inline-list-item__content
    padding-bottom: 16px

  .fbf-comments-inline-list-item--cons
    padding-top: 0
    margin-left: 64px

  .fbf-comments-inline-list-item__primary
    margin-left: 16px
    align-self: center

  .fbf-comments-inline-list-item__header
    small, .text--small
      font-size: 16px
      line-height: 24px

  .fbf-comments-inline-list-item__body > *
    font-size: 24px
    line-height: 32px
