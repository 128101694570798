@mixin tree-list-indentation($indentation)
  $list-head-content: $indentation - $fbf-list-item-padding

  margin-top: 0
  padding: 0 0 0 $indentation

  & > .fbf-tree-list__node > .fbf-list-item > .fbf-tree-list__node__prehead
    left: -$indentation
    width: $indentation

    & > .fbf-icon--small
      margin-left: $fbf-list-item-padding + (($list-head-content - $fbf-icon-size--small) / 2)

.fbf-tree-list
  border: 0 solid
  border-color: $bg-grey--300

  &__node
    @include fbf-layout--flow-down

  &__node,
  &__node > .fbf-list-item
    border: 0 solid
    border-color: inherit

  &__node > .fbf-list-item
    position: relative

  &__node__prehead
    @include fbf-layout--fullheight
    position: absolute
    display: flex
    align-items: center
    background-color: inherit

  &--indentation-none
    @include tree-list-indentation(0)
  &--indentation-extra-small
    @include tree-list-indentation(24px)
  &--indentation-small
    @include tree-list-indentation(32px)
  &--indentation-medium
    @include tree-list-indentation($fbf-list-item-padding + $fbf-list-item-head-content-width--medium)
  &--indentation-default,
  &--indentation-large
    @include tree-list-indentation($fbf-list-item-padding + $fbf-list-item-head-content-width)
  &--indentation-extra-large
    @include tree-list-indentation(72px)

  &--bordered
    .fbf-tree-list__node--branch > &
      border-top-width: 1px
    & > .fbf-tree-list__node:not(:first-child)
      border-top-width: 1px

  &--root#{&}--root-border-top > .fbf-tree-list__node:first-child
    border-top-width: 1px
  &--root#{&}--root-border-bottom > .fbf-tree-list__node:last-child
    border-bottom-width: 1px
