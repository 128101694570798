@import 'landing/mixins'
@import 'landing/static-app-bar'
@import 'landing/floating-app-bar'
@import 'landing/panels/base-panel'
@import 'landing/panels/platform-header-panel'
@import 'landing/panels/extension-header-panel'
@import 'landing/panels/extension-intro-panel'
@import 'landing/panels/knowledge-header-panel'
@import 'landing/panels/didactics-panel'
@import 'landing/panels/guide-panel'
@import 'landing/panels/innovations-panel'
@import 'landing/panels/testimonials-panel'
@import 'landing/panels/faq-panel'
@import 'landing/panels/support-panel'
@import 'landing/panels/extensions-panel'
@import 'landing/panels/learning-activities-panel'
@import 'landing/panels/customers-panel'
@import 'landing/panels/integrations-panel'
@import 'landing/panels/plugins-panel'
@import 'landing/panels/appstore-panel'
@import 'landing/panels/integration-details-panel'
@import 'landing/panels/about-us-panel'
@import 'landing/panels/awards-panel'
@import 'landing/panels/start-creating-panel'
@import 'landing/panels/footer-panel'

.fbf-landing
  @include fbf-layout--flow-down
  @include fbf-layout--scroll-overflow
  height: 100vh

  & > .fbf-card
    display: flex
    flex-direction: column
