@mixin text--red
  @include text--colored($color-red)
.text--red
  @include text--red

@mixin text--red-light
  @include text--colored($color-red-light)
.text--red-light
  @include text--red-light

@mixin text--orange
  @include text--colored($color-orange)
.text--orange
  @include text--orange

@mixin text--blue
  @include text--colored($color-blue)
.text--blue
  @include text--blue

@mixin text--cyan
  @include text--colored($color-cyan)
.text--cyan
  @include text--cyan

@mixin text--green
  @include text--colored($color-green)
.text--green
  @include text--green

@mixin text--purple
  @include text--colored($color-purple)
.text--purple
  @include text--purple

@each $color-name, $color in $colors-dark
  .text--#{$color-name}-dark
    @include text--colored($color)

.text--context
  @each $color-name, $color in $colors
    .context--#{$color-name} &
      color: $color

  @each $color-name, $color in $colors-light
    .context--#{$color-name} .context--dark &
      color: $color

// DEPRECATED: use .context-purple .text--context istead
.context--assignments .text--context-color
  color: $color-purple

.context--cyan .text--context-color
  color: $color-cyan
