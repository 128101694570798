$data-table--border-width: 1px
$data-table--body-border-width: 4px

$data-table--header-height-default: 48px
$data-table--header-height-small: 64px
$data-table--header-height-medium: 80px
$data-table--header-height-large: 96px
$data-table--header-height-huge: 112px

$data-table--header-min-width-small: 80px
$data-table--header-min-width-medium: 112px
$data-table--header-min-width-large: 184px

$data-table--row-height-small: 40px
$data-table--row-height-default: 48px
$data-table--row-height-medium: 64px
$data-table--row-height-large: 80px

$data-table--fixed-max-width-single: 50vw
$data-table--fixed-max-width-both: 33vw

$fixed-block-sizes: (0 1 2 3 4 5 6 7 8 9 10)

$highlighted-cell-opacity: 0.05
$highlighted-cell-opacity--nested-row: 0.12
