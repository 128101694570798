@mixin text--strong
  font-weight: 600 !important
.text--strong
  @include text--strong

@mixin text--medium
  font-weight: 500 !important
.text--medium
  @include text--medium

@mixin text--regular
  font-weight: 400 !important
.text--regular
  @include text--regular

@mixin text--light
  font-weight: 300 !important
.text--light
  @include text--light

@mixin text--thin
  font-weight: 200 !important
.text--thin
  @include text--thin

// DEPRECATED: use .text--regular
.text--medium
  @include text--regular
// DEPRECATED: use .text--thin
.text--more-thin
  @include text--thin
