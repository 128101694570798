.fbf-landing__platform-header-panel
  position: relative

  .fbf-image
    @include fbf-layout--flow-down
    justify-content: flex-start
    height: 100vh
    .fbf-app-bar
      @include fbf-layout--flex-spread

      .fbf-button--outlined
        margin-left: 16px

    .fbf-layout__bg-scrim
      background: $scrim-up-30
      height: 60vh

    .fbf-layout__hero-unit
      @include fbf-layout__center-column
      @include text--centered
      align-items: center
      z-index: 0
      @media (min-height: $desktop-min-height)
        padding-top: 20vh
      .fbf-app-logo
        margin-right: 0
        opacity: 1
        margin-bottom: 24px
      .fbf-button
        margin-top: 10vh

  .fbf-direct-popover.fbf-popover .fbf-popover__arrow
    right: 204px

.fbf-landing__platform-intro
  @include column-card
  @include column-card--above-the-fold
  flex-direction: row
  > div:first-child
    margin-right: 40px
  @media (max-width: $notepad-width-in)
    flex-direction: column
    > div:first-child
      margin-right: 32px
