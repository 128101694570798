$fbf-grid-max-width: 1200px
$fbf-grid-max-width-part: ($fbf-grid-max-width / 12)

%fbf-grid__max-8
  width: 100%
  max-width: ($fbf-grid-max-width-part * 8)

%fbf-grid__max-6
  width: 100%
  max-width: ($fbf-grid-max-width-part * 6)

%fbf-grid__max-4
  width: 100%
  max-width: ($fbf-grid-max-width-part * 4)

@mixin fbf-grid($_cols: 4, $_gutter: 0)
  $_percentage: (1/$_cols) * 100%
  $_gutter_px: $_gutter * 1px

  box-sizing: border-box
  display: flex
  flex-wrap: wrap
  margin-left: (-1 * $_gutter_px)

  > *
    box-sizing: border-box
    width: calc(#{$_percentage} - #{$_gutter_px})
    margin-left: $_gutter_px

@function fbf-grid-multi-cell($_fraction: 1/4, $_gutter: 0)
  @return calc(#{$_fraction * 100%} - #{$_gutter * 1px})
