@import '../platform/layout'

$fbf-well-padding: 36px
$fbf-well-padding-small: 8px

.fbf-well
  background-color: $bg-grey--300
  padding: $fbf-well-padding
  margin-bottom: 24px
  border-radius: 8px
  position: relative

  @media(max-width: $tablet-max-width)
    padding: $fbf-well-padding-small

  @include background-states

  &.background--warning
    background: linear-gradient(to bottom, $color-palette-yellow-900 0%, $color-palette-yellow-900 140px, $bg-grey--100 300px, $bg-grey--100 100%)

.fbf-well--light
  background-color: $bg-grey--100

.fbf-well__header-outside
  padding: $fbf-well-padding

.fbf-well--small__header-outside
  display: flex
  margin: $fbf-well-padding-small

.fbf-well__notes
  // TODO

.fbf-well--small
  margin: 0
  padding: $fbf-well-padding-small

  .fbf-card
    margin-bottom: $fbf-well-padding-small

  .fbf-well__notes
    @include fbf-layout--flow-down
    padding: $fbf-well-padding-small
    padding-bottom: 2 * $fbf-well-padding-small

.fbf-well__ruleset
  max-width: 90%

  & > span
    line-height: 32px

  .mdl-textfield
    margin-left: 16px
