.fbf-image
  overflow: hidden
  width: 100%
  height: 100%
  display: flex
  position: relative
  justify-content: center
  align-items: center
  background-size: cover
  background-position: center center
  flex-direction: column

.fbf-image--filter-grayscale
  filter: grayscale(100%)

.fbf-image--filter-avatar
  filter: grayscale(100%)
  // disabled due to browser bug that makes this break border radius rendering
  // transition: 0.2s filter

  &:hover
    filter: initial

.fbf-image--circle
  border-radius: 50%

.fbf-image--absolute
  position: absolute
  top: 0
  left: 0

.fbf-image--full-width
  width: 100%

.fbf-image--contain
  background-size: contain
  background-repeat: no-repeat
