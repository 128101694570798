.fbf-options-field
  .fbf-text-field
    &.is-enabled, &.is-enabled .fbf-text-field__input
      cursor: pointer
    &.is-disabled .fbf-text-field__input-segment
      @include caret-disabled

  .fbf-text-field__input-segment
    @include caret
    &::after
      margin-right: 16px
      margin-left: 0
      pointer-events: none
  .fbf-text-field.is-dirty .fbf-text-field__input-segment::after
    margin-top: 16px
