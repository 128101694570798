.fbf-landing__floating-app-bar.fbf-app-bar
  background-color: white
  position: fixed
  top: 0
  transition-property: top
  @include material-animation-default
  .fbf-tool-bar
    height: auto
    padding-right: 0
    .fbf-activity-icon
      margin-right: 8px
    h5, .text--h5
      flex-grow: 0
      flex-shrink: 0
    .fbf-app-logo
      margin-left: 16px
    .fbf-button
      border-radius: 0

.fbf-landing__floating-app-bar--hidden.fbf-app-bar
  top: -68px
  @media(max-width: $phone-max-width), (max-height: $phone-max-height)
    top: -36px
