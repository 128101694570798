.fbf-meta-bar
  box-sizing: border-box
  display: flex
  min-height: 56px
  padding-left: 18px
  width: 100%

@media(max-width: $tablet-max-width)
  .fbf-activity-list-item .fbf-meta-bar
    padding-left: 58px

.fbf-sidebar .fbf-activity-list-item
  .fbf-meta-bar
    padding-left: 58px
  .fbf-list-item__lines .fbf-meta-bar
    padding-left: 0

.fbf-meta-bar.fbf-meta-bar--inline
  margin-left: -6px
  min-height: initial
  padding-left: 0
