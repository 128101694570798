.fbf-grid
  @media (max-width: $tablet-max-width)
    .fbf-dialog__content .fbf-bottom-sheet-item
      flex-grow: 1
      max-width: 168px

.fbf-grid--2c
  @include fbf-grid(2, 16)

.fbf-grid--3c
  @include fbf-grid(3, 16)

.fbf-grid--5c
  @include fbf-grid(5, 16)

  .fbf-grid--5c__4c
    width: fbf-grid-multi-cell(4/5, 16)

.fbf-planning-grid
  display: flex
  flex-flow: row wrap

  > section
    min-width: 300px
    width: 100%
    padding: 8px
    box-sizing: border-box

    @media (min-width: 616px)
      width: 50%

      .fbf-well:first-of-type
        min-height: 440px

    @media (min-width: 916px)
      width: 33.33%

    @media (min-width: 1216px)
      width: 25%
