.fbf-notifications__action-bar-item
  height: 32px
  padding-left: 8px
  margin-left: 8px
  border-radius: 16px
  padding-right: 8px
  min-width: auto
  &.fbf-button--flat .fbf-icon
    margin-right: -4px
    margin-left: -4px

.fbf-notifications__popover
  position: fixed
  top: 56px
  right: 18px
  width: 392px
  max-height: calc(100vh - 88px)
  transform-origin: top right

  .fbf-popover__arrow
    right: 6px
    left: auto !important

  .fbf-list
    margin-top: 16px
    margin-bottom: 16px

.fbf-notifications__popover--has-notifications
  .fbf-popover__arrow
    right: 36px

.fbf-notifications__popover--has-many-notifications
  .fbf-popover__arrow
    right: 43px

.fbf-notifications__popover--offset
  .fbf-popover__arrow
    right: 46px

  &.fbf-notifications__popover--has-notifications
    .fbf-popover__arrow
      right: 81px

  &.fbf-notifications__popover--has-many-notifications
    .fbf-popover__arrow
      right: 88px

.fbf-notification-list-item
  position: relative

  .fbf-notification-list-item__details
    @include text--truncate
    @include fbf-layout--flex-spread
    @include text--muted-more

    span
      flex-shrink: 0
      align-self: center
      &:nth-child(2)
        margin-left: 4px

.fbf-notification-list-item__deadline-icon
  display: flex
  width: 40px
  height: 40px
  color: white
  background-color: $color-red-dark
  border-radius: 50%

.fbf-notification-list-item--unread::before
  width: 4px
  background-color: $color-blue
  position: absolute
  content: ''
  top: 0
  left: 0
  bottom: 0

// TODO: remove once we ditch old navbar architecture
.fbf-notifications__popover
  color: black
