.context--cyan .activity-view--presentation__landing
  background-color: $color-cyan

.activity-view--presentation__landing
  @include fbf-layout--flex-center
  padding: 0 48px
  @media(max-width: $desktop-max-width), (max-height: $desktop-max-height)
    padding: 0 40px
  @media(max-width: $tablet-max-width), (max-height: $tablet-max-height)
    padding: 0 32px
  @media(max-width: $phone-max-width), (max-height: $phone-max-height)
    padding: 0 16px

  .fbf-layout--flow-down.fbf-layout--flex-center
    flex-shrink: 0

  .fbf-profile-picture
    margin-bottom: -16px
    align-self: center

  .sharing-url
    margin-bottom: 8vh

  .fbf-button--size-large
    margin-top: 24px
    @media(min-height: $tablet-min-height)
      margin-top: 32px
    @media(min-height: $desktop-min-height)
      margin-top: 8vh
    // To make sure it catches on to the 'text--centered' rules and aligns itself
    display: inline-flex

.activity-view--presentation__landing__goodbye
  @include fbf-layout--flow-right
  @include fade--in-delayed
  margin-top: 24px
