@import '../components/fbf-dialog'

@mixin comments-list-at-depth($depth)
  > .fbf-comments-inline-list-item:last-child
    > .fbf-comments-inline-list-item__replies--depth-#{$depth + 1}
      border-bottom: 1px solid $color-palette-grey-300
      margin: 0
  @if $depth > 1
    padding: 8px 0 0 0

    .context--beamer &
      padding: 0

.fbf-comments-list
  @include comments-list-at-depth(1)
  background-color: white
  margin: 0
  padding: 8px 0

  &.fbf-comments-list--depth-2
    @include comments-list-at-depth(2)
  &.fbf-comments-list--depth-3
    @include comments-list-at-depth(3)
  &.fbf-comments-list--no-padding
    padding: 0

// For dialog hosted flat discussion
.fbf-dialog__content .fbf-comments-list--depth-1
  padding: 0
  border: none
  margin-left: -$dialog-padding
  margin-right: -$dialog-padding

  .fbf-dialog--fullscreen &, .fbf-dialog--force-fullscreen &
    margin: 0

.fbf-activity-list-item
  .fbf-comments-list--depth-1
    border-top: 1px solid $color-palette-grey-300
    padding-bottom: 0

    > .fbf-comments-inline-list-item:first-child
      padding-top: 8px
