.fbf-docked-chat
  .fbf-docked-dialog__header
    height: 64px

    .fbf-profile-picture
      margin-right: 8px

    .fbf-text.mdl-typography--title
      margin-bottom: 4px

  .fbf-tab-bar
    height: 48px
    overflow: hidden
    transition: height 0.2s

    .fbf-tab-bar-item
      flex-direction: row

      .fbf-badge
        margin-left: 8px
        margin-top: 12px

  &.fbf-docked-dialog--fullscreen
    .fbf-docked-dialog__content
      height: calc(100% - 64px)

  &.fbf-docked-dialog--collapsed
    width: 270px

  &.fbf-docked-dialog--expanded
    width: 360px

  .fbf-docked-dialog__content
    height: 432px

    .fbf-list .fbf-list-item
      cursor: pointer

    .fbf-comments-list
      flex-grow: 1
      @include fbf-layout--scroll-overflow

      .fbf-item__content .fbf-text
        word-break: break-word

      .fbf-badge
        margin-right: 8px
        align-self: flex-start

    .fbf-comments-new-inline
      flex-shrink: 0
