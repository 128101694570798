.text-placeholder
  background-color: transparentize(black, 0.9)
  color: transparent
  display: inline-flex

h4.text-placeholder
  border-radius: 14px
  margin: 4px 0
  line-height: 28px

h6.text-placeholder
  border-radius: 10px
  margin: 4px 0
  line-height: 20px

p.text-placeholder
  border-radius: 8px
  margin: 4px 0
  line-height: 16px
