@keyframes enterMainColumn
  from
    opacity: 0
    transform: translateY(72px)

  50%
    opacity: 0
    transform: translateY(72px)

  to
    opacity: 1
    transform: translateY(0)

.homescreen
  @include fbf-layout--flow-down
  @include fbf-layout__fullscreen-page
  justify-content: initial
  align-items: initial

  &__container
    @include fbf-layout__column-container
    @include fbf-layout__column-container--padding
    width: 100%
    height: 100%

  &__column
    @include fbf-layout__center-column
    animation-name: enterMainColumn
    animation-duration: 0.6s

    .fbf-activity-list-item
      margin-bottom: 0

    .fbf-activity-list-item + .fbf-activity-list-item
      margin-top: 8px

  .fbf-app-bar
    transition-property: padding-bottom, background-color
    transition-duration: 0.2s

  & > .fbf-image
    background-image: $gradient-platform
    @include fbf-layout--stretch

    .fbf-app-bar
      padding-bottom: $scrim-height-boost // For smoother scrim
