.fbf-data-table
  @include fbf-layout--flow-right
  position: relative

  &__container
    @include fbf-layout--scroll-overflow
    overflow-y: hidden
    width: 100%

    // iOS adds a z-index 0 to overflowable elements, causing the table to be messed up.
    // Until there is a fix for this, disable manual scrolling in a table.
    .browser-ios &
      overflow: hidden

    & + &
      margin-top: 24px

  &__table
    min-width: 100%
    width: 100%
    border-collapse: collapse

  &__table &__fixed-spanner
    @include fbf-layout--flow-right
    position: absolute

  &__row
    border: 0 solid $bg-grey
    border-bottom-width: $data-table--border-width

    // Background colors, needs more specific selectors to overcome selector in fbf-card
    .fbf-card &
      background-color: transparent
    &#{&}--nested
      background-color: $bg-grey--50
    &#{&}--summary
      background-color: $bg-grey--100

    // States
    &#{&}--actionable
      @include fbf-layout--actionable
    &#{&}--selected
      @include fbf-layout--selected
      border-color: $bg-grey--300

    // Border colors
    &--bordered .fbf-data-table__section + .fbf-data-table__section
      border-left-width: $data-table--border-width
      &::after
        left: -$data-table--border-width
    &--bordered .fbf-data-table__section.border-right + .fbf-data-table__section
      border-left-width: 0
      &::after
        left: 0

    .bg-grey--100 &
      border-color: $bg-grey--300

  &--loading
    opacity: 0.6

  &#{&}--no-borders &__table
    border-collapse: separate

.fbf-data-table__overflow-button
  @include appear
  @include appear--invisible
  position: absolute
  z-index: 1
  display: flex
  transform: scale(0.5)

  &.fbf-data-table__overflow-button--visible
    @include appear--visible

.fbf-data-table__fixed-spanner
  width: 0

  &.fbf-data-table__fixed-spanner--left
    justify-content: flex-end
    left: 0

    .fbf-data-table__overflow-button
      right: 0
      margin-right: -50px
      .fbf-data-table--has-fixed-left &
        margin-right: -20px

  &.fbf-data-table__fixed-spanner--right
    right: 0

    .fbf-data-table__overflow-button
      margin-left: -50px
      .fbf-data-table--has-fixed-right &
        margin-left: -20px
