// Semantic gradients
.bg-gradient-warning
  background-image: $gradient-warning
.bg-gradient-error
  background-image: $gradient-error
.bg-gradient-notice
  background-image: $gradient-notice

// Utility gradients
.bg-gradient-platform
  background-image: $gradient-platform
.bg-gradient-media-placeholder
  background-image: $gradient-media-placeholder

// Context color gradients
.bg-gradient-purple
  background-image: $gradient-purple
.bg-gradient-blue
  background-image: $gradient-blue
.bg-gradient-red
  background-image: $gradient-red
.bg-gradient-yellow
  background-image: $gradient-yellow
.bg-gradient-cyan
  background-image: $gradient-cyan
.bg-gradient-brown
  background-image: $gradient-brown
.bg-gradient-blue-grey
  background-image: $gradient-blue-grey
.bg-gradient-green
  background-image: $gradient-green


.scrim-up, .scrim-up--100
  background-image: $scrim-up-100
.scrim-up--80
  background-image: $scrim-up-80
.scrim-up--50
  background-image: $scrim-up-50
.scrim-up--30
  background-image: $scrim-up-30
.scrim-up--10
  background-image: $scrim-up-10
.scrim-up--0
  background-image: transparent

.scrim-down, .scrim-down--100
  background-image: $scrim-down-100
.scrim-down--80
  background-image: $scrim-down-80
.scrim-down--50
  background-image: $scrim-down-50
.scrim-down--30
  background-image: $scrim-down-30
.scrim-down--10
  background-image: $scrim-down-10
.scrim-down--0
  background-image: transparent

.scrim-white-up, .scrim-white-up--100
  background-image: $scrim-white-up-100
.scrim-white-up--80
  background-image: $scrim-white-up-80
.scrim-white-up--50
  background-image: $scrim-white-up-50
.scrim-white-up--30
  background-image: $scrim-white-up-30
.scrim-white-up--10
  background-image: $scrim-white-up-10
.scrim-white-up--0
  background-image: transparent

.scrim-white-down, .scrim-white-down--100
  background-image: $scrim-white-down-100
.scrim-white-down--80
  background-image: $scrim-white-down-80
.scrim-white-down--50
  background-image: $scrim-white-down-50
.scrim-white-down--30
  background-image: $scrim-white-down-30
.scrim-white-down--10
  background-image: $scrim-white-down-10
.scrim-white-down--0
  background-image: transparent
