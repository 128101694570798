.fbf-page .fbf-sidebar
  @include fbf-layout--flow-down
  position: relative
  background: $bg-grey--300
  z-index: $z-sidebar

  &__activities.is-hidden
    display: none

  .fbf-page
    flex-grow: 1

  .fbf-page__main
    position: relative
    flex-grow: 1

    &.fbf-page__main--padded
      @include fbf-layout__column-container--padding-sidebar

  // TODO: remove these fbf-detail-view styles once the fbf-page
  // classes are adopted everywhere, making them superfluous.
  .fbf-detail-view.fbf-detail-view
    @include fbf-layout--fullheight
    @include fbf-layout--fullwidth
    position: relative
    background: transparent
    border: none
    flex-grow: 1

  .fbf-detail-view__container,
  .fbf-detail-view__content.fbf-detail-view__content.fbf-detail-view__content
    @include fbf-layout__column-container--padding-sidebar
    position: relative
    flex-grow: 1

  // ---------------------------
  // LARGE SCREENS
  // ---------------------------
  @include mq-gt-desktop
    $sidebar-width: 420px

    @include fbf-layout--scroll-overflow
    @include shadow-4dp
    min-width: $sidebar-width
    max-width: $sidebar-width
    transition: margin-right 0.2s cubic-bezier(0.4, 0, 0.2, 1)

    &.fbf-sidebar--hidden
      margin-right: -$sidebar-width
      visibility: hidden

    .fbf-page__main
      @include fbf-layout--scroll-overflow

    .fbf-detail-view__container.fbf-detail-view__container
      @include fbf-layout--scroll-overflow
      @include fbf-layout--flow-down

  // ---------------------------
  // SMALL SCREENS
  // ---------------------------
  @include mq-in-desktop
    min-height: 100%

    &.fbf-sidebar--hidden
      min-height: 0
      max-height: 0
      visibility: hidden

    .fbf-detail-view__container,
    .fbf-page__main
      @include fbf-layout__center-column
      @include fbf-layout__center-column--medium-width
      overflow: hidden
