// DEPRECATED WARNING: many of the structure classes here have been deprecated
// by the more modern and general `fbf-list` and `fbf-list-item`. Please be
// advised and leverage their features foremost, and supplement with
// `activity-card` specific classes after.

.fbf-activity-card
  display: flex

.fbf-activity-card__content
  display: flex

.fbf-activity-card__primary
  display: flex
  flex-direction: column
  flex-grow: 1
  min-width: 1px

  .fbf-activity-options-menu
    align-self: center

  .fbf-menu
    display: flex
    align-items: center
    margin-right: 16px
    position: relative

// DEPRECATED: use `.fbf-list-item` instead
.fbf-activity-card__title-bar
  display: flex
  flex-direction: row

  // DEPRECATED: use `.fbf-list-item > .fbf-list-item__lines` instead
.fbf-activity-card__labels
  display: flex
  flex-grow: 1
  flex-direction: column
  padding-top: 16px
  padding-bottom: 16px
  transition: padding-left 200ms
  padding-left: 16px
  min-width: 1px
  @media(min-width: $desktop-layout-min-width + 1)
    padding-left: 24px
  padding-right: 16px

.fbf-activity-card--tiny
  .fbf-activity-card__labels
    flex-direction: row

  .fbf-text--title, h6
    flex-grow: 3

  .fbf-activity-card__labels .fbf-meta-text
    margin-left: 16px
    text-align: right
    flex-grow: 1
    min-width: 25%
    display: inline

.fbf-activity-card--tiny, .fbf-activity-card--small
  .fbf-card-container__glance
    display: none

  .fbf-meta-bar
    display: none

.fbf-activity-card--medium
  .fbf-activity-card__primary
    flex-direction: column

  .fbf-card-container__glance
    flex-basis: 200px

.fbf-activity-card--tiny, .fbf-activity-card--small, .fbf-activity-card--medium
  .fbf-activity-card__hero
    display: none

.fbf-activity-card--medium, .fbf-activity-card--large
  // Extra container to facilitate layout with deadline
  .fbf-activity-card__content
    display: flex
    flex-direction: row
    flex-grow: 1
    min-width: 1px

  .fbf-activity-card__labels
    padding-bottom: 0

  .fbf-card-container__glance .fbf-image, .fbf-activity-card__hero .fbf-image
    background-image: $gradient-media-placeholder

  .fbf-image__src
    display: flex
    align-items: center
    justify-content: center

.fbf-activity-card--large
  flex-direction: column

  .fbf-activity-card__content
    flex-direction: column
    align-items: stretch

  .fbf-activity-card__meta
    display: flex
    flex-grow: 1
    flex-direction: column

.fbf-activity-card__hero
  position: relative
  width: 100%
  height: 200px
  display: flex
  flex-wrap: wrap
  justify-content: flex-end
  border-radius: 4px 4px 0 0
  overflow: hidden

  .fbf-text--title, h6
    color: white
    background: $scrim-up
    align-self: flex-end
    position: relative
    width: 100%
    line-height: 40px
    padding-top: 16px
    padding-right: 8px
    padding-left: 16px
    transition: padding-left 200ms
    @media(min-width: $desktop-layout-min-width + 1)
      padding-left: 24px

  .fbf-menu
    position: relative
    margin: inherit
    height: 32px
    padding: 8px 8px 32px 32px
    background: linear-gradient(to top right, transparent 50%, black 100%)
    .icon
      color: white

// DEPRECATED: use `.fbf-list-item__tail` instead
.fbf-activity-card__addon
  display: flex
  align-items: center
  justify-content: center
  align-self: stretch
  padding: 8px 24px

  .fbf-icon + p, .fbf-icon + small
    margin-left: 8px
    flex-shrink: 0

// DEPRECATED: use `.fbf-list-item__tail.fbf-list-item__tail--grey` instead
.fbf-activity-card__deadline
  background-color: $color-palette-grey-200

// DEPRECATED: use `.fbf-list-item__tail.status-urgent` instead
.fbf-activity-card__time-left
  display: flex
  flex-direction: row
  margin-right: 8px

  .fbf-icon, .fbf-text, p
    color: $color-red

// DEPRECATED: compose using only `.fbf-list-item`
.fbf-activity-card__title-bar + a > .fbf-list.fbf-activity-card__tasks
  margin-top: 16px

.fbf-list.fbf-activity-card__tasks // specificity
  .fbf-activity-icon-plain
    width: 32px
    height: 32px
    margin: 16px
    text
      font-weight: 600
