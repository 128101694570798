$browser-icon-size: 128px

.fbf-browser-notice__header
  background-color: $color-primary-light
  width: 100%
  padding-bottom: 150px

.fbf-browser-notice__header__title
  margin-top: 75px

.fbf-browser-notice__header__subtitle
  margin-top: 30px
  width: 550px

.fbf-browser-notice__content
  margin-top: -$browser-icon-size / 2

.fbf-browser-notice__browser-icon
  width: $browser-icon-size
  height: $browser-icon-size

.fbf-browser-notice__list
  > li
    list-style: none
    display: flex
    margin-bottom: 4px

    > .fbf-icon
      margin-right: 8px

.fbf-browser-notice__browser-header
  margin-top: 8px

.fbf-browser-notice__filler-icon
  visibility: hidden
