.fbf-group-list
  &--root .fbf-group-list-item--course > .fbf-list-item
    & > .fbf-list-item__lines
      margin: 0
      & > *
        @include fbf-layout--flow-right
        width: 100%
        box-sizing: border-box
        padding: 12px 16px
      & > * + span
        padding-top: 8px

    .fbf-navigation-drawer__menu &
      padding-left: 8px

  &--root .fbf-group-list-item--course
    min-height: 64px

    & > .fbf-list-item
      flex-grow: 1

  &--root .fbf-card
    margin-bottom: 0

  &--root li + li
    padding-top: 8px

  &-item__main
    @include fbf-layout--actionable

    &:focus, &:hover
      background-color: transparentize(black, 0.9)

  #{&}
    padding-top: 0
    padding-left: 32px

    .fbf-group-list-item > .fbf-list-item
      > .fbf-list-item__lines
        margin: 8px 0 8px 16px

      > .fbf-list-item__tail
        margin: 8px 16px 8px 0
