$question-media-preview-width-right: 33%
$question-media-preview-height-bottom: 120px

@mixin attachment-position-right
  width: $question-media-preview-width-right
  height: auto
  align-self: stretch

@mixin attachment-position-bottom
  height: $question-media-preview-height-bottom
  width: auto

.fbf-question__header
  @include fbf-layout--flow-down
  position: relative
  flex-shrink: 0

  // Make place for possible image attachments
  &__column
    @include fbf-layout__center-column
    @include fbf-layout__center-column--large-width
    flex-direction: row

    .media-preview
      @include attachment-position-right

    .fbf-question--attachment-position-bottom &
      flex-direction: column

      .media-preview
        @include attachment-position-bottom

  &__config
    @include fbf-layout--flow-right
    justify-content: flex-end
    & > .fbf-timer, & > .fbf-chip
      margin-left: 16px

  &__info-label
    @include fbf-layout--flow-right
    flex-shrink: 0

    & + &
      margin-left: 8px

    & > .fbf-icon
      margin-right: 4px

  .content-padding-md
    @include fbf-layout--flow-down
    @include fbf-layout--fullwidth
    box-sizing: border-box
    align-self: flex-start
    flex-basis: calc(100% - #{$question-media-preview-width-right})
    flex-grow: 1

    @include mq-in-phone-or-sidebar
      flex-basis: auto

    .markdown
      ul, ol
        margin-top: 0.5em
        margin-bottom: 0.5em

    .fbf-rich-input
      // We already have padding from `.content-padding-md` so
      // we'll disable it here once per exception
      .fbf-list-item__lines
        margin: 0

  .fbf-timer
    margin-right: -8px
    margin-top: -8px
    margin-bottom: -8px

  .fbf-mini-progress-bar
    margin-top: 0
    margin-bottom: 0

  .fbf-rich-input__attachment-button
    margin-left: 24px
