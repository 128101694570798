.fbf-inline-drop-target
  border: 2px dashed $color-palette-grey-300
  background-color: $bg-grey--50
  border-radius: 16px
  display: flex
  flex-direction: column
  padding: 32px

  &.fbf-inline-drop-target--active
    border-color: $color-blue

  &__label
    text-align: center
    margin: 0 0 32px

  & > .fbf-button
    margin-top: 32px
    margin-bottom: 32px
    &:first-child
      margin-top: 0
    @media(max-width: $tablet-max-width)
      margin-bottom: 16px

  & > .fbf-media-from-link
    width: 100%
    max-width: 300px

  @media(max-width: $tablet-max-width)
    padding: 16px

.fbf-inline-drop-target__extras
  display: flex
  justify-content: center
  flex-direction: column
  margin-top: 16px

.fbf-inline-drop-target__grace
  margin-bottom: 16px
  display: flex
  justify-content: center
  .fbf-icon
    margin-right: 8px

.fbf-inline-drop-target__or-line
  @include fbf-layout--flow-right
  width: 100%
  max-width: 300px

  > p
    @include text-dark--muted-more
    padding-left: 16px
    padding-right: 16px

  > hr
    border-top: 1px solid $color-palette-grey-400
    flex-grow: 1
