.settingsscreen
  @include fbf-layout--flow-down
  @include fbf-layout__fullscreen-page

  &__container
    @include fbf-layout__column-container
    @include fbf-layout__column-container--padding

  &__content
    @include fbf-card--base
    @include content-padding-md
    @include fbf-layout__center-column
    border-radius: 4px

    @media (max-width: $notepad-width-out)
      padding: 0
