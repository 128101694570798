@import 'fbf-linear-bar'

@mixin mini-progress-bar-color-variant($color)
  $color-progress: $color
  $color-buffer: transparentize($color, 0.8)
  $color-background: transparentize($color, 0.9)

  .fbf-mini-progress-bar__progress,
  .fbf-mini-progress-bar__bar1,
  .fbf-mini-progress-bar__bar2
    background-color: $color-progress
  .fbf-mini-progress-bar__buffer
    background-color: $color-buffer
  .fbf-mini-progress-bar__background
    background-color: $color-background

.fbf-mini-progress-bar
  @include linear-bar
  @include mini-progress-bar-color-variant($color-accent)
  background-color: transparent

  .fbf-mini-progress-bar__bar
    @include linear-bar__interval
    position: absolute

  .fbf-mini-progress-bar__progress,
  .fbf-mini-progress-bar__bar1,
  .fbf-mini-progress-bar__bar2
    z-index: 1
    left: 0
  .fbf-mini-progress-bar__buffer
    z-index: 0
    left: 0
  .fbf-mini-progress-bar__background
    left: 0
    width: 100%

  .context--dark &
    @include mini-progress-bar-color-variant(white)

  @each $color-name, $color in $colors
    .context--#{$color-name} &,
    &.color-#{$color-name}.color-#{$color-name}
      @include mini-progress-bar-color-variant($color)


.fbf-mini-progress-bar--indeterminate
  .fbf-mini-progress-bar__buffer
    width: 100%
  .fbf-mini-progress-bar__bar1
    animation-name: indeterminate1
    animation-duration: 2s
    animation-iteration-count: infinite
    animation-timing-function: linear
  .fbf-mini-progress-bar__bar2
    animation-name: indeterminate2
    animation-duration: 2s
    animation-iteration-count: infinite
    animation-timing-function: linear

@keyframes indeterminate1
  0%
    left: 0%
    width: 0%

  50%
    left: 25%
    width: 75%

  75%
    left: 100%
    width: 0%

  100%
    left: 100%
    width: 0%

@keyframes indeterminate2
  0%
    left: 0%
    width: 0%

  50%
    left: 0%
    width: 0%

  75%
    left: 0%
    width: 25%

  100%
    left: 100%
    width: 0%
