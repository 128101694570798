$spinner-size: 28px !default
$spinner-stroke-width: 3px !default

// Amount of circle the arc takes up.
$spinner-arc-size: 270deg !default
// Time it takes to expand and contract arc.
$spinner-arc-time: 1333ms !default
// How much the start location of the arc should rotate each time.
$spinner-arc-start-rot: 216deg !default

$spinner-duration: 1.568s

.fbf-spinner
  margin-top: 16px
  .fbf-spinner__layer
    border-color: $color-primary

.context--dark .fbf-spinner
  .fbf-spinner__layer
    border-color: white

.fbf-spinner
  display: inline-block
  position: relative
  width: $spinner-size
  height: $spinner-size

  &.is-active
    animation: fbf-spinner__container-rotate $spinner-duration linear infinite

@keyframes fbf-spinner__container-rotate
  to
    transform: rotate(360deg)

.fbf-spinner__layer
  position: absolute
  width: 100%
  height: 100%
  opacity: 0

.fbf-spinner__layer-1
  .fbf-spinner.is-active &
    animation: fbf-spinner__fill-unfill-rotate (4 * $spinner-arc-time) $animation-curve-fast-out-slow-in infinite both, fbf-spinner__layer-1-fade-in-out (4 * $spinner-arc-time) $animation-curve-fast-out-slow-in infinite both

.fbf-spinner__layer-2
  .fbf-spinner.is-active &
    animation: fbf-spinner__fill-unfill-rotate (4 * $spinner-arc-time) $animation-curve-fast-out-slow-in infinite both, fbf-spinner__layer-2-fade-in-out (4 * $spinner-arc-time) $animation-curve-fast-out-slow-in infinite both

.fbf-spinner__layer-3
  .fbf-spinner.is-active &
    animation: fbf-spinner__fill-unfill-rotate (4 * $spinner-arc-time) $animation-curve-fast-out-slow-in infinite both, fbf-spinner__layer-3-fade-in-out (4 * $spinner-arc-time) $animation-curve-fast-out-slow-in infinite both

.fbf-spinner__layer-4
  .fbf-spinner.is-active &
    animation: fbf-spinner__fill-unfill-rotate (4 * $spinner-arc-time) $animation-curve-fast-out-slow-in infinite both, fbf-spinner__layer-4-fade-in-out (4 * $spinner-arc-time) $animation-curve-fast-out-slow-in infinite both

@keyframes fbf-spinner__fill-unfill-rotate
  12.5%
    transform: rotate(0.5 * $spinner-arc-size)
  25%
    transform: rotate($spinner-arc-size)
  37.5%
    transform: rotate(1.5 * $spinner-arc-size)
  50%
    transform: rotate(2 * $spinner-arc-size)
  62.5%
    transform: rotate(2.5 * $spinner-arc-size)
  75%
    transform: rotate(3 * $spinner-arc-size)
  87.5%
    transform: rotate(3.5 * $spinner-arc-size)
  to
    transform: rotate(4 * $spinner-arc-size)

// HACK: Even though the intention is to have the current .fbf-spinner__layer-N
// at `opacity: 1`, we set it to `opacity: 0.99` instead since this forces Chrome
// to do proper subpixel rendering for the elements being animated. This is
// especially visible in Chrome 39 on Ubuntu 14.04. See:
//
// - https://github.com/Polymer/paper-spinner/issues/9
// - https://code.google.com/p/chromium/issues/detail?id=436255
@keyframes fbf-spinner__layer-1-fade-in-out
  from
    opacity: 0.99
  25%
    opacity: 0.99
  26%
    opacity: 0
  89%
    opacity: 0
  90%
    opacity: 0.99
  100%
    opacity: 0.99

@keyframes fbf-spinner__layer-2-fade-in-out
  from
    opacity: 0
  15%
    opacity: 0
  25%
    opacity: 0.99
  50%
    opacity: 0.99
  51%
    opacity: 0

@keyframes fbf-spinner__layer-3-fade-in-out
  from
    opacity: 0
  40%
    opacity: 0
  50%
    opacity: 0.99
  75%
    opacity: 0.99
  76%
    opacity: 0

@keyframes fbf-spinner__layer-4-fade-in-out
  from
    opacity: 0
  65%
    opacity: 0
  75%
    opacity: 0.99
  90%
    opacity: 0.99
  100%
    opacity: 0

// Patch the gap that appear between the two adjacent
// div.fbf-spinner__circle-clipper while the spinner is rotating
// (appears on Chrome 38, Safari 7.1, and IE 11).
//
// Update: the gap no longer appears on Chrome when .fbf-spinner__layer-N's
// opacity is 0.99, but still does on Safari and IE.
.fbf-spinner__gap-patch
  position: absolute
  box-sizing: border-box
  top: 0
  left: 45%
  width: 10%
  height: 100%
  overflow: hidden
  border-color: inherit

  & .fbf-spinner__circle
    width: 1000%
    left: -450%

.fbf-spinner__circle-clipper
  display: inline-block
  position: relative
  width: 50%
  height: 100%
  overflow: hidden
  border-color: inherit

  &.fbf-spinner__left
    float: left

  &.fbf-spinner__right
    float: right

  & .fbf-spinner__circle
    width: 200%

.fbf-spinner__circle
  box-sizing: border-box
  height: 100%
  border-width: $spinner-stroke-width
  border-style: solid
  border-color: inherit
  border-bottom-color: transparent !important
  border-radius: 50%
  animation: none

  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0

  .fbf-spinner__left &
    border-right-color: transparent !important
    transform: rotate(129deg)

    .fbf-spinner.is-active &
      animation: fbf-spinner__left-spin $spinner-arc-time $animation-curve-fast-out-slow-in infinite both

  .fbf-spinner__right &
    left: -100%
    border-left-color: transparent !important
    transform: rotate(-129deg)

    .fbf-spinner.is-active &
      animation: fbf-spinner__right-spin $spinner-arc-time $animation-curve-fast-out-slow-in infinite both

@keyframes fbf-spinner__left-spin
  from
    transform: rotate(130deg)
  50%
    transform: rotate(-5deg)
  to
    transform: rotate(130deg)

@keyframes fbf-spinner__right-spin
  from
    transform: rotate(-130deg)
  50%
    transform: rotate(5deg)
  to
    transform: rotate(-130deg)

// To make use of this, set the spinner's container to position: relative
.fbf-spinner--center-absolute
  display: block
  position: absolute
  top: calc(50% - #{$spinner-size / 2})
  left: calc(50% - #{$spinner-size / 2})

.fbf-layout--flow-right
  .fbf-spinner
    margin-top: auto
    margin-right: 8px

// DEPRECATED: use generic layout classes
.fbf-spinner--center-horizontal
  display: block
  margin: 0 auto
