.fbf-app-logo
  font-family: $logo-fontstack
  font-size: 20px
  font-weight: 300 !important
  display: flex
  line-height: 26px
  opacity: 0.5
  position: relative // Fix for Chrome layout glitch
  transition-property: opacity
  transition-duration: 0.2s
  margin-right: 1em
  strong
    font-weight: 500 !important
  .fbf-svg
    width: 28px
    height: 28px
    margin-right: 8px

  &:hover
    opacity: 1
