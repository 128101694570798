.fbf-activity-icon-plain
  flex-shrink: 0
  color: white
  font-size: 24px
  display: flex
  align-items: center
  justify-content: center

  text
    font-family: $roboto-family

.fbf-activity-icon-plain--small-text
  font-size: 14px
  font-weight: 300

// Colors
@mixin activity-icon-plain-color-variant($color)
  .fill
    fill: $color

@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-activity-icon-plain
    @include activity-icon-plain-color-variant($color)

.fbf-activity-list-item--debate-map .fbf-activity-icon-plain
  @include activity-icon-plain-color-variant($color-green)

.fbf-activity-list-item--document .fbf-activity-icon-plain
  @include activity-icon-plain-color-variant($color-blue)

.fbf-activity-list-item--video .fbf-activity-icon-plain
  @include activity-icon-plain-color-variant($color-red)

.fbf-activity-list-item--assignment .fbf-activity-icon-plain
  @include activity-icon-plain-color-variant($color-purple)

.fbf-activity-list-item--question .fbf-activity-icon-plain
  @include activity-icon-plain-color-variant($color-teal)

.fbf-activity-list-item--presentation .fbf-activity-icon-plain
  @include activity-icon-plain-color-variant($color-cyan)

.fbf-activity-list-item--audio .fbf-activity-icon-plain
  @include activity-icon-plain-color-variant($color-brown)
