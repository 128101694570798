.fbf-landing__static-app-bar
  @include fbf-layout--flex-spread
  .fbf-app-logo
    @media (max-width: $notepad-width-in)
      margin-right: 0
    @media (max-width: $phone-max-width)
      display: none
  a.active .fbf-app-logo
    opacity: 1
  .fbf-button--outlined
    margin-left: 16px
    // flex-direction: r
  @media(max-width: $tablet-max-width + 40px)
    .fbf-landing__nav-button
      display: none
  @media(min-width: $desktop-min-width + 40px)
    .fbf-landing__nav-drawer-button
      display: none
