.fbf-folder
  & > * > .fbf-folder__header
    @include shadow-2dp
    flex: auto
    min-width: 1px
    @media(min-width: $tablet-min-width)
      border-radius: 2px

    .fbf-list-item__lines
      height: 36px

  & > * > .fbf-activity-icon
    @include fbf-layout__activity-icon
    margin-top: 4px
    margin-bottom: 4px

  &#{&}--root
    .fbf-well
      border-top-left-radius: 0
      border-top-right-radius: 0
      padding-left: 4px !important

      @media(max-width: $tablet-max-width)
        padding: 24px

      .fbf-folder .fbf-well
        margin-left: 72px
        border: 1px solid $color-palette-grey-400

  &#{&}--expanded
    > * > .fbf-folder__header
      border-top-left-radius: 8px
      border-top-right-radius: 8px

  &#{&}--collapsed
    > *
      align-items: flex-start !important
