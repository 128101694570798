@import 'fbf-toggle/variables'
@import 'fbf-toggle/mixins'

.fbf-radio
  @include toggle--base

.fbf-radio__label
  @include toggle__label

.fbf-radio__icon
  @include toggle__icon

.fbf-radio__focus-helper
  @include toggle__focus-helper
  @include material-animation-default(0.2s)
  transform: scale(0)
  transition-property: transform
  top: 0
  left: 0

.fbf-radio.is-focused
  @include toggle--is-focused

.fbf-checkbox:hover
  @include toggle--has-hover

.fbf-checkbox:active
  @include toggle--is-clicked


@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-radio, .fbf-radio.color-#{$color-name}.color-#{$color-name}
    @include toggle__icon--color($color)
    @include toggle__focus-helper--color($color)

.context--dark .fbf-radio
  @include toggle__icon--white
  @include toggle__focus-helper--white
