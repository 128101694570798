.fbf-action-bar
  display: flex
  align-self: stretch
  align-items: center
  flex-shrink: 0
  padding-left: 8px

  & > *
    flex-shrink: 0

  .fbf-action-bar__item, & > p
    padding: 0 8px
    margin-right: 8px
    display: flex
    align-items: center

    @media (max-width: $notepad-width-out)
      padding: 0 4px
      margin-right: 4px

    .fbf-icon, .fbf-svg--icon
      margin-right: 8px

    .fbf-activity-icon-plain
      margin-right: 6px // due to margin within icon
      height: 32px
      width: auto
      .backdrop-item
        display: none

.context--dark .fbf-action-bar .fbf-activity-icon-plain .fill
  fill: white
