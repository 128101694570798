.fbf-rubric
  &__toolbar
    @include fbf-card--base
    @include fbf-layout--flex-end
    @include content-padding-md(row)
    // Semi-randomly chosen for shadows. Change or remove if needed
    z-index: 10
    .fbf-options-field
      margin-left: 4px

  &__legend
    @include fbf-layout--flow-right
    padding-left: 16px
    padding-right: 16px

  &__heatmap-bar
    background: linear-gradient(to right, mix($color-blue, white, 4) 0%, mix($color-blue, white, 35) 100%)
    width: 80px
    height: 8px
    border-radius: 4px
    margin-left: 8px
    margin-right: 8px

  &__input-detail
    @include fbf-layout--flow-right
    .fbf-text-field + p
      margin-left: 8px

  &__input-main
    @include fbf-layout--flow-right
    align-self: stretch
    align-items: flex-start
    margin-top: 8px
    .fbf-text-field
      flex-grow: 1
      max-width: calc(100% - 32px)
      margin-bottom: 8px
    .fbf-menu-button
      margin-left: 8px

  &__option-input-wrapper
    @include fbf-layout--flow-down
    align-self: stretch

  &__criterion-outcome
    margin-top: 8px
    @include fade--in

  &__category
    background-color: $bg-grey--50
    .fbf-data-table__section--fixed
      background-color: $bg-grey--50
    .fbf-data-table__cell
      @include block-height(1)
    .fbf-data-table__cell-wrapper
      padding-top: 8px
      padding-bottom: 8px

.fbf-rubric__rating .fbf-data-table__cell-wrapper
  @include fbf-layout--scroll-overflow
  p
    @include text--dense

.fbf-rubric__rating
  &--users-none
    .fbf-data-table__cell-wrapper p
      @include text--muted
  &--users-one#{&}--users-one#{&}--users-one
    background-color: mix($color-blue, white, 4)
  &--users-two#{&}--users-two#{&}--users-two
    background-color: mix($color-blue, white, 8)
  &--users-some#{&}--users-some#{&}--users-some
    background-color: mix($color-blue, white, 16)
  &--users-many#{&}--users-many#{&}--users-many
    background-color: mix($color-blue, white, 24)
  &--users-lot#{&}--users-lot#{&}--users-lot
    background-color: mix($color-blue, white, 35)

  &__users
    border-bottom: 1px solid transparentize(black, 0.9)
    align-self: stretch
    padding-bottom: 8px
    margin-bottom: 8px
    margin-top: 4px

.fbf-rubric-rater
  $slider-container-padding-x: 16px
  $linear-bar-margin-x: 40px

  .fbf-layout--flow-right
    justify-content: space-between
  .fbf-slider
    padding-left: 40px
    padding-right: 40px
    box-sizing: border-box
  .fbf-linear-bar
    margin-top: 32px
    margin-bottom: 24px
    margin-left: $linear-bar-margin-x
    margin-right: $linear-bar-margin-x
    width: auto

  &__slider-container
    @include content-padding-sm

  &__label
    @include text--centered
    @include fbf-layout--actionable
    @include content-padding-xs
    border-radius: 4px
    box-sizing: border-box
    position: relative

  &__rating-details
    @include content-padding-md(column, false)
    position: relative
    background-color: $bg-grey--100
    border-top: 1px solid transparentize(black, 0.9)
    margin-top: 16px

    & >
      small, p
        margin-top: 8px

  &__indicator-container
    position: absolute
    top: 0
    left: $slider-container-padding-x + $linear-bar-margin-x
    right: $slider-container-padding-x + $linear-bar-margin-x

  &__indicator
    @include material-animation-default
    height: 16px
    width: 32px
    position: absolute
    top: -16px
    transition-property: left
    transform: translateX(-50%)

    polyline
      fill: $bg-grey--100
      stroke: transparentize(black, 0.9)

  &--2-level
    @include fbf-grid(2, 0)
  &--3-level
    @include fbf-grid(3, 0)
  &--4-level
    @include fbf-grid(4, 0)
  &--5-level
    @include fbf-grid(5, 0)
  &--6-level
    @include fbf-grid(6, 0)
  &--7-level
    @include fbf-grid(7, 0)

.fbf-dialog.fbf-rubric-dialog
  min-width: 90vw

  .fbf-layout__column-container, .fbf-matrix__container
    .fbf-rubric
      align-self: center
      max-width: 100%
      margin-bottom: 80px

      .fbf-data-table
        margin: 0

      .fbf-data-table__row
        padding: 0

      .fbf-data-table__section--fixed, .fbf-data-table__fixed-spanner
        max-width: 35vw
