$scrim-height-boost: 32px

@import 'navigation/errorscreen'
@import 'navigation/groupscreen'
@import 'navigation/homescreen'
@import 'navigation/settingsscreen'

.ember-application__container
  width: 100vw
  height: 100vh
  background-color: $bg-grey

  &.browser-mobile, &.browser-tablet
    height: calc(100vh + 1px)

  // In Firefox the viewport height is not changing when switching to fullscreen mode
  // Somehow it is recalculated when it triggers a style change, even if the change has no real effect
  html:fullscreen &.browser-firefox.browser-desktop
    height: 100vh
