.fbf-landing__knowledge-header-panel
  @include fbf-layout--scroll-overflow
  background-image: linear-gradient(135deg, rgb(53,87,109), rgb(22,39,60))
  height: 100vh
  justify-content: flex-start

  > .fbf-app-bar
    @include fbf-layout--flex-spread
    background: $scrim-up-50
    z-index: 1
    h5, .text--h5
      &.text--muted.text--light
        flex-grow: 0
        margin-right: 8px
    .fbf-app-logo
      opacity: 1
    .fbf-chip
      padding-left: 8px
      padding-right: 8px
      margin-left: 8px
    .fbf-button--outlined
      margin-left: 16px
    @media (max-width: $desktop-max-width)
      position: relative

  > .fbf-image
    position: fixed
    height: 100vh
    z-index: 0

  .fbf-layout__bg-scrim
    background: $scrim-up-80
    height: 60vh
    z-index: 0

  .fbf-layout__hero-unit
    @include fbf-layout__center-column
    @include text--centered
    z-index: 1

  .fbf-layout__column-container
    z-index: 1
    position: relative

  .fbf-direct-popover.fbf-popover .fbf-popover__arrow
    right: 202px
