$z-video-fullpage: 0
$z-base-index: 1
$z-zoom-controls: 5
$z-main: 10
$z-menu: 11
$z-sidebar-button: 12
$z-sidebar: 15
$z-app-bar: 20
$z-sidebar--mobile: 25
$z-backdrop: 40
$z-tap-target: 45
$z-floating-button: 50
$z-bottom-sheet: 60
$z-navigation-drawer: 70
$z-dropzone: 80
$z-page: 100
$z-page-backdrop: $z-page + $z-backdrop
$z-dialog: 200
$z-snackbar: 400
$z-tooltip: 500
$z-skip-navigation: 600

// ---------------------------
// DEPRECATED
// ---------------------------

// Use z-page instead
$z-detail-view: 100
// Make sure the tap-target is within a page
$z-detail-view-tap-target: $z-detail-view + $z-tap-target
