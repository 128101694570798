.fbf-progress-controls
  width: 100%
  height: $progress-controls-thickness-horizontal
  display: flex
  flex-basis: $progress-controls-thickness-horizontal
  flex-shrink: 0
  flex-grow: 1

.fbf-progress-controls--style-scrim
  background: transparent linear-gradient(to top, transparentize(black, 0.5), transparent)

.fbf-progress-controls--style-light
  background-color: $bg-grey

.fbf-progress-controls--style-dark
  background-color: $color-palette-grey-800
  .fbf-progress-controls__primary-text, .fbf-progress-controls__secondary-text
    @include text-light--muted

.fbf-progress-controls__primary-text, .fbf-progress-controls__secondary-text
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  .fbf-icon,.icon
    color: white
  > *:not(:first-child)
    margin-left: 10px


.fbf-progress-controls__action-button
  display: flex
  align-items: center
  justify-content: center
  height: $progress-controls-thickness-horizontal
  width: $progress-controls-thickness-horizontal
  font-size: 22px
  cursor: pointer
  i
    color: white

.fbf-progress-controls__progress-bar
  cursor: pointer
  flex-grow: 1
  height: $progress-controls-thickness-horizontal

.fbf-progress-controls--horizontal
  .fbf-progress-controls__primary-text, .fbf-progress-controls__secondary-text
    height: $progress-controls-thickness-horizontal
    padding: 0 16px

.fbf-progress-controls--vertical
  width: $progress-controls-thickness-vertical
  height: 100%
  flex-direction: column
  box-sizing: border-box
  padding: 48px 0
  position: absolute // Force Safari to use full height in nested horiziontal flexbox
  .fbf-progress-controls__primary-text, .fbf-progress-controls__secondary-text
    padding: 16px 0
    width: 100%
    color: $color-palette-grey-500
  .fbf-progress-controls__progress-bar
    width: 100%

.fbf-progress-controls__container
  position: relative
  width: $progress-controls-thickness-vertical

.fbf-progress-controls.fbf-progress-controls--compact
  flex-direction: column
  height: 2 * $progress-controls-thickness-horizontal--compact

  .fbf-progress-bar
    margin-top: -8px
  & > *
    min-height: $progress-controls-thickness-horizontal--compact
