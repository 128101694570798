// Icon buttons
.fbf-button--icon
  @include button--flat-grey
  border-radius: $button-icon-size / 2
  height: $button-icon-size
  margin-left: 0
  margin-right: 0
  min-width: $button-icon-size
  padding: 0
  overflow: hidden
  line-height: normal

  .fbf-icon, .fbf-svg
    // !important to overcome `fbf-layout--flow-right .fbf-button-icon .fbf-icon`
    margin: 4px !important

  .fbf-button__text
    margin-right: 12px
    // Alignment bugfix for Safari (this browser doesn't allow flex on buttons)
    align-self: center

    // If it contains no text, it otherwise still makes the button oval with
    // it's margin. This hides it so it appears just like a normal icon button
    &:empty
      display: none

    // Check if the contains no text. Otherwise it still makes the button oval with
    // it's side margin. This hides it so it appears just like a normal icon button
    &:empty
      display: none

  &.fbf-button--mini-icon
    height: $button-icon-size-mini
    min-width: $button-icon-size-mini
    width: $button-icon-size-mini

    & .material-icons
      top: ($button-icon-size-mini - $button-fab-font-size) / 2
      left: ($button-icon-size-mini - $button-fab-font-size) / 2


// Icon button color support
.fbf-button--icon.fbf-button--color-context
  @include button--icon-color-variant($color-primary)

.fbf-button--icon.fbf-button--color-green
  @include button--icon-color-variant($color-green)

.fbf-button--icon.fbf-button--color-blue
  @include button--icon-color-variant($color-blue)

.fbf-button--icon.fbf-button--color-red
  @include button--icon-color-variant($color-red)

.fbf-button--icon.fbf-button--color-purple
  @include button--icon-color-variant($color-purple)

.fbf-button--icon.fbf-button--color-yellow
  @include button--icon-color-variant($color-yellow)

.fbf-button--icon.fbf-button--color-cyan
  @include button--icon-color-variant($color-cyan)

.fbf-button--icon.fbf-button--color-brown
  @include button--icon-color-variant($color-brown)

.fbf-button--icon.fbf-button--color-blue-grey
  @include button--icon-color-variant($color-blue-grey)

.fbf-button--icon.fbf-button--color-white
  @include button--icon-color-variant(white)
