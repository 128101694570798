.fbf-dashboard
  @include fbf-layout--flow-down
  @include fbf-layout__fullscreen-page

  &__container
    @include fbf-layout__column-container
    @include fbf-layout__column-container--padding
    height: 100%
    width: 100%

  &__divider
    @media(min-width: $tablet-max-width)
      margin-left: $card-icon-gutter-width

  &__activity-list-item
    .fbf-task
      margin-bottom: 0

      .fbf-card
        box-shadow: none
        margin-left: 0
        .fbf-task__header
          display: none

        .margin
          display: none
