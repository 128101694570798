.fbf-zoom-container
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  box-sizing: border-box

  &.fbf-zoom-container--enabled
    @include fbf-layout--scroll-overflow
    // Needed, but bot ideal because it clips e.g. the slide shadow
    overflow: auto !important
    touch-action: pan-x pan-y !important

    &.fbf-zoom-container--swipeable-x
      touch-action: pan-y !important
    &.fbf-zoom-container--swipeable-y
      touch-action: pan-x !important
    &.fbf-zoom-container--swipeable-y.fbf-zoom-container--swipeable-x
      touch-action: none !important

    &.fbf-zoom-container--scrollable-x
      justify-content: flex-start !important
    &.fbf-zoom-container--scrollable-y
      align-items: flex-start !important

    .browser-ios &
      touch-action: manipulation !important
