@mixin fbf-data-table--border-support
  border: 0 solid
  border-color: inherit

  &.border-top
    border-top-width: $data-table--border-width
  &.border-right
    border-right-width: $data-table--border-width
  &.border-bottom
    border-bottom-width: $data-table--border-width
  &.border-left
    border-left-width: $data-table--border-width

@mixin fbf-data-table--body-border-support
  position: relative

  &::after
    content: ''
    position: absolute
    left: 0
    top: 0
    right: 0
    bottom: -$data-table--border-width
    border: 0px solid $color-palette-grey-400
    pointer-events: none

  &.body-border-top::after
    border-top-width: $data-table--body-border-width
  &.body-border-right::after
    border-right-width: $data-table--body-border-width
  &.body-border-bottom::after
    border-bottom-width: $data-table--body-border-width
  &.body-border-left::after
    border-left-width: $data-table--body-border-width

  &.body-border-top.body-border-left::after
    border-top-left-radius: $data-table--body-border-width
  &.body-border-top.body-border-right::after
    border-top-right-radius: $data-table--body-border-width
  &.body-border-bottom.body-border-left::after
    border-bottom-left-radius: $data-table--body-border-width
  &.body-border-bottom.body-border-right::after
    border-bottom-right-radius: $data-table--body-border-width

@mixin fbf-data-table__section--fixed
  display: flex
  position: absolute
  background-color: inherit

  .fbf-data-table__cell
    width: 100%
    height: 100%
    display: flex
    position: relative

  .fbf-data-table__cell--checkbox + .fbf-data-table__cell
    width: calc(100% - 56px)

@mixin fbf-data-table--header-height($height)
  thead .fbf-data-table__row:not([height])
    height: $height

@mixin fbf-data-table--header-min-width($width)
  .fbf-data-table__header.fbf-data-table__section--auto
    min-width: $width

@mixin fbf-data-table--row-height($height)
  tbody .fbf-data-table__row:not([height])
    height: $height

@mixin fbf-data-table--fixed-max-width($width)
  .fbf-data-table__fixed-spanner, .fbf-data-table__section--fixed
    &:not(.fbf-data-table__title)
      max-width: $width

@each $size in $fixed-block-sizes
  .fbf-data-table--fixed-left-width-#{$size}
    .fbf-data-table__fixed-spanner--left, .fbf-data-table__section--fixed-left
      &:not(.fbf-data-table__title)
        @include block-width($size)
  .fbf-data-table--fixed-right-width-#{$size}
    .fbf-data-table__fixed-spanner--right, .fbf-data-table__section--fixed-right
      &:not(.fbf-data-table__title)
        @include block-width($size)
  .fbf-data-table--header-min-width-#{$size}
    @include fbf-data-table--header-min-width($size * 56px)

@mixin cell-highlightable($color)
  .fbf-data-table__cell--highlighted
    background-color: rgba($color, $highlighted-cell-opacity)
  .fbf-data-table__row--nested .fbf-data-table__cell--highlighted
    background-color: rgba($color, $highlighted-cell-opacity--nested-row)

.fbf-data-table--header-height-default
  @include fbf-data-table--header-height($data-table--header-height-default)
.fbf-data-table--header-height-small
  @include fbf-data-table--header-height($data-table--header-height-small)
.fbf-data-table--header-height-medium
  @include fbf-data-table--header-height($data-table--header-height-medium)
.fbf-data-table--header-height-large
  @include fbf-data-table--header-height($data-table--header-height-large)
.fbf-data-table--header-height-huge
  @include fbf-data-table--header-height($data-table--header-height-huge)

.fbf-data-table--header-min-width-small
  @include fbf-data-table--header-min-width($data-table--header-min-width-small)
.fbf-data-table--header-min-width-medium
  @include fbf-data-table--header-min-width($data-table--header-min-width-medium)
.fbf-data-table--header-min-width-large
  @include fbf-data-table--header-min-width($data-table--header-min-width-large)

.fbf-data-table--row-height-small
  @include fbf-data-table--row-height($data-table--row-height-small)
.fbf-data-table--row-height-default
  @include fbf-data-table--row-height($data-table--row-height-default)
.fbf-data-table--row-height-medium
  @include fbf-data-table--row-height($data-table--row-height-medium)
.fbf-data-table--row-height-large
  @include fbf-data-table--row-height($data-table--row-height-large)

.fbf-data-table--fixed-max-width-single
  @include fbf-data-table--fixed-max-width($data-table--fixed-max-width-single)
.fbf-data-table--fixed-max-width-both
  @include fbf-data-table--fixed-max-width($data-table--fixed-max-width-both)

.context--purple
  @include cell-highlightable($color-purple)
