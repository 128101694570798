.fbf-landing__panel
  display: flex
  flex-direction: column
  // Needed for image position: static to stay put
  position: relative

  &:nth-child(even)
    background-color: white
    @include shadow-2dp
  .fbf-image
    // Needed for z-indexing of e.g. app-bar/popover to work predictably
    position: static

.fbf-landing__panel__column
  @include landing-column-padding
  @include fbf-layout__center-column
  @include fbf-layout__center-column--huge-width

.fbf-landing__panel__column-card
  @include fbf-card--base
  @include column-card

.fbf-landing__panel--padded
  padding: 16px
