.fbf-landing__learning-activities-panel
  .fbf-landing__panel__column
    @include fbf-layout__center-column--normal-width
.fbf-landing__learning-activities-panel__intro
  @include fbf-layout--flow-down
  justify-content: space-between
  > div
    @include fbf-layout--flow-down
    .fbf-button
      align-self: flex-start
    a
      display: flex
      justify-content: flex-end
  @media (min-width: $tablet-min-width)
    @include fbf-layout--flow-right
    > div
      margin-left: 40px
      max-width: 50%
      p
        text-align: right
      .fbf-button
        align-self: flex-end
  @media (max-width: $phone-max-width)
    img
      margin: 16px 0
.fbf-landing__learning-activities-panel__grid
  @include fbf-grid(1)
  margin-top: 24px
  .fbf-card
    margin-bottom: 24px
  @media (min-width: $notepad-width-out)
    @include fbf-grid(2, 24)
    margin-top: 40px
