$fbf-dialog-container-margin: 104px

.fbf-docked-dialog-container
  position: fixed
  bottom: 0
  right: $fbf-dialog-container-margin

  z-index: $z-dialog
  display: none // Hide on mobile, only visible on desktop
  display: flex

  justify-content: flex-end
  align-items: flex-end

  .fbf-docked-dialog
    margin-left: 24px
