.fbf-item-picker
  position: absolute
  z-index: $z-dialog
  width: 320px
  min-height: 240px
  max-height: 344px
  overflow: hidden
  @include shadow-4dp()
  @include fbf-layout--flow-down

  > .fbf-list
    overflow-y: auto

.fbf-item-picker__empty
  @include fbf-layout--flex-center
  flex: 1