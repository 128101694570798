$fbf-layout-corner-fixed-padding: 24px

@mixin fbf-layout--absolute-bottomright
  position: absolute
  bottom: $fbf-layout-corner-fixed-padding
  right: $fbf-layout-corner-fixed-padding

@mixin fbf-layout--absolute-center
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)

@mixin fbf-layout--absolute-centerright
  position: absolute
  top: 50%
  right: $fbf-layout-corner-fixed-padding
  transform: translateY(-50%)

@mixin fbf-layout--stretch
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: -1
