.fbf-button--app-bar-toggle
  &.fbf-button--flat
    min-width: auto

.context--dark
  .fbf-button--app-bar-toggle
    background-color: transparentize(white, 0.9) !important

    &:hover
      background-color: transparentize(white, 0.8) !important

    &:active
      background-color: transparentize(black, 0.7) !important

    &.fbf-button--active
      background-color: transparentize(black, 0.8) !important
