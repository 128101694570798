fieldset[disabled] .fbf-text-field,
.fbf-text-field.is-disabled.is-disabled
  &.has-label .fbf-text-field__label, .fbf-text-field__input, .fbf-text-field__helper-text
    @include text--muted-more

.fbf-text-field.has-label:not(.is-compact)
  &.is-dirty, &.is-focused
    .fbf-text-field__label
      @include text--small
      top: 6px
    &.is-invalid .fbf-text-field__label
      @include text--red
    .fbf-text-field__input
      padding-top: 20px
      padding-bottom: 9px

.fbf-text-field.is-compact
  .fbf-text-field__label, .fbf-text-field__input, .fbf-text-field__error, .fbf-text-field__helper-text
    padding-left: 8px
    padding-right: 8px
  .fbf-text-field__input
    padding-top: 4px
    padding-bottom: 3px
  .fbf-text-field__label
    top: 4px
  &.has-label.is-dirty
    .fbf-text-field__label
      display: none
  .fbf-text-field__leading-icon
    margin-left: 6px
  .fbf-text-field__trailing-icon
    margin-right: 6px

.fbf-text-field.is-inline
  .fbf-text-field__error
    @include fbf-card--base
    @include min-block-width(3)
    position: absolute
    top: 100%
    box-sizing: border-box
    border-radius: 0 0 4px 4px
    z-index: 1

    &.sr-only
      transform: scale(0)

  &.fbf-text-field--error-align-left .fbf-text-field__error
    left: 0
    right: 0
  &.fbf-text-field--error-align-right .fbf-text-field__error
    left: initial
    right: 0

.fbf-text-field__leading-icon
  margin-left: 12px
.fbf-text-field__trailing-icon
  margin-right: 12px
.fbf-button--icon.fbf-text-field__trailing-icon
  margin: -4px 8px -4px -4px
