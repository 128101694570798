.fbf-logo-card
  display: flex
  align-items: center
  justify-content: center
  border-radius: 4px
  padding: 16px
  height: 128px

  img
    max-height: 80%
    max-width: 40%

  p
    max-width: 66%
    box-sizing: border-box
    display: flex
    align-items: center
    padding-left: 16px

.fbf-logo-card--logo-only
  justify-content: center
  padding: 4% 5%

  img
    max-height: 100%
    max-width: 100%
