.fbf-path-graph
  position: absolute
  top: 8px
  left: -248px

.fbf-path-graph-link
  fill: none
  stroke: #999
  stroke-width: 2px
  stroke-opacity: 1

.fbf-path-graph-link--hidden
  stroke: none