// Colors
@mixin text-field--color-variant($color)
  &.has-label
    &.is-dirty, &.is-focused
      .fbf-text-field__label
        color: $color
  .fbf-text-field__container:after
    background-color: $color
  .fbf-text-field__input
    caret-color: $color

@each $color-name, $color in $colors
  .context--#{$color-name} .fbf-text-field, .fbf-text-field.color-#{$color-name}
    @include text-field--color-variant($color)
