@mixin content-margin($direction: column, $margin) {
  @if $direction == column {
    & > * + * {
      margin-top: $margin;
    }
  } @else if $direction == row {
    & > * + * {
      margin-left: $margin;
    }
  }
}
@mixin content-padding-xs($direction: column, $margins: true) {
  padding: 4px 8px;
  @if $margins {
    @include content-margin($direction, 4px);
  }
}
@mixin content-padding-sm($direction: column, $margins: true) {
  padding: 8px 16px;
  @if $margins {
    @include content-margin($direction, 8px);
  }
}
@mixin content-padding-md($direction: column, $margins: true) {
  padding: 16px 24px;
  @if $margins {
    @include content-margin($direction, 16px);
  }
}
@mixin content-padding-lg($direction: column, $margins: true) {
  padding: 32px 40px;
  @if $margins {
    @include content-margin($direction, 24px);
  }
}


// 56dp is the base width/height unit for Material Design.
// With e.g. 5 units being the number of units for a mobile device Dialog.
// https://goo.gl/sK2O5o
@mixin block-width($units:5) {
  @if(type_of($units) != 'number') {
    @error "The unit given to block-width should be a number.";
  }
  width: $units * 56px;
}
@mixin block-height($units:5) {
  @if(type_of($units) != 'number') {
    @error "The unit given to block-height should be a number.";
  }
  height: $units * 56px;
}
@mixin max-block-width($units:5) {
  @if(type_of($units) != 'number') {
    @error "The unit given to max-block-width should be a number.";
  }
  max-width: $units * 56px;
}
@mixin max-block-height($units:5) {
  @if(type_of($units) != 'number') {
    @error "The unit given to max-block-height should be a number.";
  }
  max-height: $units * 56px;
}
@mixin min-block-width($units:5) {
  @if(type_of($units) != 'number') {
    @error "The unit given to min-block-width should be a number.";
  }
  min-width: $units * 56px;
}
@mixin min-block-height($units:5) {
  @if(type_of($units) != 'number') {
    @error "The unit given to min-block-height should be a number.";
  }
  min-height: $units * 56px;
}
