.errorscreen
  @include fbf-layout--flow-down
  @include fbf-layout__fullscreen-page

  .fbf-layout--flow-right
    @include fbf-layout--scroll-overflow
    .fbf-button
      margin-right: 16px
      margin-bottom: 16px

  .homescreen__column
    margin-top: $scrim-height-boost + $fbf-tool-bar-height
