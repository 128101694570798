@import '../platform/layout'
$progress-bar-thickness-horizontal: $progress-controls-thickness-horizontal
$progress-bar-thickness-vertical: $progress-controls-thickness-vertical
$progress-position-size: 9px
$progress-dot-enlarged-size: 25px
$progress-dot-size: 9px // Includes border
$progress-dot-border: 2px
$progress-dot-icon-size: 33px
$progress-bar-width: 3px

.fbf-progress-bar
  position: relative
  height: 100%

  .progress-container
    padding-top: ($progress-bar-thickness-horizontal / 2) - ($progress-bar-width / 2)
    position: relative

  .progress
    position: relative
    height: $progress-bar-width
    margin-top: 0
    border-radius: $progress-bar-width / 2
    background-color: transparentize($color-palette-grey-600, 0.5)
    > div
      position: absolute
      height: 100%
      opacity: 1
      border-radius: $progress-bar-width / 2
    > .determinate
      z-index: 1
      background-color: transparentize(white, 0.4)
    > .definite
      z-index: 2
      background-color: $color-palette-grey-400
      transition: width 0.05s, height 0.05s

.fbf-progress-position
  z-index: 3
  width: $progress-position-size
  height: $progress-position-size
  margin-left: -($progress-position-size / 2)
  background-color: $color-palette-grey-400
  position: absolute
  top: ($progress-bar-thickness-horizontal / 2) - ($progress-position-size / 2)
  border-radius: 50%
  transition: all 0.35s ease-in-out, left 0.05s
  @include fbf-layout--flex-center

  &:hover, &.ui-draggable-dragging
    transform: scale(2, 2)
  .fbf-progress-bar--horizontal &
    top: ($progress-bar-thickness-horizontal / 2) - ($progress-position-size / 2) !important
    margin-right: -($progress-position-size / 2)
  .browser-mobile &, .browser-tablet &
    transform: scale(1.5, 1.5)

@mixin progress-bar-variant($color)
  .fbf-progress-bar
    .progress-container
      .progress
        > .definite
          background-color: $color

    .fbf-progress-position
      background-color: $color

@each $color-name, $color in $colors
  .context--#{$color-name}
    @include progress-bar-variant($color)

.fbf-progress-bar--vertical
  .progress-container
    position: absolute
    height: 100%
    padding-top: 0
    padding-left: ($progress-bar-thickness-vertical / 2) - ($progress-bar-width / 2)
    width: $progress-bar-thickness-vertical
    box-sizing: border-box

  .fbf-progress-dot
    margin-top: -($progress-dot-size / 2)
    margin-left: 0
    left: ($progress-bar-thickness-vertical / 2) - ($progress-dot-size / 2)

  .fbf-progress-dot--required,.fbf-progress-dot--done
    margin-top: -($progress-dot-icon-size / 2)
    margin-left: 0
    left: ($progress-bar-thickness-vertical / 2) - ($progress-dot-icon-size / 2)

  .progress
    position: absolute
    height: 100%
    width: $progress-bar-width
    >div
      width: $progress-bar-width
      height: 100%

  .fbf-progress-position
    margin-top: -($progress-position-size / 2)
    margin-bottom: -($progress-position-size / 2)
    margin-left: 0
    left: ($progress-bar-thickness-vertical / 2) - ($progress-position-size / 2) !important
    transition: all 0.35s ease-in-out, top 0.05s

.fbf-progress-bar--style-light
  .progress
    background-color: transparentize(black, 0.9)
  .fbf-progress-position
    background-color: transparentize(black, 0.5)

  .fbf-progress-dot--required,.fbf-progress-dot--done
    background-color: $bg-grey

.fbf-progress-bar--style-dark
  .progress
    background-color: black
  .fbf-progress-position
    background-color: transparentize(white, 0.5)
