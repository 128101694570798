$fbf-question-solution-content-padding: 16px
$fbf-question-solution-content-padding--compact: 8px

@mixin fbf-question-solution-content-padding($padding)
  & > *
    margin-top: $padding
    margin-bottom: 0
  & > *:last-child
    margin-bottom: $padding
  & > .fbf-question__solution-panel--antimargin
    margin-left: -$padding
    margin-right: -$padding

.fbf-question__solution
  $content-padding: 16px
  $content-padding--compact: 8px

  @include fbf-layout--flow-down
  @include fbf-question-solution-content-padding($content-padding)
  padding: 0 $content-padding

  .fbf-question--compact.is-collapsed &
    @include fbf-question-solution-content-padding($content-padding--compact)

  &__config
    @include fbf-layout--flow-down
    margin: 16px
    align-self: start
    & > *:not(:last-child)
      margin-bottom: 16px

  &__add-new
    @include fbf-layout--flow-right
    margin-left: 16px
    margin-bottom: 24px

.fbf-question
  &__answering-status
    @include fbf-layout--self-center
    border-radius: 100px

    .fbf-list-item__lines
      margin-top: 0
      margin-bottom: 0

  &__facet-weight-notice-container
    @include fbf-layout--self-center
    .fbf-question--compact.is-collapsed &
      display: block
