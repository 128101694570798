// Semantic gradients
$gradient-warning: linear-gradient(to right, transparentize($color-orange, 0.8) 0%, white 80px)
$gradient-error: linear-gradient(to right, transparentize($color-red, 0.8) 0%, white 80px)
$gradient-notice: linear-gradient(to right, transparentize($color-blue, 0.8) 0%, white 80px)

// Utility gradients
$gradient-platform: linear-gradient(to bottom right, $color-palette-green-500 0%, $color-primary-dark 100%)
$gradient-media-placeholder: linear-gradient(to bottom right, black 0%, $color-palette-grey-800 50%, black 51%)

// Context color gradients
$gradient-primary: linear-gradient(to bottom right, $color-primary-light 0%, $color-primary-dark 100%)
$gradient-purple: linear-gradient(to bottom right, $color-palette-deep-purple-300 0%, $color-palette-deep-purple-500 100%)
$gradient-blue: linear-gradient(to bottom right, $color-palette-blue-300 0%, $color-palette-blue-500 100%)
$gradient-red: linear-gradient(to bottom right, $color-palette-red-700 0%, $color-palette-red-900 100%)
$gradient-orange: linear-gradient(to bottom right, $color-palette-orange-500 0%, $color-palette-orange-700 100%)
$gradient-yellow: linear-gradient(to bottom right, $color-palette-lime-500 0%, $color-palette-lime-700 100%)
$gradient-teal: linear-gradient(to bottom right, $color-palette-teal-500 0%, $color-palette-teal-700 100%)
$gradient-cyan: linear-gradient(to bottom right, $color-palette-cyan-500 0%, $color-palette-cyan-700 100%)
$gradient-brown: linear-gradient(to bottom right, $color-palette-brown-300 0%, $color-palette-brown-500 100%)
$gradient-blue-grey: linear-gradient(to bottom right, $color-palette-blue-grey-400 0%, $color-palette-blue-grey-600 100%)
$gradient-green: linear-gradient(to bottom right, $color-palette-light-green-600 0%, $color-palette-light-green-800 100%)

$scrim-up-100: linear-gradient(to top, transparent 0%, black 100%)
$scrim-up-80: linear-gradient(to top, transparent 0%, transparentize(black, 0.2) 100%)
$scrim-up-50: linear-gradient(to top, transparent 0%, transparentize(black, 0.5) 100%)
$scrim-up-30: linear-gradient(to top, transparent 0%, transparentize(black, 0.7) 100%)
$scrim-up-10: linear-gradient(to top, transparent 0%, transparentize(black, 0.9) 100%)
$scrim-up: $scrim-up-50

$scrim-down-100: linear-gradient(to bottom, transparent 0%, black 100%)
$scrim-down-80: linear-gradient(to bottom, transparent 0%, transparentize(black, 0.2) 100%)
$scrim-down-50: linear-gradient(to bottom, transparent 0%, transparentize(black, 0.5) 100%)
$scrim-down-30: linear-gradient(to bottom, transparent 0%, transparentize(black, 0.7) 100%)
$scrim-down-10: linear-gradient(to bottom, transparent 0%, transparentize(black, 0.9) 100%)
$scrim-up: $scrim-down-50

$scrim-white-up: linear-gradient(to top, transparentize(white, 1) 0%, white 100%)
$scrim-white-up-100: $scrim-white-up
$scrim-white-up-80: linear-gradient(to top, transparentize(white, 1) 0%, transparentize(white, 0.2) 100%)
$scrim-white-up-50: linear-gradient(to top, transparentize(white, 1) 0%, transparentize(white, 0.5) 100%)
$scrim-white-up-30: linear-gradient(to top, transparentize(white, 1) 0%, transparentize(white, 0.7) 100%)
$scrim-white-up-10: linear-gradient(to top, transparentize(white, 1) 0%, transparentize(white, 0.9) 100%)

$scrim-white-down: linear-gradient(to bottom, transparentize(white, 1) 0%, white 100%)
$scrim-white-down-100: $scrim-white-down
$scrim-white-down-80: linear-gradient(to bottom, transparentize(white, 1) 0%, transparentize(white, 0.2) 100%)
$scrim-white-down-50: linear-gradient(to bottom, transparentize(white, 1) 0%, transparentize(white, 0.5) 100%)
$scrim-white-down-30: linear-gradient(to bottom, transparentize(white, 1) 0%, transparentize(white, 0.7) 100%)
$scrim-white-down-10: linear-gradient(to bottom, transparentize(white, 1) 0%, transparentize(white, 0.9) 100%)
